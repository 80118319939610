import React, { useEffect, useState } from "react";
import { ConsentContainer } from "../Engine-Page/ConsentRequestPage/ConsentRequestStyle";
import { AddNewDoctor, Okbutton } from "../Doctor-Page/Doctorstyle";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { Button, Form, Alert, Row, Col } from "react-bootstrap";
import { configJson } from "../../Constent/config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import { convertToUTC } from "../../helpers";

function CreateNewPatient({ show, handleClose, tokendata, tokensStatus }) {
  console.log(2,tokendata)
  const [date, setDate] = useState(null);
  // const [formFieldDrop, setFormFieldDrop] = useState([]);
  // const [languages, setLanguages] = useState([]);
  const [load, setload] = useState(false);
  const [decode, setDecode] = useState({});
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm(
  //   {
  //   defaultValues: {
  //     language: "ta-IN" // Fallback default value
  //   }
  // }
);
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("success");
  const [formData, setFormData] = useState({
    hospital_patient_id: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    phone_number: "",
  });

  // Function to handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value, // Update form data dynamically
    }));
  };

  // Function to handle input blur (when field loses focus)
  const handleBlur = async (e) => {
    const { name, value } = e.target;
    if (value.length > 0) {
      // console.log(`${name} field blurred with value: ${value}`);
      try {
        // Make the GET request to your backend API
        const response = await fetch(
          `${configJson.backend_URL}/patient/get/${value}/${decode.organization_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // Check if the response is ok (status code 200-299)
        if (response.ok) {
          const data = await response.json();
          if (data.patient) {
            // console.log(data.patient);
            // console.log("Patient fetched successfully:", data.patient);
            setValue("hospital_patient_id", data.patient.hospital_patient_id);
            setValue("first_name", data.patient.first_name);
            setValue("last_name", data.patient.last_name);
            setValue("date_of_birth", data.patient.date_of_birth);
           // setValue("language", data.patient.language);
            setValue("phone_number", data.patient.phone_number);
          }
        } else {
          setValue("hospital_patient_id", formData.hospital_patient_id);
          // If not ok, handle errors
          const errorData = await response.json();
          console.error("Error fetching patient:", errorData.message);
        }
      } catch (error) {
        console.error("Error during fetch operation:", error);
      }
      // You can add any additional logic for blur event here
    }
  };

  const onSubmit = async (data) => {
    setload(true);
    if (!data.date_of_birth) {
      data.date_of_birth = null;
    }  
    console.log(decode.doctor_id)
    const patientData = {
      ...data,
      organization_id: decode.organization_id,
      doctor_id: tokendata.doctor_id,
      speciality_id: tokendata.speciality_id,
      interaction_status: "99",
      interaction_date: date,
    };
    try {
      const response = await fetch(
        `${configJson.backend_URL}/patient/patientIntraction`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(patientData),
        }
      );

      const result = await response.json();
      console.log(8,result)
      if (response.ok) {
        console.log(response,"responsedata")
        setMessage(result.message);
        setVariant("success");
        toast.success("Patient Add Successful ");
          // Additional: Create Client in Invoice Ninja
          // await createInvoiceNinjaClient(result.patient);
          console.log("patientresult",result.patient)
        setload(false);
        handleClose();
      } else {
        setMessage(result.message);
        setVariant("danger");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("Error creating patient: " + error.message);
      setVariant("danger");
    }finally{
      setload(false);
    }
  };


  // Function to create client in Invoice Ninja
  // const createInvoiceNinjaClient = async (data) => {
  //   try {
  //     const clientData = {
  //       "name": "Client Name",
  //       "contacts": [
  //         {
  //           "first_name": "John",
  //           "last_name": "Smith",
  //           "email": "john@example.com",
  //           "phone": "555-0123"
  //         }
  //       ],
  //       "address1": "123 Main St",
  //       "city": "New York",
  //       "state": "NY",
  //       "postal_code": "10001",
  //       "country_id": "1"
  //     }
  
  //     const response = await fetch(`https://invoice.medscribe.in/api/v1/clients`, {
  //       method: "POST",
  //       headers: {
  //         "X-Api-Token": "z3VjplPVEKQzDvCAAjYvHSpneJRUJGEm4watX53N5WHf7tmoPkvOsxhqikfkVLfK", // Replace with your actual API token
  //       },
  //       body: JSON.stringify(clientData),
  //     });
  
  //     if (!response.ok) {
  //       const errorData = await response.json();
  //       console.error("Error creating client in Invoice Ninja:", errorData);
  //       throw new Error(errorData.message || "Unknown error");
  //     }
  
  //     const result = await response.json();
  //     console.log("Client created successfully in Invoice Ninja:", result);
  //     return result.data;  // Return the saved client data
  //   } catch (error) {
  //     console.error("Error creating client in Invoice Ninja:", error.message);
  //     return null;
  //   }
  // };
  
  
  

  // const createInvoiceNinjaClient = async (data) => {
  //   try {
  //     const response = await fetch('https://invoice.medscribe.in/api/v1/clients', {
  //       method: 'POST',
  //       headers: {
  //         'X-Api-Token': 'z3VjplPVEKQzDvCAAjYvHSpneJRUJGEm4watX53N5WHf7tmoPkvOsxhqikfkVLfK', // Use your API token
  //       },
  //       body: JSON.stringify({
  //         name: data.first_name, // Client name
  //         phone: data.phone_number, // Client phone number
  //         id_number: data.hospital_patient_id, // Patient ID number (optional)
  //         address1: "Default Address", // Address line 1
  //         city: "Default City", // City
  //         postal_code: "12345", // Postal code
  //         country_id: "IN", // Country code (IN for India)
  
  //         // Contact details (this could be a sub-object or array depending on the API)
  //         contacts: [
  //           {
  //             first_name: data.first_name, // Contact first name
  //             last_name: data.last_name, // Contact last name (optional)
  //             email: data.email, // Contact email (optional)
  //             phone: data.phone_number, // Contact phone (optional)
  //             // You can add more contact details if required by your API
  //           }
  //         ],
  //       }),
  //     });
  
  //     if (!response.ok) {
  //       const errorText = await response.text(); // Get raw error text for debugging
  //       console.error("Error response:", errorText); // Log the error response
  //       throw new Error("Error creating client: " + errorText);
  //     }
  
  //     const result = await response.json(); // Parse JSON response if successful
  //     console.log("Client created successfully in Invoice Ninja:", result);
  //     toast.success("Client created in Invoice Ninja");
  
  //   } catch (error) {
  //     console.error("Error creating Invoice Ninja client:", error.message);
  //     toast.error("Failed to create client in Invoice Ninja");
  //   }
  // };
  
  

  //form field
  useEffect(() => {
    // const timestamp = Date.now();
    // // console.log(timestamp);
    // const now = new Date(timestamp);
    // const formattedDate = now.toLocaleString("en-US", {
    //   month: "2-digit",
    //   day: "2-digit",
    //   year: "2-digit",
    //   hour: "2-digit",
    //   minute: "2-digit",
    //   hour12: false,
    // });
    // // console.log("date", formattedDate);
    // setDate(formattedDate);
    setDate(convertToUTC());

    
    // //Language set in form
    // if (languages.length > 0) {
    //   setFormFieldDrop([
    //     {
    //       label: "Language",
    //       name: "language",
    //       type: "select",
    //       required: true,
    //       options: languages,
    //     },
    //     // Other fields if needed
    //   ]);

    //   if(decode.organization_id===10){
    //   // Ensure the default value is set to "ta-IN"
    //   setValue("language", "en");
    //   }else{
    //     setValue("language", "ta-IN");
    //   }
    // }
  }, []);

  //get languages
  // useEffect(() => {
  //   const fetchLanguages = async () => {
  //     try {
  //       const response = await fetch(
  //         `${configJson.backend_URL}/patient/language`
  //       ); // Adjust the endpoint if needed
  //       const result = await response.json();

  //       if (response.ok) {
  //         setLanguages(result);
  //       } else {
  //         setMessage(result.message || "Failed to fetch languages.");
  //       }
  //     } catch (error) {
  //       setMessage("An error occurred while fetching languages.");
  //     }
  //   };

  //   fetchLanguages();
  // }, []);
  useEffect(() => {
    const getid = uuidv4().split("-")[0];
    setFormData((prevData) => ({
      ...prevData,
      ["hospital_patient_id"]: getid, // Update form data dynamically
    }));
    setValue("hospital_patient_id",getid)
  }, []);

  //decode token get data
  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decodeddata = jwtDecode(token);
      // console.log(decodeddata);
      setDecode(decodeddata);
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <AddNewDoctor>
        <Modal.Body>
          <ConsentContainer>
            <div className="header-text-part">
              <h2 className="header-text"> Patient Details</h2>
              <h3>
              SPECIALITY: {tokendata.speciality_name}
              </h3>
            </div>
            {message && <Alert variant={variant}>{message}</Alert>}
            <Form onSubmit={handleSubmit(onSubmit)}>
              {/* Hospital Patient ID */}
              <Form.Group as={Row} className="mb-3">
                <Col sm={4}>
                  <Form.Label>Hospital Patient ID</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="hospital_patient_id"
                    value={formData.hospital_patient_id}
                    {...register("hospital_patient_id", {
                      required: "Hospital Patient ID is required",
                    })}
                    onChange={handleChange}
                    onBlur={handleBlur} // Added onBlur event handler
                    isInvalid={!!errors.hospital_patient_id}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.hospital_patient_id?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* First Name */}
              <Form.Group as={Row} className="mb-3">
                <Col sm={4}>
                  <Form.Label>First Name</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    {...register("first_name", {
                      required: "First Name is required",
                    })}
                    onChange={handleChange}
                    isInvalid={!!errors.first_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.first_name?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* Last Name */}
              <Form.Group as={Row} className="mb-3">
                <Col sm={4}>
                  <Form.Label>Last Name</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    {...register("last_name", {
                      required: "Last Name is required",
                    })}
                    onChange={handleChange}
                    isInvalid={!!errors.last_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.last_name?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* Date of Birth */}
              <Form.Group as={Row} className="mb-3">
                <Col sm={4}>
                  <Form.Label>Date of Birth</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="date"
                    name="date_of_birth"
                    value={formData.date_of_birth}
                    // {...register("date_of_birth", {
                    //   required: "Date of Birth is required",
                    // })}
                    onChange={handleChange}
                   // isInvalid={!!errors.date_of_birth}
                  />
                  {/* <Form.Control.Feedback type="invalid">
                    {errors.date_of_birth?.message}
                  </Form.Control.Feedback> */}
                </Col>
              </Form.Group>

              {/* Phone Number */}
              <Form.Group as={Row} className="mb-3">
                <Col sm={4}>
                  <Form.Label>Phone Number</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="phone_number"
                    value={formData.phone_number}
                    {...register("phone_number", {
                      required: "Phone Number is required",
                    })}
                    onChange={handleChange}
                     isInvalid={!!errors.phone_number}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone_number?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Col sm={4}>
                  <Form.Label>Token Number</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="number"
                    name="token_number"
                    value={formData.token_number}
                    {...register("token_number", {
                      required: "token_number is required",
                    })}
                    onChange={handleChange}
                    isInvalid={!!errors.token_number}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.token_number?.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              {/* {formFieldDrop.map((field, index) => (
                <Form.Group as={Row} className="mb-3" key={index}>
                  <Col sm={4}>
                    <Form.Label>{field.label}</Form.Label>
                  </Col>
                  <Col sm={8}>
                    {field.type === "select" ? (
                      <Form.Control
                        as="select"
                        {...register(field.name, {
                          required: field.required
                            ? `${field.label} is required`
                            : false,
                        })}
                        // defaultValue="ta-IN" // Set default value directly
                        isInvalid={!!errors[field.name]}
                      >
                        <option value="">Select an option</option>
                        {field.options.map((option, i) => (
                          <option key={option.id} value={option.language_code}>
                            {option.language_name}
                          </option>
                        ))}
                      </Form.Control>
                    ) : (
                      <Form.Control
                        type={field.type}
                        {...register(field.name, {
                          required: field.required
                            ? `${field.label} is required`
                            : false,
                        })}
                        isInvalid={!!errors[field.name]}
                      />
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors[field.name]?.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              ))} */}
              <Okbutton>
                <Button
                  type="button"
                  className="reg-sub-btn"
                  onClick={handleClose}
                >
                  cancel
                </Button>
                {load ? (
                  <Button className="reg-sub-btn">Loading..</Button>
                ) : (
                  <Button type="submit" className="reg-sub-btn">
                    OK
                  </Button>
                )}
              </Okbutton>
            </Form>
          </ConsentContainer>
        </Modal.Body>
      </AddNewDoctor>
    </Modal>
  );
}

export default CreateNewPatient;
