import styled from "styled-components";
export const TemplateButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
  justify-content: end;
`;
export const MedicationContainer = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 10px;
  // background-color: #2196f3;
  padding: 10px;
  .medicatiob-form {
    // display: flex;
    // justify-content: center;
    // flex-direction: row;
    // align-items: center;
    border: 1px solid black;
  }
  .medicatiob-table {
  }
`;

export const MedicationFormCOntainer = styled.div`
  .heading-text {
    font-family: OpenSans, Arial, sans-serif;
    padding: 10px 4px;
    margin: 0px;
    margin-bottom: 0;
    color: #fff;
    font-size: 16px;
    clear: both;
    background: #699;
    position: relative;
    border-radius: 2px 2px 0 0;
  }
  .medication-form-field {
    padding: 5px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .theme-button {
    color: #fff;
    background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    padding: 0px 10px;
    border-radius: 5px;
    margin: 0px;
    height: 34px;
  }
`;

export const MedicationCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  padding: 10px;
  margin: 10px;
  // width: 100%;
  height: 150px;
  .medication-title {
  }
  .medication-center {
  }
`;
