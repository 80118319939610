import React from "react";
import { Table } from "react-bootstrap";

const ChargesTable = ({ charges }) => {
  return (
    <Table striped bordered hover size="sm" className="mb-4">
      <thead>
        <tr>
          <th>S.NO</th>
          <th>HEADER NAME</th>
          <th>SERVICE NAME</th>
          <th>QTY</th>
          <th>RATE</th>
          <th>AMOUNT</th>
        </tr>
      </thead>
      <tbody>
        {charges.map((charge, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{charge.headerName}</td>
            <td>{charge.serviceName}</td>
            <td>{charge.qty}</td>
            <td>{charge.rate}</td>
            <td>{charge.amount}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ChargesTable;
