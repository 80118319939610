import React, { useState, useRef, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import UploadFiles from "../Engine-Page/mainEngine/Components/UploadFiles";
import { jwtDecode } from "jwt-decode";
import ChatbotQuery from "../Engine-Page/mainEngine/Components/ChatbotQuery";
// import { FaCamera, FaPrint } from "react-icons/fa";
import "./table.css";
import {
  convertToKolkataTimeFormat,
  // formatDate,
  renderMedia,
} from "../../helpers";
import logo from "../../assets/gunam.png";
import { IoIosCopy } from "react-icons/io";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdLocalPrintshop } from "react-icons/md";
import { RiFileEditFill } from "react-icons/ri";
import MarkdownEditorModal from "../Doctor-Page/Components/DoctorFeed/SoapNotesEditer";
import { Aibot } from "./ActivityStyle";
import VitalsModal from "./Vitals";
import BillingDefaultTemplate from "../Billing/BillingDefaultTemplate";
import ImgCarousel from "./carousel";
import { Checkbox, Input, Radio } from "antd";
import Masonry from "react-masonry-css";
import PatientSummary from "./summary";
import axios from "axios";
import { configJson } from "../../Constent/config";

const isMobile = () => window.innerWidth <= 768;

const Feed = ({
  interactions,
  selectedPatientName,
  fetchInteractions,
  doctorDetails,
  audioUrlInteractionId,
}) => {
  console.log("Feed interaction");
  const navigate = useNavigate();
  const [mediaSrc, setMediaSrc] = useState();
  const [mediaModal, setMediaModal] = useState(false);
  const [images, setImages] = useState([]);
  const [decode, setDecode] = useState({});
  const [userDetails, setUserDetails] = useState({
    patientId: "",
    doctorId: "",
    interactionId: "",
  });
  const [imgExpanded, setImgExpanded] = useState(null);
  const [expanded, setExpanded] = useState(null);
  const [showReadBtn, setShowReadBtn] = useState({});
  const ref = useRef([]);
  const [uploadModal, setUploadModal] = useState(false);
  // const [staticQns, setStaticQns] = useState("");
  const comnotes = useRef();
  const [copied, setCopied] = useState(false);
  const [editmodel, setEditmodel] = useState(false);
  const printRef = useRef({});
  const imgRef = React.createRef();
  const [vitalsModal, setVitalsModal] = useState(false);
  const [showBillingTemplate, setShowBillingTemplate] = useState(false);
  const [carouselModal, setCarouselModal] = useState(false);
  const [summaryDrawer, setSummaryDrawer] = useState(false);
  const [content, setContent] = useState("detail");
  const [showTempComp, setShowTempComp] = useState(false);
  const [patientSummary, setPatientSummary] = useState([])

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const docId = queryParams.get("doctor");
  const patId = queryParams.get("id");

  const style = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    overflow: "hidden",
  };


  const getDivContent = () => comnotes.current?.innerText || "";

  const handelOpenmodel = () => {
    // console.log("click");
    setEditmodel(true);
  };
  const handleCloseModel = () => {
    // console.log("click");
    setEditmodel(false);
  };

  const fetchImages = () => {
    // setMediaModal(true);
    const types = [
      "mri",
      "blood test",
      "ct scan",
      "x-ray",
      "ultrasound",
      "ecg",
      "biopsy",
      "ct angiography",
      "nuclear medicine",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "",
    ];
    interactions.Interactions?.forEach((interaction) => {
      interaction.InteractionDetails.forEach((detail) => {
        if (
          detail.processedFileContent?.split(".").pop().toLowerCase() === "pdf"
        )
          return null;
        if (types.includes(detail.interaction_detail_type)) {
          setImages((prevImages) => [
            ...prevImages,
            detail.processedFileContent,
          ]);
        }
      });
    });
  };

  const sortedInteractions = interactions?.Interactions?.sort((a, b) => {
    return new Date(b.updated_at) - new Date(a.updated_at);
  });

  const handleClose = () => {
    setMediaModal(false);
    setImages([]);
  };

  // const handleShowImages = (imageUrls) => {
  //   setMediaSrc(imageUrls);
  //   setMediaModal(true);
  // };

  const toggleExpand = (index) => {
    setExpanded((prevIndex) => (prevIndex === index ? null : index));
  };

  const toggleImgExpand = (index) => {
    setImgExpanded((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleResize = () => {
    // console.log("inn");
    ref.current.forEach((el, index) => {
      if (el) {
        const lineHeight = parseFloat(window.getComputedStyle(el).lineHeight);
        const maxVisibleHeight = lineHeight * 3; // Height of 3 lines

        // Compare clientHeight and scrollHeight
        const shouldShowReadMore =
          el.scrollHeight > el.clientHeight &&
          el.scrollHeight > maxVisibleHeight;

        setShowReadBtn((prev) => ({
          ...prev,
          [index]: shouldShowReadMore,
        }));
      }
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // Trigger the calculation initially
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref, interactions]);

  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      if (doctorDetails) {
        console.log("doctorDetails OK",doctorDetails);
        setDecode(doctorDetails);
      } else {
        const decoded = jwtDecode(token);
        console.log("DESoocedeOK");
        setDecode(decoded);
      }
    } else {
      navigate("/login");
    }
  }, [doctorDetails]);

  const handlePrint = (detailId) => {
    const contentToPrint = ref.current[detailId];

    // Create a hidden iframe element
    const printFrame = document.createElement("iframe");
    printFrame.style.display = "none";
    document.body.appendChild(printFrame);

    // Set the iframe content to the content to be printed
    const doc = printFrame.contentWindow.document;
    doc.write(`
      <!DOCTYPE html>
      <html>
        <head>
          
        </head>
        <body>
          <div class="flex items-center justify-between">
            <img src="${logo}" alt='Logo' />
            <div class="text-right">
              <div class="font-bold">Gunam Super Speciality Hospital</div>
              <div class="text-sm">Health Care You Deserve</div>
              <div class="font-bold">Dr. ${decode.first_name} ${decode.last_name}</div>
            </div>
          </div>
          <div class='flex items-center gap-1 font-bold'>
            <div>Patient:</div>
            <div>${interactions?.first_name} ${interactions?.last_name}</div>
          </div>
          ${contentToPrint.innerHTML}

          <script src="https://cdn.tailwindcss.com"></script>
        </body>
      </html>
    `);
    doc.close();

    // Trigger the print dialog using the iframe
    printFrame.contentWindow.print();

    // Remove the iframe after printing
    document.body.removeChild(printFrame);
  };

  useEffect(() => {
    setUserDetails({
      patientId: interactions?.patient_id,
      doctorId: interactions?.doctor_id,
      interactionId: interactions?.Interactions?.interaction_id,
    });
  }, [interactions]);

  const openFullScreen = (event) => {
    const imgElement = event.target;

    // Ensure `isMobile` function is defined correctly
    if (typeof isMobile === "function" && !isMobile()) {
      // Check for Fullscreen API support
      if (imgElement.requestFullscreen) {
        imgElement.requestFullscreen();
      } else if (imgElement.mozRequestFullScreen) {
        imgElement.mozRequestFullScreen(); // Firefox
      } else if (imgElement.webkitRequestFullscreen) {
        imgElement.webkitRequestFullscreen(); // Chrome, Safari, Opera
      } else if (imgElement.msRequestFullscreen) {
        imgElement.msRequestFullscreen(); // IE/Edge
      } else {
        console.error("Fullscreen API is not supported by this browser.");
        return;
      }

      // Update the imgRef if it's a React ref
      if (imgRef && typeof imgRef === "object" && imgRef.current) {
        imgRef.current = imgElement;
      }
    } else {
      // For mobile, open image in a new tab instead of fullscreen
      const mediaSrcUrl = mediaSrc ? mediaSrc : imgElement.src;
      window.open(mediaSrcUrl, "_blank");
    }
  };

  // console.log(decode);
  const getSummary = async (patientId) => {
    try {
      const res = await axios.get(
        `${configJson.backend_URL}/patient/summary/${patientId}`
      );
      // console.log(res);
      setPatientSummary(res?.data?.summaryReport
      )
      // console.log(patientSummary)
    } catch (err) {
      console.log(err);
    }
  };
  const generateSummary = async (patientId) => {
    try {
      // interactions.IsVisitUpdated
      const res = await axios.post(
        `${configJson.backend_URL}/patient/generateSummary/${patientId}`
      );
      getSummary(userDetails?.patientId)
    } catch (err) {
      console.log(err);
    }
  };
  // console.log(userDetails?.patientId, "lkjhg");

  // useEffect(() => {
  //   generateSummary(userDetails?.patientId);
  //   getSummary(userDetails?.patientId);
  // }, [userDetails]);

  const ress = {
    query: "write a summary",
    response: {
      Summary:
        "A patient's vital signs were recorded.  The patient's height was measured as 145 cm and weight as 34 kg.  Pulse, blood pressure (systolic and diastolic), and oxygen saturation were not recorded.  No information about the patient's medical history, current medications, or the reason for this vital sign check is available from the provided context.  Additional information is needed to provide a more comprehensive summary.  Further details regarding the patient's condition, symptoms, or the context surrounding this visit would be beneficial.  It is important to gather a complete picture of the patient's health status to ensure appropriate care.  This limited information underscores the importance of complete documentation in healthcare settings.  More data points would allow for a more thorough assessment and potentially reveal trends or patterns in the patient's health.  The absence of key vital signs like pulse, blood pressure, and oxygen saturation makes it difficult to draw any conclusions about the patient's current state.  Further investigation and data collection are recommended.",
      Vitals:
        "Height: 145 cm\nWeight: 34 kg\nPulse: Not recorded\nSystolic Blood Pressure: Not recorded\nDiastolic Blood Pressure: Not recorded\nOxygen Saturation: Not recorded",
      Medication: "No medication information available.",
    },
    image_url: [
      "https://storage.googleapis.com/medscribe-test/d9363a46_2024_12_09_20_17_29_2.jpg",
      "https://storage.googleapis.com/medscribe-test/d9363a46_2024_12_09_20_17_29_1.jpg",
      "https://storage.googleapis.com/medscribe-test/d9363a46_2024_12_09_20_17_29_0.jpg",
    ],
  };

  return (
    <>
      {/* <div>
        <ChatbotQuery
          organizationId={decode.organization_id}
          doctorId={decode.doctor_id}
          patientId={interactions?.patient_id}
          staticQns={staticQns}
          setStaticQns={setStaticQns}
        />
      </div> */}
      {/* <MediaModal
        mediaModal={mediaModal}
        handleClose={handleClose}
        images={images}
        userDetails={userDetails}
      /> */}
      <div className="flex flex-col gap-3 w-full m-auto sticky top-0 z-50 bg-white mb-2">
        <div className="flex items-center justify-center w-full m-auto ">
          <div
            className={`m-auto !w-1/2 cursor-pointer border-b-2  ${
              content === "detail" ? "!border-[#ACACAC]" : ""
            }`}
            style={{ borderColor: "transparent" }}
            onClick={() => setContent("detail")}
          >
            <img src={`/patient.png`} className="m-auto" width={32} />
          </div>
          <div
            className={`m-auto !w-1/2 cursor-pointer border-b-2  ${
              content === "summary" ? "!border-[#ACACAC]" : ""
            }`}
            style={{ borderColor: "transparent" }}
          //   onClick={() => {
          //     setContent("summary")
          //     generateSummary(userDetails?.patientId)
          //     // getSummary(userDetails?.patientId)
          //   }
          // }
          >
            <img src={`/appointment.png`} className="m-auto" width={32} />
          </div>
        </div>
      </div>
      <ImgCarousel
        carouselModal={carouselModal}
        setCarouselModal={setCarouselModal}
        images={images}
        setImages={setImages}
      />
      <UploadFiles
        show={uploadModal}
        onClose={() => setUploadModal(false)}
        patientId={
          userDetails?.patientId != undefined ? userDetails?.patientId : patId
        }
        doctorId={
          userDetails?.doctorId !== null ? userDetails?.doctorId : docId
        }
        setUploadModal={setUploadModal}
        fetchInteractions={fetchInteractions}
      />
      <VitalsModal
        vitalsModal={vitalsModal}
        setVitalsModal={setVitalsModal}
        patientId={
          userDetails?.patientId != undefined ? userDetails?.patientId : patId
        }
        doctorId={
          userDetails?.doctorId !== null ? userDetails?.doctorId : docId
        }
        fetchInteractions={fetchInteractions}
      />
      {content === "detail" && (
        <>
          <div className="w-full">
            <ChatbotQuery
              organizationId={decode.organization_id}
              doctorIdDatasheet={decode.doctor_id}
              doctorId={decode.doctor_id}
              // role={decode?.speciality_id ? "doctor" : decode?.role}
              // doctorId={
              //   userDetails?.doctorId !== null ? userDetails?.doctorId : docId
              // }
              patientId={interactions}
              audioUrlInteractionId={audioUrlInteractionId}
              fetchInteractions={fetchInteractions}
              showBillingTemplate={showBillingTemplate}
              setShowBillingTemplate={setShowBillingTemplate}
              // staticQns={staticQns}
              // setStaticQns={setStaticQns}
              setUploadModal={setUploadModal}
              setVitalsModal={setVitalsModal}
              setSummaryDrawer={setSummaryDrawer}
              setShowTempComp={setShowTempComp}
            />
          </div>
          <PatientSummary
            summaryDrawer={summaryDrawer}
            setSummaryDrawer={setSummaryDrawer}
          />
          {/* {showBillingTemplate && <BillingDefaultTemplate />} */}
          <div className="rounded-lg flex flex-col gap-4 pt-4 feed">
            {/* {showTempComp && 
              <div className="p-8 bg-gray-200 m-auto w-full">
                <div>
                  <button onClick={() => setShowTempComp(false)}>Close</button>
                  <BillingDefaultTemplate />
                </div>
              </div>
              } */}
            {sortedInteractions?.length > 0 ? (
              sortedInteractions?.map((data) => {
                const imageUrls = data?.InteractionDetails.filter((detail) =>
                  ["jpg", "jpeg", "png", "gif", "jfif", "avif"].includes(
                    detail?.processedFileContent?.split(".").pop().toLowerCase()
                  )
                ).map((detail) => ({
                  url: detail?.processedFileContent,
                  date: detail?.created_at,
                }));
                const breakpointColumnsObj = {
                  default: 3,
                  1100: 2,
                  700: 1,
                };
                return (
                  <div className="m-auto w-full border p-2 sm:p-4 py-2 rounded-lg flex flex-col gap-1 cursor-pointer bg-gray-200 transition-all duration-300">
                    <div className="flex flex-col gap-2 sticky top-0 p-2 bg-gray-200 rounded-b-lg z-40">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center justify-between w-full gap-2">
                          <div className="flex items-center gap-1">
                            <div className="font-semibold sm:text-lg">
                              {interactions?.first_name}{" "}
                              {interactions?.last_name}
                            </div>
                            <div>-</div>
                            <div className="text-sm">
                              {convertToKolkataTimeFormat(data?.updated_at)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* {imageUrls?.length > 0 ? (
                  <div
                    className="m-auto bg-white p-2"
                    onClick={() => {
                      fetchImages();
                      setCarouselModal(true);
                    }}
                  >
                    {imageUrls.length == 1 && (
                      <div>
                        <img src={imageUrls[0].url} alt="" />
                      </div>
                    )}
                    {imageUrls.length === 2 && (
                      <div className="flex items-center gap-1">
                        <div
                          className="size-[200px] overflow-hidden"
                          style={{
                            backgroundImage: `url(${imageUrls[0].url})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        />
                        <div
                          className="size-[200px] overflow-hidden"
                          style={{
                            backgroundImage: `url(${imageUrls[1].url})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        />
                      </div>
                    )}

                    {imageUrls.length == 3 && (
                      <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-1">
                          <div
                            className="size-[200px] overflow-hidden"
                            style={{
                              backgroundImage: `url(${imageUrls[0].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                          <div
                            className="size-[200px] overflow-hidden"
                            style={{
                              backgroundImage: `url(${imageUrls[1].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                        </div>
                        <div
                          className="w-full h-[120px] overflow-hidden"
                          style={{
                            backgroundImage: `url(${imageUrls[2].url})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        />
                      </div>
                    )}
                    {imageUrls.length === 4 && (
                      <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-1">
                          <div
                            className="size-[200px] overflow-hidden"
                            style={{
                              backgroundImage: `url(${imageUrls[0].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                          <div
                            className="size-[200px] overflow-hidden"
                            style={{
                              backgroundImage: `url(${imageUrls[1].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                        </div>
                        <div className="flex items-center gap-1">
                          <div
                            className="w-[65%] h-[150px] overflow-hidden"
                            style={{
                              backgroundImage: `url(${imageUrls[2].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                          <div
                            className="w-[35%] h-[150px] overflow-hidden flex items-center justify-center text-white text-xl font-semibold"
                            style={{
                              backgroundImage: `url(${imageUrls[3].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {imageUrls.length >= 5 && (
                      <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-1">
                          <div
                            className="size-[200px] overflow-hidden"
                            style={{
                              backgroundImage: `url(${imageUrls[0].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                          <div
                            className="size-[200px] overflow-hidden"
                            style={{
                              backgroundImage: `url(${imageUrls[1].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                        </div>
                        <div className="flex items-center gap-1">
                          <div
                            className="w-1/3 h-[150px] overflow-hidden"
                            style={{
                              backgroundImage: `url(${imageUrls[2].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                          <div
                            className="w-1/3 h-[150px] overflow-hidden flex items-center justify-center text-white text-xl font-semibold"
                            style={{
                              backgroundImage: `url(${imageUrls[3].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                          <div
                            className="w-1/3 h-[150px] overflow-hidden flex items-center justify-center text-white text-xl font-semibold"
                            style={{
                              backgroundImage: `url(${imageUrls[4].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          >
                            + {imageUrls.length - 4}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )} */}
                    {/* <div className="gallery">
                  {imageUrls.map((img, index) => {
                    return (
                      <div className="pics" key={index}>
                        <img src={img.url} alt="" style={{width: '100%'}} />
                      </div>
                    )
                  })}
                </div> */}
                    {/* <div className="image-grid">
      {imageUrls.map((img, index) => (
        <div className="image-grid-item" key={index}>
          <img src={img.url} alt={`Image ${index + 1}`} />
        </div>
      ))}
    </div> */}
                    <Masonry
                      breakpointCols={breakpointColumnsObj}
                      className="masonry-grid"
                      columnClassName="masonry-grid-column"
                      onClick={() => {
                        fetchImages();
                        setCarouselModal(true);
                      }}
                    >
                      {imageUrls.map((img, index) => (
                        <div className="image-grid-item" key={index}>
                          <img src={img.url} alt={`Image ${index + 1}`} />
                        </div>
                      ))}
                    </Masonry>

                    {data?.InteractionDetails.length > 0 ? (
                      data.InteractionDetails.map((detail, index) => {
                        const excludedTypes = [
                          "mri",
                          "blood test",
                          "ct scan",
                          "processed-speech-to-txt",
                          "x-ray",
                          "ultrasound",
                          "ecg",
                          "biopsy",
                          "ct angiography",
                          "nuclear medicine",
                          "1",
                          "2",
                          "3",
                          "4",
                          "5",
                          "6",
                          "7",
                          "8",
                          "9",
                          "soap-error",
                          "raw-audio-txt",
                          "Edited Prescription"
                        ];

                        // if (!detail?.processedFileContent) return null;
                        if (
                          excludedTypes.includes(
                            detail?.interaction_detail_type
                          )
                        )
                          return null;

                        const contentType = renderMedia(
                          detail?.processedFileContent
                        )?.type;
                        // console.log(contentType, 'RRR')

                        const isExpanded = expanded === detail.detail_id;
                        if (contentType == "img") return null;

                        return (
                          <div
                            key={index}
                            className={`p-2 my-1 ${
                              contentType == "audio"
                                ? ""
                                : "bg-white rounded-2xl"
                            } text-sm sm:text-base`}
                            ref={comnotes}
                          >
                            {detail.interaction_detail_type === "vitals" &&
                              detail?.vitals !== null && (
                                <>
                                  {/* <div className="pointer-events-none w-fit m-auto"> */}
                                  <div className="pointer-events-none">
                                    {detail?.vitals?.height !== "" && (
                                      <div className="flex flex-col gap-2">
                                        <div className="text-xl font-semibold mb-2">
                                          Basic details
                                        </div>
                                        <div className="flex items-center gap-4  mb-2">
                                          <div className="flex items-center gap-4">
                                            <div>Height (cm)</div>
                                            <Input
                                              type="number"
                                              className="outline-none border p-1 rounded bg-slate-200"
                                              value={detail?.vitals?.height}
                                              readOnly
                                            />
                                          </div>
                                          <div className="flex items-center gap-4">
                                            <div>Weight (kg)</div>
                                            <Input
                                              type="number"
                                              className="outline-none border p-1 rounded bg-slate-200"
                                              value={detail?.vitals?.weight}
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    <div className="flex flex-col gap-2">
                                      {(detail?.vitals?.systolicBP !== "" ||
                                        detail?.vitals?.diastolicBP !== "" ||
                                        detail?.vitals?.pulse !== "" ||
                                        detail?.vitals?.oxygenSaturation !==
                                          "") && (
                                        <div className="text-xl font-semibold">
                                          Vitals
                                        </div>
                                      )}
                                      {(detail?.vitals?.systolicBP !== "" ||
                                        detail?.vitals?.diastolicBP !== "") && (
                                        <div className="border-black border rounded p-2 flex flex-col gap-2 ">
                                          <div className="font-semibold ">
                                            Blood pressure
                                          </div>
                                          <div className="flex items-center gap-2 justify-between">
                                            <div className="w-fit">
                                              Systolic blood pressure (mmHg)
                                            </div>
                                            <Input
                                              type="number"
                                              className={`outline-none border-2 p-1 rounded w-fit bg-slate-200`}
                                              value={detail?.vitals?.systolicBP}
                                              style={{
                                                borderColor:
                                                  detail?.vitals?.systolicBP >
                                                  140
                                                    ? "red"
                                                    : "transparent",
                                              }}
                                              readOnly
                                            />
                                            <div className="w-[100px]">
                                              (100 - 140)
                                            </div>
                                            <Checkbox
                                              checked={
                                                detail?.vitals?.systolicBP > 140
                                              }
                                            >
                                              Abnormal
                                            </Checkbox>
                                          </div>
                                          <div className="flex items-center gap-2 justify-between">
                                            <div className="w-fit">
                                              Diastolic blood pressure (mmHg)
                                            </div>
                                            <Input
                                              type="number"
                                              className="outline-none border-2 p-1 rounded w-fit bg-slate-200"
                                              value={
                                                detail?.vitals?.diastolicBP
                                              }
                                              style={{
                                                borderColor:
                                                  detail?.vitals?.diastolicBP >
                                                  90
                                                    ? "red"
                                                    : "transparent",
                                              }}
                                              readOnly
                                            />
                                            <div className="w-[100px]">
                                              (60 - 90)
                                            </div>
                                            <Checkbox
                                              checked={
                                                detail?.vitals?.diastolicBP > 90
                                              }
                                            >
                                              Abnormal
                                            </Checkbox>
                                          </div>
                                          <div className="flex items-center justify-between">
                                            <div className="font-semibold ">
                                              Body position
                                            </div>
                                            <Radio.Group
                                              value={detail?.vitals?.position}
                                            >
                                              <Radio.Button value="sitting">
                                                Sitting
                                              </Radio.Button>
                                              <Radio.Button value="recumbent">
                                                Recumbent
                                              </Radio.Button>
                                              <Radio.Button value="unknown">
                                                Unknown
                                              </Radio.Button>
                                              <Radio.Button value="other">
                                                Other
                                              </Radio.Button>
                                              <Radio.Button value="standing">
                                                Standing
                                              </Radio.Button>
                                              <Radio.Button value="fowler">
                                                Fowler
                                              </Radio.Button>
                                            </Radio.Group>
                                          </div>
                                        </div>
                                      )}
                                      {detail?.vitals?.pulse !== "" && (
                                        <div className="flex items-center gap-2 justify-between flex-wrap ">
                                          <div className="w-fit">
                                            Pulse (beats/min)
                                          </div>
                                          <Input
                                            type="number"
                                            className="outline-none border-2 p-1 rounded w-fit bg-slate-200"
                                            value={detail?.vitals?.pulse}
                                            style={{
                                              borderColor:
                                                detail?.vitals?.pulse > 100
                                                  ? "red"
                                                  : "transparent",
                                            }}
                                            readOnly
                                          />
                                          <div className="">(60 - 100)</div>
                                          <Checkbox
                                            checked={
                                              detail?.vitals?.pulse > 100
                                            }
                                          >
                                            Abnormal
                                          </Checkbox>
                                        </div>
                                      )}
                                      {detail?.vitals?.oxygenSaturation !==
                                        "" && (
                                        <div className="flex items-center gap-2 justify-between ">
                                          <div className="w-fit">
                                            Arterial blood oxygen saturation
                                            (pulse oximeter) (%)
                                          </div>
                                          <Input
                                            type="number"
                                            className="outline-none border-2 p-1 rounded w-fit bg-slate-200"
                                            value={
                                              detail?.vitals?.oxygenSaturation
                                            }
                                            style={{
                                              borderColor:
                                                detail?.vitals
                                                  ?.oxygenSaturation > 95
                                                  ? "red"
                                                  : "transparent",
                                            }}
                                            readOnly
                                          />
                                          <div className="">(&gt;95)</div>
                                          <Checkbox
                                            checked={
                                              detail?.vitals?.oxygenSaturation >
                                              95
                                            }
                                          >
                                            Abnormal
                                          </Checkbox>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}

                            {detail?.interaction_detail_type ==
                              "soap-notes" && (
                              <>
                                {editmodel ? (
                                  <MarkdownEditorModal
                                    data={detail.processedFileContent}
                                    id={detail.detail_id}
                                    show={editmodel}
                                    patient_id={interactions.patient_id}
                                    name={`${interactions?.first_name} ${interactions?.last_name}`}
                                    handleClose={handleCloseModel}
                                    fetchInteractions={fetchInteractions}
                                  />
                                ) : (
                                  ""
                                )}
                                <div className="flex items-center justify-end">
                                  {copied && (
                                    <span className="copy-feedback">
                                      Copied!
                                    </span>
                                  )}
                                  <button
                                    type="button"
                                    className="text-btn-top  p-1 w-fit"
                                    onClick={handelOpenmodel}
                                  >
                                    <RiFileEditFill size={15} />
                                  </button>
                                  <CopyToClipboard
                                    text={getDivContent()}
                                    onCopy={() => setCopied(true)}
                                  >
                                    <button
                                      type="button"
                                      className="text-btn-top  p-1 w-fit"
                                    >
                                      <IoIosCopy size={15} />
                                    </button>
                                  </CopyToClipboard>
                                  <div
                                    className=" p-1 w-fit"
                                    onClick={() =>
                                      handlePrint(detail.detail_id)
                                    }
                                  >
                                    <MdLocalPrintshop />
                                  </div>
                                </div>
                              </>
                            )}
                            <div className={`flex flex-col gap-2`}>
                              <div
                                className={`${
                                  contentType == "audio" ? "!h-fit" : ""
                                }`}
                                style={isExpanded ? null : style}
                                ref={(el) =>
                                  (ref.current[detail.detail_id] = el)
                                }
                                // onClick={() => {
                                //   fetchImages();
                                //   setCarouselModal(true);
                                // }}
                              >
                                {renderMedia(
                                  detail?.processedFileContent,
                                  openFullScreen
                                )}
                              </div>
                            </div>
                            {renderMedia(detail?.processedFileContent)?.type !==
                              "audio" &&
                              showReadBtn[detail.detail_id] && (
                                <div
                                  className="w-fit float-right cursor-pointer text-blue-500"
                                  onClick={() => toggleExpand(detail.detail_id)}
                                >
                                  {isExpanded ? "Show less" : "Read more"}
                                </div>
                              )}
                          </div>
                        );
                      })
                    ) : (
                      <div className="text-center mt-20">
                        No details available
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="text-center text-lg font-semibold mt-10">
                No interactions available
              </div>
            )}
          </div>
        </>
      )}
      {content === "summary" && (
        <div className="w-full">
          <div className="flex flex-col gap-3 mt-4 p-6 w-full">
            <div>
              <div className="text-lg font-semibold">Summary:</div>
              <div>{patientSummary?.summary}</div>
            </div>
            <div>
              <div className="text-lg font-semibold">Vitals report:</div>
              <div>
                {patientSummary?.Vitalsummary}
              </div>
            </div>
            <div>
              <div className="text-lg font-semibold">Medication:</div>
              <div>
              {patientSummary?.medSummary}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Feed;

const MediaModal = ({ mediaModal, handleClose, images, userDetails }) => {
  const [uploadModal, setUploadModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [zoom, setZoom] = useState(1); // for zooming the image
  const imgRef = useRef(null);
  const data = userDetails;

  // Helper to detect if device is mobile
  const isMobile = () => window.innerWidth <= 768;

  // const openFullScreen = (event) => {
  //   const imgElement = event.target;
  //   if (!isMobile()) {
  //     if (imgElement.requestFullscreen) {
  //       imgElement.requestFullscreen();
  //     } else if (imgElement.mozRequestFullScreen) {
  //       // Firefox
  //       imgElement.mozRequestFullScreen();
  //     } else if (imgElement.webkitRequestFullscreen) {
  //       // Chrome, Safari and Opera
  //       imgElement.webkitRequestFullscreen();
  //     } else if (imgElement.msRequestFullscreen) {
  //       // IE/Edge
  //       imgElement.msRequestFullscreen();
  //     }
  //     imgRef.current = imgElement;
  //   } else {
  //     // For mobile, open image in a new tab instead of fullscreen
  //     window.open(mediaSrc.url, "_blank");
  //   }
  // };

  {
    /* {detail.interaction_detail_type === "vitals" &&
                          detail?.vitals !== null && (
                            <div className="flex flex-col items-center text-black gap-3 md:w-[420px] bg-white shadow-lg rounded-lg p-4">
                              {detail?.vitals?.height !== "" && (
                                <div className="flex items-center justify-between gap-4 w-full border-b pb-1">
                                  <div className="flex items-center gap-2">
                                    <div className="font-medium text-gray-700">
                                      Height
                                    </div>
                                    <div className="bg-gray-200 text-gray-800 px-3 py-1 rounded-lg text-sm">
                                      {detail?.vitals?.height} cm
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    <div className="font-medium text-gray-700">
                                      Weight
                                    </div>
                                    <div className="bg-gray-200 text-gray-800 px-3 py-1 rounded-lg text-sm">
                                      {detail?.vitals?.weight} kg
                                    </div>
                                  </div>
                                </div>
                              )}
                              {detail?.vitals?.systolicBP !== "" && (
                                <div className="flex items-center justify-between gap-4 w-full border-b pb-1">
                                  <div className="flex items-center gap-2">
                                    <div className="font-medium text-gray-700">
                                      Systolic BP
                                    </div>
                                    <div className="bg-gray-200 text-gray-800 px-3 py-1 rounded-lg text-sm">
                                      {detail?.vitals?.systolicBP} mmHg
                                    </div>
                                  </div>
                                  <div
                                    className={`text-white font-semibold px-3 py-1 rounded-lg text-sm ${
                                      detail?.vitals?.systolicBP > 140
                                        ? "bg-red-500"
                                        : "bg-green-500"
                                    }`}
                                  >
                                    {detail?.vitals?.systolicBP > 140
                                      ? "Abnormal"
                                      : "Normal"}
                                  </div>
                                </div>
                              )}
                              {detail?.vitals?.diastolicBP !== "" && (
                                <div className="flex items-center justify-between gap-4 w-full border-b pb-1">
                                  <div className="flex items-center gap-2">
                                    <div className="font-medium text-gray-700">
                                      Diastolic BP
                                    </div>
                                    <div className="bg-gray-200 text-gray-800 px-3 py-1 rounded-lg text-sm">
                                      {detail?.vitals?.diastolicBP} mmHg
                                    </div>
                                  </div>
                                  <div
                                    className={`text-white font-semibold px-3 py-1 rounded-lg text-sm ${
                                      detail?.vitals?.diastolicBP > 90
                                        ? "bg-red-500"
                                        : "bg-green-500"
                                    }`}
                                  >
                                    {detail?.vitals?.diastolicBP > 90
                                      ? "Abnormal"
                                      : "Normal"}
                                  </div>
                                </div>
                              )}
                              {detail?.vitals?.position !== "" && (
                                <div className="flex items-center justify-between gap-4 w-full border-b pb-1">
                                  <div className="font-medium text-gray-700">
                                    Body Position
                                  </div>
                                  <div className="bg-gray-200 text-gray-800 px-3 py-1 rounded-lg text-sm">
                                    {detail?.vitals?.position}
                                  </div>
                                </div>
                              )}
                              {detail?.vitals?.pulse !== "" && (
                                <div className="flex items-center justify-between gap-4 w-full border-b pb-1">
                                  <div className="flex items-center gap-2">
                                    <div className="font-medium text-gray-700">
                                      Pulse
                                    </div>
                                    <div className="bg-gray-200 text-gray-800 px-3 py-1 rounded-lg text-sm">
                                      {detail?.vitals?.pulse}
                                    </div>
                                  </div>
                                  <div
                                    className={`text-white font-semibold px-3 py-1 rounded-lg text-sm ${
                                      detail?.vitals?.pulse > 100
                                        ? "bg-red-500"
                                        : "bg-green-500"
                                    }`}
                                  >
                                    {detail?.vitals?.pulse > 100
                                      ? "Abnormal"
                                      : "Normal"}
                                  </div>
                                </div>
                              )}
                              {detail?.vitals?.oxygenSaturation !== "" && (
                                <div className="flex items-center justify-between gap-4 w-full">
                                  <div className="flex items-center gap-2">
                                    <div className="font-medium text-gray-700">
                                      Oxygen Saturation
                                    </div>
                                    <div className="bg-gray-200 text-gray-800 px-3 py-1 rounded-lg text-sm">
                                      {detail?.vitals?.oxygenSaturation}%
                                    </div>
                                  </div>
                                  <div
                                    className={`text-white font-semibold px-3 py-1 rounded-lg text-sm ${
                                      detail?.vitals?.oxygenSaturation > 140
                                        ? "bg-red-500"
                                        : "bg-green-500"
                                    }`}
                                  >
                                    {detail?.vitals?.oxygenSaturation > 140
                                      ? "Abnormal"
                                      : "Normal"}
                                  </div>
                                </div>
                              )}
                            </div>
                          )} */
  }

  const closeFullScreen = () => {
    if (!isMobile()) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else if (document.webkitFullscreenElement) {
        // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.mozFullScreenElement) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.msFullscreenElement) {
        // IE/Edge
        document.msExitFullscreen();
      }
    }
  };

  const zoomIn = () => {
    if (imgRef.current) {
      setZoom((prevZoom) => prevZoom + 0.1);
      imgRef.current.style.transform = `scale(${zoom + 0.1})`;
    }
  };

  const zoomOut = () => {
    if (imgRef.current && zoom > 1) {
      setZoom((prevZoom) => prevZoom - 0.1);
      imgRef.current.style.transform = `scale(${zoom - 0.1})`;
    }
  };

  const handleCancel = () => {
    setShowDropdown(false);
  };

  return (
    <Modal show={mediaModal} onHide={handleClose}>
      {showDropdown && (
        <UploadFiles
          show={showDropdown}
          onClose={handleCancel}
          interactionId={userDetails?.interactionId}
          patientId={userDetails?.patientId}
        />
      )}
      <Modal.Header closeButton>
        <Modal.Title>Documents</Modal.Title>
      </Modal.Header>
      <Modal.Body className="h-[65vh] overflow-auto text-center">
        <button
          className="rounded text-white p-2 px-4 w-fit m-auto"
          onClick={() => setShowDropdown(true)}
          style={{ background: "linear-gradient(to right, #1C1B1B, #5B5B5B)" }}
        >
          Upload Documents
        </button>
        {images.length > 0 ? (
          images.map((image, index) => (
            <div key={index} className="text-center flex flex-col gap-2 mt-2">
              {/* <div className="mt-2 text-gray-500">
                {new Date(image.date).toLocaleString()}
              </div> */}
              <img
                src={image}
                alt=""
                className="rounded-lg cursor-pointer m-auto"
                // onClick={openFullScreen}
                ref={imgRef}
              />
            </div>
          ))
        ) : (
          <p className="mt-6">No documents to display.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>

      {/* Fullscreen image controls */}
      {!isMobile() && document.fullscreenElement && (
        <div className="fullscreen-controls">
          <button onClick={zoomIn}>Zoom In</button>
          <button onClick={zoomOut}>Zoom Out</button>
          <button onClick={closeFullScreen}>Close Fullscreen</button>
        </div>
      )}
    </Modal>
  );
};
