import React, { useState, useEffect } from "react";
import axios from "axios";
import BillingDefaultTemplate from "../BillingDefaultTemplate";

const FetchInvoiceNinjaProducts = () => {
  const [products, setProducts] = useState([]); // State to store products list
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Constants for API
  const API_URL = "https://invoice.medscribe.in/api/v1/products";
  const API_TOKEN = "z3VjplPVEKQzDvCAAjYvHSpneJRUJGEm4watX53N5WHf7tmoPkvOsxhqikfkVLfK";

  // Function to fetch all products
  const fetchAllProducts = async () => {
    setLoading(true);
    setError("");
    setProducts([]);

    try {
      // Fetch products
      const response = await axios.get(API_URL, {
        headers: {
          "X-API-Token": API_TOKEN,
        },
      });

      // Store the fetched products in state
      const fetchedProducts = response.data.data;
      setProducts(fetchedProducts);
    } catch (err) {
      setError("Error fetching products. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // UseEffect to fetch products when the component mounts
  useEffect(() => {
    fetchAllProducts();
  }, []);

  return (
    <div>
      <h1>Fetched Products from Invoice Ninja</h1>

      {loading && <p>Loading...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}

      {products.length > 0 && (
        <div>
          <h2>Product List</h2>
          <ul>
            {products.map((product) => (
              <li key={product.id}>
                <strong>{product.product_key}</strong> - {product.notes} - ${product.price}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Pass the products data to BillingDefaultTemplate */}
      {/* <BillingDefaultTemplate productslistninja={products} /> */}
    </div>
  );
};

export default FetchInvoiceNinjaProducts;
