import React, { useState, useEffect, useRef } from "react";
import { Container, Button } from "react-bootstrap";
import axios from "axios";
import InvoiceHeader from "./Components/InvoiceHeader";
import PatientInfo from "./Components/PatientInfo";
import ChargesTable from "./Components/ChargesTable";
import Footer from "./Components/InvoiceFooter";

const BillingDefaultTemplate = ({
  patientId,
  registrationNumber,
  date,
  patientName,
  patientAddress,
  doctorName,
  doctorId,
  tnmcRegNo,
  appointmentNo,
  gender,
  dob,
  maritalStatus,
  mobileNumber,
  fetchInteractions,
  answer,
  onAnswerChange,
  productslistninja,
}) => {
  // State for managing product data and loading/error states
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  console.log("Prodc", productslistninja[0]);
  // Create a reference for the content to print
  const printRef = useRef();

  // Fetch products from the Invoice Ninja API
  const fetchAllProducts = async () => {
    setLoading(true);
    setError("");
    setProducts([]);

    try {
      const response = await axios.get(
        "https://invoice.medscribe.in/api/v1/products",
        {
          headers: {
            "X-API-Token":
              "z3VjplPVEKQzDvCAAjYvHSpneJRUJGEm4watX53N5WHf7tmoPkvOsxhqikfkVLfK",
          },
        }
      );
      setProducts(response.data.data); // Set fetched products in state
    } catch (err) {
      setError("Error fetching products. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // UseEffect to fetch products on mount
  useEffect(() => {
    fetchAllProducts();
  }, []);

  // Prepare data for the invoice
  const data = {
    hospitalDetails: {
      name: "GUNAM SUPER SPECIALITY HOSPITAL",
      association: "(A UNIT OF HOSUR SUPER SPECIALITY HOSPITALS PVT. LTD)",
      address: `#35, TANK STREET, (OLD SURVEY NO: 120/2B2),
        DENKANIKOTTA ROAD, KRISHNAGIRI (DT),
        HOSUR - 635109`,
      phone: "04344-220599",
      email: "info@gunamhospital.com",
      cin: "33AADCH2048B1Z9",
      gst: "U85100TZ2013PTC019427",
    },
    logoUrl: `/headlogotemplate.png`,
    invoiceDetails: {
      billNo: appointmentNo || "Not Available",
      date: date || "Not Available",
      registrationNo: patientId || "",
      doctor: `${doctorName || "Doctor Not Available"} ${
        tnmcRegNo ? `(TNMC Reg No: ${tnmcRegNo})` : ""
      }`,
      department: "General and Laparoscopic Surgery",
      patientType: "General",
    },
    patient: {
      name: patientName || "Not Available",
      // age: calculateAge(dob),
      gender: gender || "Not Available",
      address: patientAddress || "Not Available",
    },
    doctor: {
      name: doctorName || "Not Available",
      dept: "General",
    },
    billInfo: {
      validity: date || "Not Available",
    },
    charges: [
      {
        headerName: "Consultation Charges",
        serviceName: `${doctorName || "Doctor"} Consultation Charges`,
        qty: 1,
        rate: 300.0,
        amount: 300.0,
      },
    ],
    total: {
      billAmount: 300.0,
      paidAmount: 300.0,
    },
    payment: {
      mode: "Card",
      referenceNo: "1982",
    },
    remarks: answer || "Review Registration Bill",
    productDetails: products.map((product) => ({
      product_key: product.product_key,
      notes: product.notes,
      price: product.price,
    })),
  };

  // Function to handle print action
  const handlePrint = () => {
    const content = printRef.current;
    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.width = "0px";
    iframe.style.height = "0px";
    iframe.style.border = "none";
    document.body.appendChild(iframe);

    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write(`
      <html>
        <head>
          <title>Print Invoice</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; font-size: 12pt; }
            .container { max-width: 100%; padding: 0; }
            .print-button { display: none; }
            .footer, .navbar { display: none; }
          </style>
        </head>
        <body>
          ${content.innerHTML}
        </body>
      </html>
    `);
    doc.close();
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
    iframe.remove();
  };

  return (
    <Container
      style={{ background: "#fff", maxWidth: "1095px" }}
      className="border p-4 my-4"
    >
      {/* Print Button */}
      <Button
        variant="primary"
        onClick={handlePrint}
        style={{ marginBottom: "20px" }}
      >
        Print Invoice
      </Button>

      {/* Content to Print */}
      <div ref={printRef}>
        <InvoiceHeader
          hospitalDetails={data.hospitalDetails}
          association={data.association}
          logoUrl={data.logoUrl}
          invoiceDetails={data.invoiceDetails}
          products={products} // Passing fetched products to child components
        />
        <PatientInfo
          patient={data.patient}
          doctor={data.doctor}
          billInfo={data.billInfo}
        />
        <ChargesTable
          charges={data.charges}
          productDetails={data.productDetails}
        />
        <Footer
          total={data.total}
          payment={data.payment}
          remarks={data.remarks}
        />
      </div>
    </Container>
  );
};

export default BillingDefaultTemplate;
