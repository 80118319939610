import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { configJson } from "../../Constent/config";
import { AddNewDoctor, EditDoctorForm } from "./Doctorstyle";
import { Form, Button } from "react-bootstrap";

const EditUserModal = ({
  show,
  handleCloseUser,
  token,
  setErrorMessage,
  userData,
  setEditedData,
  handleCloseEdit
}) => {
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: userData,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [specialties, setSpecialties] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState("");
  const [doctorData, setDoctorData] = useState(null);

  // console.log("Userdatachck", userData);

  useEffect(() => {
    const fetchSpecialities = async () => {
      try {
        const response = await fetch(`${configJson.backend_URL}/specialties/`);
        const data = await response.json();
        if (Array.isArray(data)) {
          setSpecialties(data);
        }
      } catch (error) {
        console.error("Error fetching specialties:", error);
      }
    };
    fetchSpecialities();
  }, []);

  useEffect(() => {
    const fetchDoctorDetails = async () => {
      try {
        if (userData?.role?.toLowerCase() === "doctor") {
          const response = await fetch(
            `${configJson.backend_URL}/doctor/${userData.user_id}`,
            { method: "GET", headers: { Authorization: `Bearer ${token}` } }
          );

          if (response.ok) {
            const doctorInfo = await response.json();
            setDoctorData(doctorInfo);
            setValue("phone_number", doctorInfo.phone_number || "");
            setValue("license_no", doctorInfo.license_no || "");
            setSelectedSpecialty(doctorInfo.speciality_id || "");
          } else {
            setDoctorData(null);
          }
        }
      } catch (error) {
        console.error("Error fetching doctor details:", error);
      }
    };

    if (userData?.user_id) {
      fetchDoctorDetails();
    }
  }, [userData, setValue, token]);

  useEffect(() => {
    if (specialties.length && (doctorData || userData)) {
      setValue("id", userData.user_id);
      setValue("first_name", userData.first_name || "");
      setValue("last_name", userData.last_name || "");
      setValue("email", userData.email || "");
      setValue(
        "phone_number",
        doctorData?.phone_number || userData.phone_number || ""
      );
      setValue(
        "license_no",
        doctorData?.license_no || userData.license_no || ""
      );
      setSelectedSpecialty(
        doctorData?.speciality_id || userData.speciality_id || ""
      );
    }
  }, [specialties, doctorData, userData, setValue]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      // Prepare payload with common fields
      const payload = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone_number: data.phone_number || data.contact_number,
      };

      // Check if the user is a doctor based on the available `doctor_id`
      const isDoctor = Boolean(userData.doctor_id);
      const id = isDoctor ? userData.doctor_id : userData.user_id; // Use `doctor_id` for doctors and `user_id` for others

      // Add license_no and speciality_id only for doctors
      if (isDoctor) {
        payload.license_no = data.license_no || null;
        payload.speciality_id =
          selectedSpecialty || userData.speciality_id || null;
      }

      const requestOptions = {
        method: isDoctor ? "POST" : "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      };

      // Use the correct endpoint based on the role
      const endpoint = isDoctor
        ? `${configJson.backend_URL}/doctor/update_doctor/${id}`
        : `${configJson.backend_URL}/user/update_user/${id}`;

      const response = await fetch(endpoint, requestOptions);

      if (!response.ok) {
        const errorData = await response.json();
        setErrorMessage(errorData.message || "Failed to update details.");
      } else {
        const updatedData = await response.json();
        setEditedData(updatedData);
        handleCloseEdit();
      }
    } catch (error) {
      console.error("Error in form submission:", error);
      setErrorMessage("Failed to update details. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={handleCloseUser} key={userData?.user_id}>
      <AddNewDoctor>
        <Modal.Header closeButton>
          <div className="head-text-container">
            <h2 className="head-text">
              Edit {userData?.role || "User"}'s Details
            </h2>
          </div>
        </Modal.Header>
        <Modal.Body className="p-3">
          <EditDoctorForm>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter first name"
                  isInvalid={!!errors.first_name}
                  {...register("first_name", {
                    required: "First name is required",
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.first_name?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter user's last name"
                  isInvalid={!!errors.last_name}
                  {...register("last_name", {
                    required: "Last name is required",
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Invalid characters.",
                    },
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.last_name?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter user's email"
                  isInvalid={!!errors.email}
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                      message: "Invalid email address",
                    },
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formPhoneNumberOrContactNumber">
                <Form.Label>
                  {userData?.role === "front office staff" ||
                  userData?.role === "technician"
                    ? "Contact Number"
                    : "Phone Number"}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={
                    userData?.role === "front office staff" ||
                    userData?.role === "technician"
                      ? "Enter contact number"
                      : "Enter doctor's phone number"
                  }
                  isInvalid={
                    !!errors[
                      userData?.role === "front office staff" ||
                      userData?.role === "technician"
                        ? "contact_number"
                        : "phone_number"
                    ]
                  }
                  {...register(
                    userData?.role === "front office staff" ||
                      userData?.role === "technician"
                      ? "contact_number"
                      : "phone_number",
                    {
                      pattern: {
                        value: /^[0-9\s-]+$/,
                        message: "Only numbers, spaces, and dashes allowed.",
                      },
                    }
                  )}
                  defaultValue={
                    userData?.role === "front office staff" ||
                    userData?.role === "technician"
                      ? userData.contact_number || ""
                      : doctorData?.phone_number || ""
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {
                    errors[
                      userData?.role === "front office staff" ||
                      userData?.role === "technician"
                        ? "contact_number"
                        : "phone_number"
                    ]?.message
                  }
                </Form.Control.Feedback>
              </Form.Group>

              {!(
                userData?.role === "front office staff" ||
                userData?.role === "technician"
              ) && (
                <>
                  <Form.Group controlId="formLicenseNo">
                    <Form.Label>License No</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter license number"
                      isInvalid={!!errors.license_no}
                      {...register("license_no")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.license_no?.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formSpecialty">
                    <Form.Label>Specialty</Form.Label>
                    <Form.Control
                      as="select"
                      value={selectedSpecialty}
                      onChange={(e) => setSelectedSpecialty(e.target.value)}
                    >
                      <option value="">Select Specialty</option>
                      {specialties.map((specialty) => (
                        <option
                          key={specialty.speciality_id}
                          value={specialty.speciality_id}
                        >
                          {specialty.specialty_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </>
              )}

              <div className="btn-log">
                <Button type="submit" className="reg-sub-btn mt-2">
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </Form>
          </EditDoctorForm>
        </Modal.Body>
      </AddNewDoctor>
    </Modal>
  );
};

export default EditUserModal;