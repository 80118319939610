import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabContent, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "../Home/Components/Sidebar/Sidebar";
import AssessmentPage from "../AssessmentTemplate/AssessmentPage";
import DoctorDetails from "./DoctorDetails";
import "./DoctorDetails.css";
import MedicationTemplates from "../Templates/MedicationTemplates";
import KollaConnect from "../kolla/kollaToken";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import BillingInfoDoctor from "../Billing/BillingInformationDoctor";
import TemplateIntegration from "./Components/TemplateSettings/TemplateIntegration";

const Settings = () => {
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  const [decode, setDecode] = useState(null);
  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      setDecode(decoded);
      setRole(decoded.role);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    // <Sidebar setuser={true}>
    <div>
      <div className="heading-text">
        <h4 className="gradient-text">Settings</h4>
        <span class="heading-text-line"></span>
      </div>
      {role === "doctor" && (
        <Tabs defaultActiveKey="doctordetails" id="settings-tabs">
          <Tab eventKey="doctordetails" title="General">
            <TabContent>
              <div className="p-3">
                <DoctorDetails />
              </div>
            </TabContent>
          </Tab>
          <Tab eventKey="integration" title="Integration">
            <TabContent>
              <div className="p-3">
                <KollaConnect />
              </div>
            </TabContent>
          </Tab>
          <Tab eventKey="assessment" title="Assessment Setup">
            <TabContent>
              <div className="p-3">
                <AssessmentPage />
              </div>
            </TabContent>
          </Tab>
          <Tab eventKey="medication templates" title="Medication Setup">
            <TabContent>
              <div className="p-3">
                <MedicationTemplates />
              </div>
            </TabContent>
          </Tab>
          {/* <Tab eventKey="templates selection" title="Templates Setup">
            <TabContent>
              <div className="p-3">
              <TemplateIntegration />
              </div>
            </TabContent>
          </Tab> */}
          {/* <Tab eventKey="profile" title="Business Profile">
          <TabContent>
            <div className="p-3">
              <h4>Business Profile</h4>
              <p>Settings and information related to your business profile.</p>
            </div>
          </TabContent>
        </Tab>
        <Tab eventKey="billing" title="Billing">
          <TabContent>
            <div className="p-3">
              <h4>Billing</h4>
              <p>Manage your billing information here.</p>
            </div>
          </TabContent>
        </Tab>
        <Tab eventKey="staff" title="My Staff">
          <TabContent>
            <div className="p-3">
              <h4>My Staff</h4>
              <p>Details about staff members.</p>
            </div>
          </TabContent>
        </Tab>
        <Tab eventKey="opportunities" title="Opportunities">
          <TabContent>
            <div className="p-3">
              <h4>Opportunities</h4>
              <p>Manage opportunities and leads here.</p>
            </div>
          </TabContent>
        </Tab> */}
        </Tabs>
      )}

      {role === "front office staff" && (
        <Tabs defaultActiveKey="integration" id="settings-tabs">
          <Tab eventKey="integration" title="Integration">
            <TabContent>
              <div className="p-3">
                <KollaConnect />
              </div>
            </TabContent>
          </Tab>
          <Tab eventKey="billing" title="Billing">
            <TabContent>
              <div className="p-3">
                <p>Update Billing Details for the Doctor</p>
                <BillingInfoDoctor />
              </div>
            </TabContent>
          </Tab>
        </Tabs>
      )}
    </div>
    // </Sidebar>
  );
};

export default Settings;
