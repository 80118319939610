import styled from "styled-components";

export const EngineContainer = styled.div`
  .header-name-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    gap: 15px;
    .name-tag {
      .name-text {
        color: #5b5b5b;
        font-size: 18px;
      }
      @media (max-width: 767px) {
        margin-bottom: 0px;
      }
    }
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: left;
      gap: 1px;
    }
  }
  .Line-header {
    display: flex;
    flex-direction: row;
    justify-content: left;

    .gradient-line {
      width: 10%;
      height: 4px;
      background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
      border: none;
      margin: 0px;
    }

    .black-line {
      width: 90%;
      height: 1px;
      background-color: black;
      border: none;
      margin: 3px 0px 0px 0px;
      // margin-top: 18px;
    }
  }
  .empty {
    margin-top: 110px;
  }
  // .recorder-wav-container {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   // align-items: center;
  //   padding: 10px;
  //   .audioContainer div {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //   }
  //   .audioContainer > div:nth-child(2) {
  //     display: none;
  //   }
  //   .select-audio-type {
  //     width: 30%;
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     // align-items: center;
  //   }
  // }
  .top-record {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 110px;
    .recording-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      .audio-wave-out {
        width: 60%;
        @media (max-width: 767px) {
          width: 80%;
        }
      }
      .record-group-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        // margin-right: 50px;
        margin-top: 25px;
        gap: 5px;
        .start-stop-btn {
          display: flex;
          flex-direction: row;
          background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
          width: 254px;
          height: 72px;
          border-radius: 156px;
          padding: 9px;
          .button-content {
            display: flex;
            flex-direction: row;
            .rec-btn-icon {
              border: 1px solid black;
              border-radius: 50%;
              background-color: white;
              color: black;
              width: 49px;
              text-align: center;
              height: 49px;
              align-items: center;
              display: flex;
              justify-content: center;
              font-size: 23px;
            }
            .rec-btn-text {
              width: 185px;
              height: 51px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
            }
          }
        }
        @media (max-width: 767px) {
          gap: 2px;
          width: 350px;
          height: auto;
          margin-right: 0px;
        }
      }
    }

    .or-divider {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;
      color: rgb(105, 112, 125);
      font-size: 13px;
      padding-top: 15px;
      padding-bottom: 10px;
      width: 100%;
      .divide-line {
        flex-basis: auto;
        flex-grow: 1;
      }
    }
    .my-from {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
    .eng-sending {
      color: #fff;
      background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
      padding: 5px 20px 5px 20px;
    }

    .eng-sub {
      color: #fff;
      background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
      padding: 5px 20px 5px 20px;
    }
  }
`;

export const FollowUPButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  .follow {
    color: #fff;
    background-image: linear-gradient(to right, #b0981c, #b64c4c);
    padding: 5px 20px 5px 20px;
  }
  .finish {
    color: #fff;
    background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    padding: 5px 20px 5px 20px;
  }
`;

export const AudioWaveformBot = styled.div`
  border-radius: 60px;
  max-width: 100%;
  /* margin-right: 50px; */
  /* border-radius: 10px 10px 10px 0px; */
  padding: 5px;
  background-color: #d2d6d74d;
  .bot-waveform-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    .bot-audio-wave {
      display: flex;
      align-items: center;
      width: 100%;
      .bot-audio-wave-button {
        margin-right: 10px;
        border: none;
      }
      .bot-audio-wave-container {
        flex: 1;
        margin-right: 10px;
        width: 100px;
        height: 20px;
        position: relative;
        bottom: 14px;
      }
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }
`;

export const SoapNotesContainer = styled.div`
  padding: 10px;
  .header-name-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    gap: 15px;
    .name-tag {
      .name-text {
        color: #5b5b5b;
        font-size: 18px;
      }
      @media (max-width: 767px) {
        margin-bottom: 0px;
      }
    }
    @media (max-width: 767px) {
      gap: 0px;
    }
  }
  .Line-header {
    display: flex;
    flex-direction: row;
    justify-content: left;

    .gradient-line {
      width: 10%;
      height: 4px;
      background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
      border: none;
      margin: 0px;
    }

    .black-line {
      width: 90%;
      height: 1px;
      background-color: black;
      border: none;
      margin: 3px 0px 0px 0px;
      // margin-top: 18px;
    }
  }
  .form-design {
    display: flex;
    flex-direction: row;
    justify-content: left;
    .form-text {
      display: flex;
      flex-direction: column;
      justify-content: left;
      width: 80%;
      .lable-text {
        background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        font-size: 20px;
      }
      #Notes-text {
        height: 100px;
        padding: 10px;
        border: 1px solid black;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    .record-group-button-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 5px;
      gap: 5px;
      .color-btn {
        color: #fff;
        background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
        padding: 5px 20px 5px 20px;
      }
      .record-group-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 4px;
        // margin-left: 5px;
        margin-bottom: -30px;
        gap: 5px;
        .color-btn {
          color: #fff;
          background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
          padding: 5px 20px 5px 20px;
        }
        .save {
          width: 153px;
        }
        @media (max-width: 767px) {
          margin-top: 10px;
          margin-left: 0px;
        }
      }
      @media (max-width: 767px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: baseline;
        gap: 5px;
      }
    }
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;
export const SoapNoteImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 300px;
  padding: 5px;
  margin: 5px 0px 5px 0px;
  border: 1px solid black;
  align-items: center;
  .soap-image {
    height: 240px;
    width: 400px;
  }
`;

export const SoapNotesShowContainer = styled.div`
  border: 1px solid black;
  margin-top: 5px;
  .text-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 16px 4px 16px;
    align-items: center;
    .text-header-text {
      margin: 0px;
      // color: #5B5B5B;
      border: none;
    }
  }
  .text-line {
    margin: 0px;
  }
  .text-btn-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    .text-btn-top {
      border: 1px solid black;
      padding: 5px;
    }
  }
  .text-notes-text {
    display: flex;
    flex-direction: column;
    justify-content: left;
    height: 370px;
    // overflow-y: auto;
    .Notes-soap {
      height: 369px;
      width: 100%;
      padding: 10px;
    }
    &::-webkit-scrollbar {
      background-color: #f1f1f1;
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
  }
`;

export const PrintContainer = styled.div`
  display: none;
  padding: 80px;
  white-space: pre-wrap; /* Preserve whitespace and line breaks */
  // margin-top: 200px;
  font-family: monospace;

  @media print {
    display: block !important;
  }
`;
export const PrintSoapContainer = styled.div`
  display: none;
  padding: 80px;
  @media print {
    display: block !important;
  }
`;
export const Mybtn = styled.div`
  .new-style-btn {
    color: #fff;
    background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    padding: 5px 20px 5px 20px;
    border: none;
  }
`;
export const Themebutton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .new-style-btn {
    color: #fff;
    background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    padding: 5px 20px 5px 20px;
    border: none;
  }
`;

export const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  height: 350px;
  overflow-y: auto;
  padding: 10px;
  &::-webkit-scrollbar {
    background-color: #f1f1f1;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
`;

export const ModelContainers = styled.div`
  .message-line {
    background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 26px;
    text-align: center;
  }
`;

export const Printdata = styled.div`
  font-family: Arial, sans-serif;
  margin: 20px;
  height: 600px;
  .container {
    width: 80%;
    margin: 0 auto;

    .header {
      text-align: center;
    }
    .details,
    .diagnosis {
      margin-top: 20px;
      border-collapse: collapse;
      width: 100%;
    }
    .details td,
    .diagnosis td {
      padding: 8px;
    }
    .diagnosis-section {
      margin-top: 20px;
      border: 1px solid #000;
      padding: 10px;
    }
  }
`;
