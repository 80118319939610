import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

const InvoiceHeader = ({ hospitalDetails, logoUrl, invoiceDetails }) => {
  return (
    <Container className="border-bottom pb-3 mb-4">
      <Row className="align-items-center">
        <Col xs={3}>
          <Image src={logoUrl} alt="Hospital Logo" fluid />
        </Col>
        <Col style={{textAlign:"center"}} xs={6}>
          <h3 className="mb-1">{hospitalDetails.name}</h3>
          <p className="text-muted mb-1">{hospitalDetails.association}</p>
          <p className="text-muted mb-1">{hospitalDetails.address}</p>
          <p className="text-muted mb-1">P: {hospitalDetails.phone} | Email: {hospitalDetails.email}</p>
          <p className="text-muted">CIN:{hospitalDetails.cin} | GST: {hospitalDetails.gst}</p>
          <h1>CASH RECEIPT</h1>
        </Col>
        <Col style={{textAlign:"center"}} xs={3}>
 
        </Col>
      </Row>
      <hr
          style={{
            border: "1px solid black",
            margin: "0",
            marginBottom: "0px",
            marginTop: "0px",
            opacity: "0.5",
          }}
        />
      <Row className="mt-3">
        <Col xs={6}>
          <p>Bill No / Date:<strong> {invoiceDetails.billNo} / {invoiceDetails.date}</strong></p>
          <p>Registration No:<strong>{invoiceDetails.registrationNo}</strong></p>
        </Col>
        <Col xs={6}>
          <p>Doctor / Dept: <strong>{invoiceDetails.doctor} / {invoiceDetails.department}</strong></p>
          <p>Patient Type: <strong>{invoiceDetails.patientType}</strong></p>
        </Col>
      </Row>
    </Container>
  );
};

export default InvoiceHeader;
