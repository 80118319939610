import React, { useState } from "react";
import { Modal, Checkbox, Input, Radio } from "antd";
import "./table.css";
import axios from "axios";
import { configJson } from "../../Constent/config";
import Spinner from "react-bootstrap/Spinner";

const VitalsModal = ({
  vitalsModal,
  setVitalsModal,
  patientId,
  doctorId,
  fetchInteractions,
}) => {
  const [vitalsData, setVitalsData] = useState({
    height: "",
    weight: "",
    systolicBP: "",
    diastolicBP: "",
    pulse: "",
    oxygenSaturation: "",
    position: "",
    abnormalSystolic: false,
    abnormalDiastolic: false,
    abnormalPulse: false,
    abnormalOxygen: false,
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (key, value) => {
    setVitalsData((prev) => ({ ...prev, [key]: value }));
  };

  const handleCheckboxChange = (key, checked) => {
    setVitalsData((prev) => ({ ...prev, [key]: checked }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${configJson.backend_URL}/interaction/saveVitals`,
        {
          patient_id: patientId,
          // doctor_id: doctorId,
          interaction_date: new Date()
            .toISOString()
            .slice(0, 19)
            .replace("T", " "),
          vitalsData,
        }
      );
      fetchInteractions(patientId);
      setVitalsModal(false);
    } catch (error) {
      console.error("Error saving vitals:", error);
      setLoading(false);
      setVitalsModal(false);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      open={vitalsModal}
      footer={null}
      onCancel={() => setVitalsModal(false)}
      width={560}
    >
      <div className="w-full">
        <div>
          <div className="flex flex-col gap-2">
            <div className="text-xl font-semibold mb-2">Basic details</div>
            <div className="flex items-center gap-4  mb-2">
              <div className="flex items-center gap-4">
                <div>Height (cm)</div>
                <Input
                  type="number"
                  placeholder="Height in cm"
                  className="outline-none border p-1 rounded"
                  value={vitalsData.height}
                  min={0}
                  max={200}
                  onChange={(e) => handleInputChange("height", e.target.value)}
                />
              </div>
              <div className="flex items-center gap-4">
                <div>Weight (kg)</div>
                <Input
                  type="number"
                  placeholder="Weight in kg"
                  className="outline-none border p-1 rounded"
                  value={vitalsData.weight}
                  min={0}
                  max={200}
                  onChange={(e) => handleInputChange("weight", e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-xl font-semibold">Vitals</div>
            <div className="border-black border rounded p-2 flex flex-col gap-2 ">
              <div className="font-semibold ">Blood Pressure</div>
              <div className="flex items-center gap-2 justify-between">
                <div className="w-fit">Systolic Blood Pressure (mmHg)</div>
                <Input
                  type="number"
                  className={`outline-none border-2 p-1 rounded w-fit`}
                  value={vitalsData.systolicBP}
                  min={0}
                  onChange={(e) =>
                    handleInputChange("systolicBP", e.target.value)
                  }
                  style={{
                    borderColor:
                      vitalsData.systolicBP > 140 ? "red" : "transparent",
                  }}
                />
                <div className="w-[100px]">(100 - 140)</div>
                <Checkbox
                  checked={vitalsData.abnormalSystolic}
                  onChange={(e) =>
                    handleCheckboxChange("abnormalSystolic", e.target.checked)
                  }
                  className=""
                >
                  Abnormal
                </Checkbox>
              </div>
              <div className="flex items-center gap-2 justify-between">
                <div className="w-fit">Diastolic Blood Pressure (mmHg)</div>
                <Input
                  type="number"
                  className="outline-none border-2 p-1 rounded w-fit"
                  value={vitalsData.diastolicBP}
                  min={0}
                  onChange={(e) =>
                    handleInputChange("diastolicBP", e.target.value)
                  }
                  style={{
                    borderColor:
                      vitalsData.diastolicBP > 90 ? "red" : "transparent",
                  }}
                />
                <div className="w-[100px]">(60 - 90)</div>
                <Checkbox
                  checked={vitalsData.abnormalDiastolic}
                  onChange={(e) =>
                    handleCheckboxChange("abnormalDiastolic", e.target.checked)
                  }
                  className=""
                >
                  Abnormal
                </Checkbox>
              </div>
              <div className="flex items-center justify-between">
                <div className="font-semibold ">Body Position</div>
              </div>
              <Radio.Group
                value={vitalsData.position}
                onChange={(e) => handleInputChange("position", e.target.value)}
              >
                <Radio.Button value="Sitting">Sitting</Radio.Button>
                <Radio.Button value="Recumbent">Recumbent</Radio.Button>
                <Radio.Button value="Unknown">Unknown</Radio.Button>
                <Radio.Button value="Other">Other</Radio.Button>
                <Radio.Button value="Standing">Standing</Radio.Button>
                <Radio.Button value="Fowler">Fowler</Radio.Button>
              </Radio.Group>
            </div>
            <div className="flex items-center gap-2 justify-between flex-wrap ">
              <div className="w-fit">Pulse (beats/min)</div>
              <Input
                type="number"
                className="outline-none border-2 p-1 rounded w-fit "
                value={vitalsData.pulse}
                min={0}
                onChange={(e) => handleInputChange("pulse", e.target.value)}
                style={{
                  borderColor: vitalsData.pulse > 100 ? "red" : "transparent",
                }}
              />
              <div className="">(60 - 100)</div>
              <Checkbox
                checked={vitalsData.abnormalPulse}
                onChange={(e) =>
                  handleCheckboxChange("abnormalPulse", e.target.checked)
                }
                className=""
              >
                Abnormal
              </Checkbox>
            </div>
            <div className="flex items-center gap-2 justify-between ">
              <div className="w-fit">
                Arterial Blood Oxygen Saturation (pulse oximeter) (%)
              </div>
              <Input
                type="number"
                className="outline-none border-2 p-1 rounded w-fit"
                minLength={0}
                value={vitalsData.oxygenSaturation}
                min={0}
                onChange={(e) =>
                  handleInputChange("oxygenSaturation", e.target.value)
                }
                style={{
                  borderColor:
                    vitalsData.oxygenSaturation > 95 ? "red" : "transparent",
                }}
              />
              <div className="">(&gt;95)</div>
              <Checkbox
                checked={vitalsData.abnormalOxygen}
                className=""
                onChange={(e) =>
                  handleCheckboxChange("abnormalOxygen", e.target.checked)
                }
              >
                Abnormal
              </Checkbox>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end gap-2">
          <button
            className="rounded-3xl text-black border border-black py-2 px-3 w-32 mt-4"
            onClick={() => setVitalsModal(false)}
          >
            Cancel
          </button>
          <button
            className="rounded-3xl text-white py-2 px-3 w-32 mt-4"
            style={{
              background: "linear-gradient(to right, #1C1B1B, #5B5B5B)",
            }}
            onClick={handleSubmit}
            disabled={vitalsData.height === "" || vitalsData.weight === ""}
          >
            {loading ? <Spinner animation="border" size="sm" /> : "Save"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default VitalsModal;
