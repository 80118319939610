import React, { useState } from "react";
import { TokenPageContainer } from "../../Doctor-Page/Doctorstyle";
import Patients from "../../Interaction-Detail-Page/patients";
import { useLocation } from "react-router-dom";
import Sidebar from "../../Home/Components/Sidebar/Sidebar";
// import ProfileCard from "./RecordingActivity";


const AdminActivity = () => {
  const [content, setContent] = useState("patients");
  const [isContent, setIsContent] = useState(false);
  const { state }=useLocation();
  // const getpatrient=(data)=>{
  //   setContent(data);
  //   setIsContent(false)
  // }
  
  return (
    // <Sidebar setuser={true} isContent={isContent}>
      <TokenPageContainer>
        {!isContent && (
          <div className="flex items-center justify-center w-full m-auto sticky top-0 z-50 bg-white">
            <div
              className={`m-auto !w-1/2 cursor-pointer border-b-2  ${
                content === "patients" ? "!border-[#ACACAC]" : ""
              }`}
              style={{ borderColor: "transparent" }}
              onClick={() => setContent("patients")}
            >
              <img src={`/patient.png`} className="m-auto" width={32} />
            </div>
            <div
              className={`m-auto !w-1/2 cursor-pointer border-b-2  ${
                content === "appointment" ? "!border-[#ACACAC]" : ""
              }`}
              style={{ borderColor: "transparent" }}
              // onClick={() => setContent("appointment")}
            >
              <img src={`/appointment.png`} className="m-auto" width={32} />
            </div>
          </div>
          //   <div className="grid grid-cols-2 border-b gap-4">
          //     <div className="border-b-4 p-2 pt-0 border-gray-400">
          //       <img
          //         src={`/patient.png`}
          //         alt=""
          //         width={25}
          //         className="m-auto"
          //         onClick={() => setContent("patients")}
          //       />
          //     </div>
          //     {/* <div className="border-transparent border-b-4 p-2 pt-0 hover:border-gray-400 transition-all duration-300 cursor-pointer">
          //             <img
          //             src={`/surgery-room.png`}
          //             alt=""
          //             width={25}
          //             className="m-auto"
          //             onClick={() => setContent('surgery')}
          //             />
          //         </div> */}
          //     <div className="border-transparent border-b-4 p-2 pt-0 hover:border-gray-400 transition-all duration-300 cursor-pointer">
          //       <img
          //         src={`/appointment.png`}
          //         alt=""
          //         width={25}
          //         className="m-auto"
          //         onClick={() => setContent("appointments")}
          //       />
          //     </div>
          //   </div>
        )}
        {content === "patients" ? (
          <div className="h-[85vh] overflow-auto box p-4 py-0">
            <Patients setIsContent={setIsContent} doctorDetails={state}/>
          </div>
        ) : (
          // <div className="h-[75vh] overflow-auto box p-4 py-0">
          //  <ProfileCard setContent={getpatrient}/>
          // </div>
          ""
        )}
      </TokenPageContainer>
    // </Sidebar>
  );
};

export default AdminActivity;
