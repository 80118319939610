import React from "react";
import { MedicationTable } from "./MedicationTable";


function Medication({ tabledata, medicationtableshow }) {
  return (
    <div className="medication-container">
      {/* <div className="medication-from">
          <MedicationForm />
        </div> */}
      <div className="medication-table">
        <MedicationTable
          tabledata={tabledata}
          medicationtableshow={medicationtableshow}
        />
      </div>
    </div>
  );
}

export default Medication;
