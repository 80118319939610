import React, { useState, useRef } from "react";
import { Drawer, Carousel } from "antd";
import {
  FaMagnifyingGlassPlus,
  FaMagnifyingGlassMinus,
  FaExpand,
} from "react-icons/fa6";

const isMobile = () => window.innerWidth <= 768;

const ImgCarousel = ({
  carouselModal,
  setCarouselModal,
  images,
  setImages,
}) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [activeSlide, setActiveSlide] = useState(0);
  const imgRef = useRef(null);

  const handleZoomIn = () => setZoomLevel((prev) => Math.min(prev + 0.2, 3));
  const handleZoomOut = () => setZoomLevel((prev) => Math.max(prev - 0.2, 1));

  const handleAfterChange = (currentSlide) => {
    setActiveSlide(currentSlide);
    setZoomLevel(1); // Reset zoom for the new slide
  };

  const openFullScreen = () => {
    const imgElement = imgRef.current;
    if (imgElement) {
      let requestFullscreen;
      // Loop through fullscreen methods, returning if successful
      for (const method of ["requestFullscreen", "mozRequestFullScreen", "webkitRequestFullscreen", "msRequestFullscreen"]) {
        if (method in imgElement && !isMobile()) { // Check for mobile inside the loop
          requestFullscreen = method;
          break;
        }
      }
  
      if (requestFullscreen) {
        imgElement[requestFullscreen]();
      } else {
        console.error("Fullscreen API is not supported by this browser.");
        // Add a fallback here (optional)
        // For example, open the image in a new window:
        const currentImage = images[activeSlide];
        window.open(currentImage, "_blank");
      }
    }
  };

  return (
    <Drawer
      placement="right"
      size="large"
      open={carouselModal}
      className="img-carousel"
      onClose={() => {
        setCarouselModal(false);
        setImages([]);
        setZoomLevel(1);
      }}
      extra={
        <div className="flex items-center gap-3">
          <button
            className="text-xl"
            onClick={handleZoomIn}
            disabled={zoomLevel >= 1.5}
          >
            <FaMagnifyingGlassPlus />
          </button>
          <button
            className="text-xl"
            onClick={handleZoomOut}
            disabled={zoomLevel <= 1}
          >
            <FaMagnifyingGlassMinus />
          </button>
          <button
            className="text-xl"
            onClick={openFullScreen}
          >
            <FaExpand />
          </button>
        </div>
      }
      style={{ backgroundColor: "black", color: "white" }}
    >
      <Carousel
        autoplay={false}
        arrows
        infinite={false}
        dots={false}
        afterChange={handleAfterChange}
      >
        {images.map((img, index) => (
          <div
            key={index}
            className="!flex !items-center !justify-center !h-[80vh]"
          >
            <img
              src={img}
              alt={`Image ${index + 1}`}
              style={{
                transform: `scale(${index === activeSlide ? zoomLevel : 1})`,
                transition: "transform 0.3s ease",
                maxHeight: "100%",
                maxWidth: "100%",
              }}
              ref={index === activeSlide ? imgRef : null}
            />
          </div>
        ))}
      </Carousel>
    </Drawer>
  );
};

export default ImgCarousel;
