export const configJson = {};

// configJson.backend_URL = "http://localhost:5000";
// configJson.CHATBOT_URL = "https://medscribe-server-sand.medscribe.in";

// //production

configJson.backend_URL = "";
configJson.CHATBOT_URL = "https://medscribe-server.medscribe.in";

// // DEV

// configJson.backend_URL = "";
// configJson.CHATBOT_URL = "https://medscribe-server-sand.medscribe.in";
