import React from "react";

const PatientInfo = ({ patient, billInfo }) => {
  const rowStyle = {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px", // Spacing between rows
    fontSize: "16px",
  };

  const labelStyle = {
    width: "12%",
    textAlign: "left",
    paddingRight: "10px",
  };

  const colonStyle = {
    width: "2%",
    textAlign: "center",
  };

  const valueStyle = {
    width: "86%",
    textAlign: "left",
  };

  return (
    <div className="mb-4">
      {/* Patient Name */}
      <div style={rowStyle}>
        <div style={labelStyle}>Patient Name</div>
        <div style={colonStyle}>:</div>
        <div style={valueStyle}>
          <strong>
            {patient.name}, {patient.age} Y / {patient.gender}
          </strong>
        </div>
      </div>

      {/* Address */}
      <div style={rowStyle}>
        <div style={labelStyle}>Address</div>
        <div style={colonStyle}>:</div>
        <div style={valueStyle}>
          <strong>{patient.address}</strong>
        </div>
      </div>

      {/* Validity */}
      <div style={rowStyle}>
        <div style={labelStyle}>Validity</div>
        <div style={colonStyle}>:</div>
        <div style={valueStyle}>
          <strong>{billInfo.validity}</strong>
        </div>
      </div>
    </div>
  );
};

export default PatientInfo;
