import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Spinner,
  Container,
  Alert,
  Pagination,
  Form,
} from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import { configJson } from "../../Constent/config";
import userimage from "../../assets/user/user51.png";
import editicon from "../../assets/table/editiconadmin.png";
import AddDoctorModal from "./AddDoctorModal";
import AddSpecModel from "./AddSpecModel";
import EditUserModal from "./EditUserModel";
import "../Doctor-Page/DoctorsTable.css";
import { TabStyleComp } from "./AdminPageStyle";
import { useNavigate } from "react-router-dom";
import { FcViewDetails } from "react-icons/fc";

const UserList = () => {
  const [doctors, setDoctors] = useState([]);
  const [Org_id, setOrgID] = useState();
  const [frontOffice, setFrontOffice] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [orgspecialties, setOrgSpecialties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showAddDoctorModal, setShowAddDoctorModal] = useState(false);
  const [showAddSpecModal, setShowAddSpecModal] = useState(false);
  const [showEditDoctorModal, setShowEditDoctorModal] = useState(false);
  const [showEditSpecModal, setShowEditSpecModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedSpec, setSelectedSpec] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [selectedRole, setSelectedRole] = useState("Doctor");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0); // New state for refreshing component
  const navigate = useNavigate();

  const fetchSpecialties = async () => {
    try {
      const response = await fetch(`${configJson.backend_URL}/specialties`);
      if (!response.ok) throw new Error("Failed to fetch specialties data");
      const data = await response.json();
      setSpecialties(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchDoctors = async (organization_id) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${configJson.backend_URL}/doctor/${organization_id}`,
        { method: "GET", headers: { "Content-Type": "application/json" } }
      );
      if (!response.ok) throw new Error("Failed to fetch Doctor data");
      const data = await response.json();

      const doctorsWithSpecialties = data.map((doctor) => {
        const specialty = specialties.find(
          (spec) => spec.speciality_id === doctor.speciality_id
        );
        return {
          ...doctor,
          specialty_name: specialty ? specialty.specialty_name : "N/A",
        };
      });
      setDoctors(doctorsWithSpecialties);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUsersByRole = async (organization_id, role, setState) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${configJson.backend_URL}/user/${organization_id}?role=${role}`,
        { method: "GET", headers: { "Content-Type": "application/json" } }
      );
      if (!response.ok) throw new Error(`Failed to fetch ${role} data`);
      const data = await response.json();
      setState(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchOrgSpecialties = async (organization_id) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        //  `${configJson.backend_URL}/specialtysetup?organization_id=${organization_id}`,
        `${configJson.backend_URL}/specialtysetup/test/${organization_id}`,

        { method: "GET", headers: { "Content-Type": "application/json" } }
      );
      if (!response.ok) throw new Error(`Failed to fetch data`);
      const data = await response.json();
      setOrgSpecialties(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchSpecialties();
  }, []);

  useEffect(() => {
    if (specialties.length > 0) {
      const token = sessionStorage.getItem("orgtoken");
      if (token) {
        try {
          const decoded = jwtDecode(token);
          const organizationId = decoded.organization_id;
          fetchDoctors(organizationId);
          fetchUsersByRole(
            organizationId,
            "Front Office Staff",
            setFrontOffice
          );
          fetchUsersByRole(organizationId, "Technician", setTechnicians);

          fetchOrgSpecialties(organizationId);
        } catch (error) {
          setError("Invalid token. Please login again.");
        }
      } else {
        setError("No token found. Please login again.");
      }
    }
  }, [specialties, refreshKey]); // Add `refreshKey` to re-trigger on change

  const handleEditUserModalClose = () => {
    setShowEditDoctorModal(false);
  };
  const handleEditSpecModalClose = () => {
    setShowEditSpecModal(false);
  };

  const handleEditUserCloseSubmission = () => {
    setShowEditDoctorModal(false);
    setSelectedUser({});
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      const organizationId = decoded.organization_id;

      if (selectedRole === "Doctor") {
        fetchDoctors(organizationId);
      } else if (selectedRole === "Speciality") {
        fetchOrgSpecialties(organizationId);
      } else {
        fetchUsersByRole(organizationId, selectedRole, getSelectedRoleSetter());
      }
    }
  };
  const handleEditSpecCloseSubmission = () => {
    setShowEditSpecModal(false);
    setSelectedSpec({});
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      const organizationId = decoded.organization_id;
      setOrgID(organizationId);
      if (selectedRole === "Speciality") {
        fetchOrgSpecialties(organizationId);
      }
    }
  };

  const handleAddSpecModalClose = () => {
    setShowAddSpecModal(false);
  };
  const handleAddDoctorModalClose = () => {
    setShowAddDoctorModal(false);
  };

  const handleFetchClose = (newUserData, role) => {
    setShowAddDoctorModal(false);
    console.log(role);
    if (newUserData && role) {
      // Determine the role and update the relevant state
      if (role === "doctor") {
        setDoctors((prevDoctors) => [...prevDoctors, newUserData]);
        setSelectedRole("Doctor"); // Correct role assignment for Doctor
      } else if (role === "front office staff") {
        setFrontOffice((prevFrontOffice) => [...prevFrontOffice, newUserData]);
        setSelectedRole("Front Office Staff"); // Correct role assignment for Front Office
      } else if (role === "technician") {
        setTechnicians((prevTechnicians) => [...prevTechnicians, newUserData]);
        setSelectedRole("Technician"); // Correct role assignment for Technician
      } else if (role === "speciality") {
        // setTechnicians((prevTechnicians) => [...prevTechnicians, newUserData]);
        setSelectedRole("Speciality"); // Correct role assignment for Technician
      }
      setCurrentPage(1); // Reset to the first page to show the newly added user
    }

    // Trigger a full component refresh
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const handleEditUserClick = (user) => {
    setSelectedUser(user);
    setShowEditDoctorModal(true);
  };
  console.log(showAddSpecModal);

  const handleEditSpecClick = (speciality) => {
    setSelectedSpec(speciality);
    setShowEditDoctorModal(true);
  };

  const setEditedData = (updatedData) => {
    const { role } = updatedData;
    if (role === "Doctor") {
      setDoctors((prev) =>
        prev.map((user) =>
          user.user_id === updatedData.user_id ? updatedData : user
        )
      );
    } else if (role === "Front Office Staff") {
      setFrontOffice((prev) =>
        prev.map((user) =>
          user.user_id === updatedData.user_id ? updatedData : user
        )
      );
    } else if (role === "Technician") {
      setTechnicians((prev) =>
        prev.map((user) =>
          user.user_id === updatedData.user_id ? updatedData : user
        )
      );
    } else if (role === "Speciality") {
      setOrgSpecialties((prev) =>
        prev.map((user) =>
          user.speciality_id === orgspecialties.speciality_id
            ? orgspecialties
            : user
        )
      );
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getPaginatedData = (data) => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return data.slice(indexOfFirstItem, indexOfLastItem);
  };
  //navigate
  const Visitpage = (data) => {
    navigate("/admin-activity", { state: { data } });
  };

  if (isLoading) {
    return (
      <Container className="text-center my-5">
        <Spinner animation="border" role="status" />
        <p>Loading Members...</p>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="text-center my-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  const handleToggle = async (organization_id, speciality_id, isActive) => {
    try {
      console.log(organization_id, speciality_id, isActive);
      const response = await fetch(
        `${configJson.backend_URL}/specialtysetup/edit`,
        {
          method: "PUT", // Use PUT or POST based on your backend's expectations
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            organization_id,
            speciality_id,
            is_active: isActive ? 1 : 0, // Convert boolean to numeric format if needed
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to update data");

      const data = await response.json();
      setOrgSpecialties((prev) =>
        prev.map((item) =>
          item.speciality_id === speciality_id
            ? { ...item, is_active: isActive ? 1 : 0 }
            : item
        )
      );
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const renderSpecialityTable = (speciality) => (
    <div className="table-responsive">
      <Table striped bordered hover className="text-center">
        <thead>
          <tr>
            <th>Speciality Name</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          {console.log(orgspecialties)}
          {orgspecialties.map((item) => (
            <tr key={item.speciality_id}>
              <td>{item.speciality_name}</td>
              <td>
                <Form.Check
                  type="switch"
                  id={`is-active-${item.speciality_id}`}
                  checked={item.is_active === 1}
                  onChange={(e) =>
                    handleToggle(
                      item.organization_id,
                      item.speciality_id,
                      e.target.checked
                    )
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination className="justify-content-center mt-3">
        {[...Array(Math.ceil(orgspecialties.length / itemsPerPage))].map(
          (_, index) => (
            <Pagination.Item
              key={index}
              active={currentPage === index + 1}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          )
        )}
      </Pagination>
    </div>
  );
  const renderUserTable = (users) => (
    <div className="table-responsive">
      <Table striped bordered hover responsive="md" className="text-center">
        <thead>
          <tr>
            <th>Profile</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            {selectedRole === "Doctor" && <th>Speciality</th>}
            <th>Edit</th>
            {selectedRole === "Doctor" && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {getPaginatedData(users)
            .filter((user) =>
              user.first_name?.toLowerCase().includes(searchValue.toLowerCase())
            )
            .map((user, index) => (
              <tr key={index}>
                <td>
                  <img
                    style={{ textAlign: "center", margin: "0 auto" }}
                    src={userimage}
                    alt="profile"
                    width="30"
                    height="30"
                    className="rounded-circle"
                  />
                </td>
                <td>{user.first_name || "N/A"}</td>
                <td>{user.last_name || "N/A"}</td>
                <td>{user.email || "N/A"}</td>
                {selectedRole === "Doctor" && (
                  <td>{user.specialty_name || "N/A"}</td>
                )}
                <td>
                  <Button
                    className="editbtnres"
                    style={{ background: "none", border: "none" }}
                    onClick={() => handleEditUserClick(user)}
                  >
                    <img src={editicon} alt="Edit" />
                  </Button>
                </td>
                {selectedRole === "Doctor" && (
                  <td>
                    <Button
                      className="editbtnres"
                      style={{ background: "none", border: "none" }}
                      onClick={() => Visitpage(user)}
                    >
                      <FcViewDetails size={25} />
                    </Button>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </Table>

      <Pagination className="justify-content-center mt-3">
        {[...Array(Math.ceil(users.length / itemsPerPage))].map((_, index) => (
          <Pagination.Item
            key={index}
            active={currentPage === index + 1}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </div>
  );

  const getSelectedRoleSetter = () => {
    switch (selectedRole) {
      case "Doctor":
        return setDoctors;
      case "Front Office Staff":
        return setFrontOffice;
      case "Technician":
        return setTechnicians;
      case "Speciality":
        return setSpecialties;
      default:
        return () => {}; // No-op if no role matches
    }
  };

  const getSelectedRoleData = () => {
    switch (selectedRole) {
      case "Doctor":
        return doctors;
      case "Front Office Staff":
        return frontOffice;
      case "Technician":
        return technicians;
      case "Speciality":
        return specialties;
      default:
        return [];
    }
  };

  return (
    <Container style={{ minHeight: "400px" }} key={refreshKey}>
      {" "}
      {/* key added for re-render */}
      {showEditDoctorModal && (
        <EditUserModal
          show={showEditDoctorModal}
          handleCloseUser={handleEditUserModalClose}
          handleCloseEdit={handleEditUserCloseSubmission}
          token={sessionStorage.getItem("orgtoken")}
          userData={selectedUser}
          setEditedData={setEditedData}
          setErrorMessage={setError}
        />
      )}
      {/* <EditSpecModel
 show={showEditSpecModal}
 handleCloseUser={handleEditSpecModalClose}
 handleCloseEdit={handleEditSpecCloseSubmission}
 token={sessionStorage.getItem("orgtoken")}
 userData={selectedSpec}
 setEditedData={setEditedData}
 setErrorMessage={setError}
 /> */}
      {showAddSpecModal && (
        <AddSpecModel
          show={showAddSpecModal}
          handleClose={handleAddSpecModalClose}
          handleFetch={handleFetchClose}
          token={sessionStorage.getItem("orgtoken")}
          setShouldRefresh={setShouldRefresh}
        />
      )}
      {showAddDoctorModal && (
        <AddDoctorModal
          show={showAddDoctorModal}
          handleClose={handleAddDoctorModalClose}
          handleFetch={handleFetchClose}
          token={sessionStorage.getItem("orgtoken")}
          setShouldRefresh={setShouldRefresh}
        />
      )}
      <TabStyleComp>
        <div className="d-flex justify-content-between align-items-center my-4 tabup">
          <Form.Group className="me-3 w-25">
            <Form.Label>Select Users By Role</Form.Label>
            <Form.Select
              value={selectedRole}
              onChange={(e) => {
                setSelectedRole(e.target.value);
                setCurrentPage(1);
              }}
              className="form-select"
            >
              <option value="Doctor">Doctor</option>
              <option value="Front Office Staff">Front Office Staff</option>
              <option value="Technician">Technician</option>
              <option value="Speciality">Speciality</option>
            </Form.Select>
          </Form.Group>
          <div className="searchbaradd d-flex justify-content-between align-items-center">
            <input
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              className="form-control"
              placeholder="Search here"
            />
            {selectedRole !== "Speciality" ? (
              <Button
                className="addmemberbtn"
                onClick={() => setShowAddDoctorModal(true)}
              >
                Add Member
              </Button>
            ) : (
              <Button
                className="addmemberbtn"
                onClick={() => setShowAddSpecModal(true)}
              >
                Add Speciality
              </Button>
            )}
          </div>
        </div>
        {selectedRole !== "Speciality" ? (
          <>{renderUserTable(getSelectedRoleData())}</>
        ) : (
          <>{renderSpecialityTable(getSelectedRoleData())}</>
        )}
      </TabStyleComp>
    </Container>
  );
};

export default UserList;
