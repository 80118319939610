import React, { useState, useEffect, useContext } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { UserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import { configJson } from "../../Constent/config";

const BillingInfoDoctor = () => {
  const [doctors, setDoctors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [billingFee, setBillingFee] = useState("");
  const [billingDescription, setBillingDescription] = useState("");
  const [token] = useContext(UserContext);
  const navigate = useNavigate();

  // const fetchDoctorsWithBilling = async (organization_id) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await fetch(
  //       `${configJson.backend_URL}/doctor/${organization_id}`,
  //       {
  //         method: "GET",
  //         headers: { "Content-Type": "application/json" },
  //       }
  //     );

  //     if (!response.ok) throw new Error("Failed to fetch doctors");

  //     const doctors = await response.json();
  //     const doctorsWithBilling = await Promise.all(
  //       doctors.map(async (doctor) => {
  //         const billingResponse = await fetch(
  //           `${configJson.backend_URL}/billing/${doctor.doctor_id}`,
  //           {
  //             method: "GET",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${token}`,
  //             },
  //           }
  //         );

  //         if (billingResponse.ok) {
  //           const billing = await billingResponse.json();
  //           return { ...doctor, billing };
  //         }
  //         return { ...doctor, billing: null };
  //       })
  //     );

  //     setDoctors(doctorsWithBilling);
  //   } catch (err) {
  //     console.error(err);
  //     setError(err.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleAddBillingClick = async (doctor) => {
    setSelectedDoctor(doctor);
    setBillingFee(doctor.billing?.fee || "");
    setBillingDescription(doctor.billing?.description || "");
    setShowBillingModal(true);
  };

  const handleBillingSave = async () => {
    try {
      // Check if the client_id exists in the selectedDoctor object
      let clientId = selectedDoctor.client_id;
  
      // If client_id doesn't exist, create a new client first
      if (!clientId) {
        const createdClient = await createClient(selectedDoctor);  // Create the client and get the client_id
        clientId = createdClient.client_id;  // Set the client_id from the newly created client
      }
  
      // Once you have the client_id, proceed to create an invoice
      const billingFee = parseFloat(billingFee);  // Ensure the fee is a number
      const billingDescription = billingDescription;  // Use the description provided
  
      const invoiceData = await createInvoice(clientId, billingFee, billingDescription);
  
      // Handle the response, you can show the invoice data to the user or perform additional actions
      console.log('Invoice created successfully:', invoiceData);
      setShowBillingModal(false);  // Close the modal after saving
    } catch (error) {
      console.error('Error creating invoice:', error);
    }
  };
  
  

  // const saveBillingDetails = async (clientId) => {
  //   try {
  //     // Prepare the billing details for the new client
  //     const billingDetails = {
  //       billing_address1: "123 Main Street",
  //       billing_city: "New York",
  //       billing_postal_code: "10001",
  //       billing_country_id: "US",
  //       // Additional billing details
  //       fee: billingFee,  // Use the billing fee from the form
  //       description: billingDescription,  // Use the description from the form
  //     };
  
  //     // Update the client's billing info in Invoice Ninja
  //     const response = await fetch(`https://invoice.medscribe.in/api/v1/clients/${clientId}`, {
  //       method: 'PUT',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'X-Api-Token': 'z3VjplPVEKQzDvCAAjYvHSpneJRUJGEm4watX53N5WHf7tmoPkvOsxhqikfkVLfK', // Replace with actual API token
  //       },
  //       body: JSON.stringify({
  //         client: billingDetails,
  //       }),
  //     });
  
  //     if (!response.ok) {
  //       const errorData = await response.json();
  //       console.error('Error updating client billing info:', errorData);
  //       throw new Error(errorData.message || 'Unknown error');
  //     }
  
  //     const data = await response.json();
  //     console.log('Billing info updated for client:', data);
  //     setShowBillingModal(false);  // Close modal after saving billing info
  
  //   } catch (error) {
  //     console.error('Error saving billing details:', error.message || error);
  //   }
  // };
  
  const createInvoice = async (clientId, billingFee, billingDescription) => {
    const response = await fetch('https://invoice.medscribe.in/api/v1/invoices', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Token': 'z3VjplPVEKQzDvCAAjYvHSpneJRUJGEm4watX53N5WHf7tmoPkvOsxhqikfkVLfK', // Replace with your Invoice Ninja API token
      },
      body: JSON.stringify({
        client_id: clientId,  // The client_id of the client for which you are creating the invoice
        status: 'draft',  // Set the status of the invoice (draft, sent, etc.)
        line_items: [
          {
            product_key: 'consultation_fee',  // Product key for the line item
            quantity: 1,
            unit_cost: billingFee,  // The fee for this service
            notes: billingDescription,  // Any notes about the line item
          },
        ],
        invoice_date: new Date().toISOString().split('T')[0],  // The current date
        due_date: '2024-12-31',  // The due date for the invoice (you can adjust this)
      }),
    });
  
    if (response.ok) {
      const data = await response.json();
      console.log('Invoice created successfully:', data);
      return data.data;  // Return invoice data for reference
    } else {
      const error = await response.json();
      console.error('Error creating invoice:', error);
    }
  };

  const createClient = async (doctor) => {
    try {
      const response = await fetch('https://invoice.medscribe.in/api/v1/clients', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Token': 'z3VjplPVEKQzDvCAAjYvHSpneJRUJGEm4watX53N5WHf7tmoPkvOsxhqikfkVLfK', // Replace with your Invoice Ninja API token
        },
        body: JSON.stringify({
          first_name: doctor.first_name,
          last_name: doctor.last_name,
          email: doctor.email,
          phone: doctor.phone_number,  // If available
          address: doctor.address,     // If available
          city: doctor.city,           // If available
          postal_code: doctor.postal_code, // If available
          country_id: doctor.country_id || 'US', // Example country code
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Client Created:', data);
        return data.data;  // Return the client data including client_id
      } else {
        const error = await response.json();
        console.error('Error creating client:', error);
        throw new Error(error.message || 'Client creation failed');
      }
    } catch (error) {
      console.error('Error creating client:', error);
      throw error;  // Rethrow the error to be handled in the calling function
    }
  };
  
  

  useEffect(() => {
    const token1 = sessionStorage.getItem("orgtoken");
    if (token1) {
      const decoded = jwtDecode(token1);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  if (isLoading)
    return (
      <Container className="text-center my-5">
        <Spinner animation="border" role="status">
          <span className="sr-only"></span>
        </Spinner>
        <p>Loading Doctors...</p>
      </Container>
    );

  if (error)
    return (
      <Container className="text-center my-5">
        <p className="text-danger">Error: {error}</p>
      </Container>
    );

  return (
    <div>
      <Container>
        <Table striped bordered hover>
          <thead>
            <tr>
              {/* <th>Doctor ID</th> */}
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Specialization</th>
              {/* <th>Phone Number</th> */}
              <th>Consultant Fee (₹)</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {doctors.map((doctor) => (
              <tr key={doctor.doctor_id}>
                {/* <td>{doctor.doctor_id}</td> */}
                <td>{doctor.first_name}</td>
                <td>{doctor.last_name}</td>
                <td>{doctor.email}</td>
                <td>{doctor.specialization || "N/A"}</td>
                {/* <td>{doctor.phone_number || "N/A"}</td> */}
                <td>{doctor.billing?.fee || "Not Set"}</td>
                <td>
                  <Button
                    variant="info"
                    onClick={() => handleAddBillingClick(doctor)}
                  >
                    Add/Edit Billing
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>

      <Modal show={showBillingModal} onHide={() => setShowBillingModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Add Billing Details for Dr. {selectedDoctor?.first_name}{" "}
            {selectedDoctor?.last_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Consultant Fee (₹)</Form.Label>
            <Form.Control
              type="number"
              value={billingFee}
              onChange={(e) => setBillingFee(e.target.value)}
              placeholder="Enter Consultant Fee"
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Billing Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={billingDescription}
              onChange={(e) => setBillingDescription(e.target.value)}
              placeholder="Enter billing description"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowBillingModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleBillingSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BillingInfoDoctor;
