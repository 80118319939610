import React, { useEffect, useState } from "react";
import {
  Modal,
  Dropdown,
  DropdownButton,
  Form,
  Button,
  Col,
  Row,
} from "react-bootstrap";
import axios from "axios";
import { configJson } from "../../../Constent/config";
import { useForm } from "react-hook-form";

const UpdateUserRole = ({
  user,
  show,
  handleClose,
  specialties,
  setUsers
}) => {
  console.log(user);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [message, setMessage] = useState("");
  const [doctorRole, setDoctorRole] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  //   const [roles, setRoles] = useState([]);
  const roles = ["admin", "doctor", "front office staff", "technician"];

  const handleRoleSelect = (role) => {
    if (selectedRoles.includes(role)) {
      // Remove role if already selected
      setSelectedRoles(selectedRoles.filter((r) => r !== role));
      if (role === "doctor") {
        setDoctorRole(false);
        setValue("specialty_id", "");
      }
    } else {
      // Add role if not selected
      setSelectedRoles([...selectedRoles, role]);
      if (role === "doctor") {
        setDoctorRole(true);
      }
    }

    // if (selectedRoles.includes("doctor")) {
    //   setDoctorRole(true)
    // } else {
    //   setDoctorRole(false)
    // }
  };

  useEffect(() => {
    const rolesToFilter = user?.role.split(",");
    setSelectedRoles(rolesToFilter);
    // const filteredRoles = role.filter((role) => !rolesToFilter.includes(role));
    //setRoles(filteredRoles);
  }, [user]);

  const onSubmit = async (data) => {
    console.log(data);
    if (data?.speciality_id === "") {
      console.log("data", data);
    }
    console.log(user, selectedRoles);
    try {
      const response = await axios.put(
        `${configJson.backend_URL}/user/update-role`,
        {
          user_id:user.user_id,
          roles: selectedRoles,
          specialty_id: data?.specialty_id === "" ? "" : data?.specialty_id || "",
          license_no:data.license_no || ""
        }
      );

      if (response.data.success) {
        setMessage("Roles updated successfully!");
        setUsers((prevUsers) =>
          prevUsers.map((users) =>
            users.user_id === user.user_id ? { ...users, role: selectedRoles.join(",") } : users
          )
        );
        handleClose();
      } else {
        setMessage("Failed to update roles.");
      }
    } catch (error) {
      setMessage("Error updating roles.");
      console.error(error);
    }
  };
  const handleSpecialityChange = (e) => {
    const selected = e.target.value;
    setValue("specialty_id", selected);
  };


  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Update User Roles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label>Select Roles</Form.Label>
            <DropdownButton id="dropdown-basic-button" title="Select Roles">
              {roles.map((role) => (
                <Dropdown.Item
                  key={role}
                  onClick={() => handleRoleSelect(role)}
                  active={selectedRoles.includes(role)}
                >
                  {selectedRoles.includes(role) ? "✓ " : ""} {role}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <Form.Text className="text-muted">
              Selected Roles: {selectedRoles.join(", ")}
            </Form.Text>
          </Form.Group>
          {message && <p className="text-success mt-2">{message}</p>}
          {doctorRole && (
            <>
              <Form.Group as={Row} controlId="formSpeciality">
                <Form.Label column sm={4}>
                  Specialty <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Col className="mb-3" sm={8}>
                  <Form.Select
                    {...register("specialty_id", {
                      required: "Specialty is required",
                    })}
                    onChange={handleSpecialityChange}
                  >
                    <option value="">Select Specialty</option>
                    {specialties.map((speciality) => (
                      <option
                        key={speciality.speciality_id}
                        value={speciality.speciality_id}
                      >
                        {speciality.specialty_name}
                      </option>
                    ))}
                  </Form.Select>
                  {errors.specialty_id && (
                    <Form.Text className="text-danger">
                      {errors.specialty_id.message}
                    </Form.Text>
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formLicenseNo">
                <Form.Label column sm={4}>
                  License Number <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Col className="mb-3" sm={8}>
                  <Form.Control
                    type="text"
                    placeholder="Enter license number"
                    {...register("license_no", {
                      required: "License number is required",
                    })}
                  />
                  {errors.license_no && (
                    <Form.Text className="text-danger">
                      {errors.license_no.message}
                    </Form.Text>
                  )}
                </Col>
              </Form.Group>
            </>
          )}
          <Button variant="success" type="submit">
            Update Roles
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default UpdateUserRole;

// import React, { useState } from "react";
// import { Dropdown, DropdownButton, Form, Button } from "react-bootstrap";
// import axios from "axios";
// import { configJson } from "../../../Constent/config";

// const UpdateUserRole = () => {
//   const [userId, setUserId] = useState("");
//   const [selectedRoles, setSelectedRoles] = useState([]);
//   const [message, setMessage] = useState("");

//   const roles = ["admin", "doctor", "front office staff", "technician"];

//   const handleRoleSelect = (role) => {
//     if (selectedRoles.includes(role)) {
//       // Remove role if already selected
//       setSelectedRoles(selectedRoles.filter((r) => r !== role));
//     } else {
//       // Add role if not selected
//       setSelectedRoles([...selectedRoles, role]);
//     }
//   };

//   const handleUpdateRole = async () => {
//     try {
//       const response = await axios.put(
//         `${configJson.backend_URL}/user/update-role`,
//         {
//           user_id:79,
//           roles: selectedRoles, // Send array of roles
//         }
//       );

//       if (response.data.success) {
//         setMessage("Roles updated successfully!");
//       } else {
//         setMessage("Failed to update roles.");
//       }
//     } catch (error) {
//       setMessage("Error updating roles.");
//       console.error(error);
//     }
//   };

//   return (
//     <div>
//       <h2>Update User Roles</h2>
//       <Form>

//         <Form.Group>
//           <Form.Label>Select Roles</Form.Label>
//           <DropdownButton id="dropdown-basic-button" title="Select Roles">
//             {roles.map((role) => (
//               <Dropdown.Item
//                 key={role}
//                 onClick={() => handleRoleSelect(role)}
//                 active={selectedRoles.includes(role)}
//               >
//                 {selectedRoles.includes(role) ? "✓ " : ""} {role}
//               </Dropdown.Item>
//             ))}
//           </DropdownButton>
//           <Form.Text className="text-muted">
//             Selected Roles: {selectedRoles.join(", ")}
//           </Form.Text>
//         </Form.Group>

//         <Button variant="primary" onClick={handleUpdateRole}>
//           Update Roles
//         </Button>
//       </Form>
//       {message && <p>{message}</p>}
//     </div>
//   );
// };

// export default UpdateUserRole;
