import React, { useEffect, useRef, useState } from "react";
import loginlog from "../../assets/home/loginlogo.png";
import signuplog from "../../assets/home/signuplogo.png";
import { useNavigate } from "react-router-dom";
import TopBar from "./Components/Topbar/TopBar";
import { HomeContainer } from "./HomeStyle";

const Home = () => {
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

//navigate Organization Signup
  // const OrganizationSignup = () => {
  //   navigate("/signup");
  // };
  //navigate Doctor Login
  const DoctorLogin = () => {
    navigate("/login");
  };
  // Function to update size
  const updateSize = () => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }
  };
  useEffect(() => {
    updateSize();

    // Add resize event listener
    window.addEventListener("resize", updateSize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);
  return (
    <>
      <HomeContainer ref={containerRef}>
        {/* <TopBar showUser={false} /> */}
        {containerWidth > 767 ? (
          <div className="top-btn">
            {/* <button
              className="line-button"
              type="button"
              onClick={OrganizationSignup}
            >
              <span className="line-btn-round">
                <img src={loginlog} title="login" alt="login" />
              </span>
              <p className="btn-text">Signup</p>
            </button> */}
            <button className="line-button" type="button" onClick={DoctorLogin}>
              <span className="line-btn-round">
                <img src={signuplog} title="signup" alt="signup" />
              </span>
              <p className="btn-text">Login</p>
            </button>
          </div>
        ) : (
          ""
        )}
        <div className="text-container">
          <div className="welcome">
            <h2 className="welcome-text">Welcome to Medscribe</h2>
          </div>
          <div className="text-part">
            <p className="text-part-p">
              Medscribe is your go-to app for converting speech to text summaries
              efficiently. Ideal for doctors and organizations looking to
              streamline their documentation process.
            </p>
          </div>
        </div>
        {containerWidth < 767 ? (
          <div className="top-btn">
            {/* <button
              className="line-button"
              type="button"
              onClick={OrganizationSignup}
            >
              <span className="line-btn-round">
                <img src={signuplog} title="signup" alt="signup" />
              </span>
              <p className="btn-text">Signup</p>
            </button> */}
            <button className="line-button" type="button" onClick={DoctorLogin}>
              <span className="line-btn-round">
                <img src={loginlog} title="login" alt="login" />
              </span>
              <p className="btn-text">Login</p>
            </button>
          </div>
        ) : (
          ""
        )}
      </HomeContainer>
    </>
  );
};

export default Home;
