import React from "react";
import { Table, Button } from "react-bootstrap";

const TemplateAfterUpdate = ({
  prescriptionDataTemplate,
  handleTemplateUpdateRow,
  handleTemplateDeleteRow,
  handleAddRowTemplate,
}) => {
  return (
    <Table bordered responsive className="medicinetable">
      <thead>
        <tr>
          <th
            style={{
              textAlign: "center",
              fontSize: "16px",
              fontFamily: "Calibri",
              padding: "5px",
              width: "40%", // Adjusted width for Medicine Name
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <span style={{ width: "100%", textAlign: "center" }}>
                Medicine Name
              </span>
            </div>
          </th>
          <th
            style={{
              width: "10%", // Reduced width for FN
              textAlign: "center",
              fontSize: "16px",
              fontFamily: "Calibri",
              padding: "5px",
            }}
          >
            FN
          </th>
          <th
            style={{
              width: "10%", // Reduced width for AN
              textAlign: "center",
              fontSize: "16px",
              fontFamily: "Calibri",
              padding: "5px",
            }}
          >
            AN
          </th>
          <th
            style={{
              width: "10%", // Reduced width for N
              textAlign: "center",
              fontSize: "16px",
              fontFamily: "Calibri",
              padding: "5px",
            }}
          >
            N
          </th>
          <th
            style={{
              width: "15%", // Adjusted width for Days
              textAlign: "center",
              fontSize: "16px",
              fontFamily: "Calibri",
              padding: "5px",
            }}
          >
            Days
          </th>
          <th
            className="printlastnonecol"
            style={{
              width: "40%", // Adjusted width for Instructions
              textAlign: "center",
              fontSize: "16px",
              fontFamily: "Calibri",
              padding: "5px",
            }}
          >
            Instructions
          </th>
        </tr>
      </thead>
      <tbody className="templateaftereditdata">
        {prescriptionDataTemplate.map((row) => (
          <tr key={row.id}>
            <td style={{ width: "35%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    width: "65%",
                    textAlign: "center",
                    fontSize: "16px",
                    fontFamily: "Calibri",
                    padding: "5px",
                  }}
                >
                  {row.medication_name}
                </span>
                <span
                  style={{
                    width: "21%",
                    textAlign: "center",
                    fontSize: "16px",
                    fontFamily: "Calibri",
                    padding: "5px",
                  }}
                >
                  {row.dosage}
                </span>
              </div>
            </td>
            <td style={{ width: "10%", textAlign: "center" }}>
              <span
                style={{
                  width: "100%",
                  fontSize: "16px",
                  fontFamily: "Calibri",
                  padding: "5px",
                }}
              >
                {row.frequency.morning}
              </span>
            </td>
            <td style={{ width: "10%", textAlign: "center" }}>
              <span
                style={{
                  width: "100%",
                  fontSize: "16px",
                  fontFamily: "Calibri",
                  padding: "5px",
                }}
              >
                {row.frequency.afternoon}
              </span>
            </td>
            <td style={{ width: "10%", textAlign: "center" }}>
              <span
                style={{
                  width: "100%",
                  fontSize: "16px",
                  fontFamily: "Calibri",
                  padding: "5px",
                }}
              >
                {row.frequency.night}
              </span>
            </td>
            <td style={{ width: "15%", textAlign: "center" }}>
              <span
                style={{
                  width: "100%",
                  fontSize: "16px",
                  fontFamily: "Calibri",
                  padding: "5px",
                }}
              >
                {row.duration}
              </span>
            </td>
            <td className="printlastnonecol" style={{ width: "40%" }}>
              <span
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "16px",
                  fontFamily: "Calibri",
                  padding: "5px",
                }}
              >
                {row.instructions}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TemplateAfterUpdate;
