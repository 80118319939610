import React, { useState, useEffect } from "react";
import {
  Table,
  InputGroup,
  FormControl,
  Pagination,
  Spinner,
  Alert,
  Button,
} from "react-bootstrap";
import { configJson } from "../../../Constent/config";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Sidebar from "../../Home/Components/Sidebar/Sidebar";
import UpdateUserRole from "./dropdown";
// import UserInfo from "./UserInfo";

const AddRole = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [showRoleUpdate, setShowRoleUpdate] = useState(false);
  const [userUpdateData, setUserUpdateData] = useState({});
  const [specialties, setSpecialties] = useState([]);
  // const [showanother,setShowanoter]=useState(false);
  const usersPerPage = 8;
  const navigate = useNavigate();

  const fetchSpecialties = async () => {
    try {
      const response = await fetch(`${configJson.backend_URL}/specialties`);
      if (!response.ok) throw new Error("Failed to fetch specialties data");
      const data = await response.json();
      setSpecialties(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchUsers = async (organization_id) => {
    setLoading(true); // Start loading
    setError(null); // Reset error
    try {
      const response = await fetch(
        `${configJson.backend_URL}/user/all/userlist/${organization_id}` // Replace with actual endpoint
      );
      const data = await response.json();
      if (response.ok) {
        setUsers(data);
        setFilteredUsers(data); // Initially, all users are visible
      } else {
        setError(data.message || "Failed to fetch users");
      }
    } catch (error) {
      setError("Error fetching users: " + error.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Fetch users from the API
  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded.role === "admin") {
        fetchUsers(decoded.organization_id);
        fetchSpecialties();
      } else {
        navigate("/login");
      }
    }
  }, []);

  // Search functionality
  useEffect(() => {
    const filtered = users.filter(
      (user) =>
        user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
    setCurrentPage(1); // Reset to first page on new search
  }, [searchTerm, users]);

  // Pagination logic
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  //Role Update Model Close
  const handleRoleUpdateClose = () => {
    setShowRoleUpdate(false);
  };
  const RoleUpdate = (data) => {
    setUserUpdateData(data);
    setShowRoleUpdate(true);
  };
  // const adds=(data)=>{
  //   setUserUpdateData(data);
  //   setShowanoter(true)
  // }

  return (
    // <Sidebar setuser={true}>
      <div className="container mt-4">
        {showRoleUpdate ? (
          <UpdateUserRole
            user={userUpdateData}
            show={userUpdateData}
            handleClose={handleRoleUpdateClose}
            specialties={specialties}
            setUsers={setUsers}
          />
        ) : (
          ""
        )}
        <h2 className="mb-3">Users List</h2>
        {/* {showanother ? <UserInfo userData={userUpdateData} /> : ""} */}
        {/* Error Message */}
        {error && <Alert variant="danger">{error}</Alert>}

        {/* Search Bar */}
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Search by name or email"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>

        {/* Table with Loading Spinner */}
        {loading ? (
          <div className="text-center my-4">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>S.no</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.length > 0 ? (
                currentUsers.map((user, index) => (
                  <tr key={user.user_id}>
                    <td>{indexOfFirstUser + index + 1}</td>
                    <td>{user.first_name}</td>
                    <td>{user.last_name}</td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                    <td>
                      <Button type="button" onClick={() => RoleUpdate(user)}>
                        Update Role
                      </Button>
                      {/* <Button type="button" onClick={() => adds(user)}>
                         Role
                      </Button> */}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    No users found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}

        {/* Pagination */}
        {!loading && !error && filteredUsers.length > 0 && (
          <Pagination className="justify-content-center">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map(
              (pageNumber) => (
                <Pagination.Item
                  key={pageNumber}
                  active={pageNumber === currentPage}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </Pagination.Item>
              )
            )}
          </Pagination>
        )}
      </div>
    // </Sidebar>
  );
};

export default AddRole;
