import styled from "styled-components";
export const PatientContainer = styled.div`
  .heading-text {
    font-weight: bold;
    text-decoration: underline;
    font-family: "'Roboto', sans-serif";
  }
  .text-part-container {
    max-height: 700px;
    overflow-y: auto;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    color: rgb(51, 51, 51);
    background-color: rgb(249, 249, 249);
    .sign-img {
      width: 150px;
      height: 50px;
      margin-left: 10px;
    }
    .agree-btn {
      text-align: center;
      .agree-button {
        color: #fff;
        background-image: linear-gradient(to right, #ba1a81, #7412b7);
        padding: 5px 20px 5px 20px;
      }
    }
  }
`;
export const FormButton = styled.div`
  text-align: center;
  .close-button {
    color: #fff;
    background-image: linear-gradient(to right, #ba1a81, #7412b7);
    padding: 5px 20px 5px 20px;
  }
  .submit-btn {
    color: #fff;
    background-image: linear-gradient(to right, #ba1a81, #7412b7);
    padding: 5px 20px 5px 20px;
    margin-left: 10px;
  }
`;
export const ClearSavebtn = styled.div`
  .clear-btn {
    color: #fff;
    background-image: linear-gradient(to right, #ba1a81, #7412b7);
    padding: 5px 20px 5px 20px;
  }
  .save-btn {
    color: #fff;
    background-image: linear-gradient(to right, #ba1a81, #7412b7);
    padding: 5px 20px 5px 20px;
    margin-left: 10px;
  }
`;
