import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { configJson } from "../../Constent/config";
import { AddNewDoctor } from "./Doctorstyle";
import { useForm } from "react-hook-form";
import { Form, Button, Alert, Row, Col } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import eyeicon from "../../assets/signup/eye.png";
import eyeClose from "../../assets/signup/eyeclose.png";
import { AdddoctorForm } from "../Login-page/LoginStyle";

const AddDoctorModal = ({ show, handleClose, handleFetch }) => {
  const [decode, setDecode] = useState({});
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [errorMessage, setErrorMessage] = useState(null);
  // const [successMessage, setSuccessMessage] = useState(null); // Success message state
  const [showPassword, setShowPassword] = useState(false);
  const [selectedMemberType, setSelectedMemberType] = useState("doctor");
  const [specialities, setSpecialities] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchSpecialities = async () => {
    try {
      const response = await fetch(`${configJson.backend_URL}/specialties/`);
      const data = await response.json();
      setSpecialities(data);
    } catch (error) {
      console.error("Error fetching specialities:", error);
    }
  };

  const handleSpecialityChange = (e) => {
    const selected = e.target.value;
    setValue("specialty_id", selected);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleMemberTypeChange = (e) => {
    const selected = e.target.value;
    setSelectedMemberType(selected);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await fetch(`${configJson.backend_URL}/doctor/create`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          organization_id: decode.organization_id,
          speciality_id: selectedMemberType === "doctor" ? data.specialty_id : null,
          first_name: data.first_name,
          last_name: data.last_name,
          license_no: data.license_no,
          password_hash: data.password,
          phone_number: data.phone_number,
          email: data.email,
          role: selectedMemberType,
        }),
      });
  
      const newUser = await response.json();
  
      if (response.ok) {
        reset();
        handleFetch(newUser, selectedMemberType); // Send new user data and role to UserList
        handleClose(); // Close the modal
      } else {
        setErrorMessage(newUser.message || "An unexpected error occurred. Please try again.");
      }
    } catch (error) {
      setErrorMessage("An unexpected error occurred: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      setDecode(decoded);
      fetchSpecialities();
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <Modal
      className="Memberaddition"
      show={show}
      onHide={handleClose}
      style={{ position: "absolute", left: "0%", top: "17%" }}
      backdrop="static"
    >
      <AddNewDoctor>
        <div>
          <Modal.Header closeButton style={{ alignItems: "center" }}>
            <div className="head-text-container">
              <h2 className="head-text">Add Member</h2>
            </div>
          </Modal.Header>
          <Modal.Body className="Modalmember">
            {/* {successMessage && (
              <Alert variant="success">{successMessage}</Alert>
            )} */}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <AdddoctorForm>
              <Form onSubmit={handleSubmit(onSubmit)}>
                {/* Member Type Radio Buttons */}
                <Form.Group
                  className="mb-3 sh-pass"
                  as={Row}
                  controlId="formMemberType"
                >
                  <Form.Label column sm={4}>
                    Member Type <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Col sm={8} className="d-flex">
                    <Form.Check
                      type="radio"
                      label="Doctor"
                      value="doctor"
                      {...register("member_type", {
                        required: "Member type is required",
                      })}
                      onChange={handleMemberTypeChange}
                      checked={selectedMemberType === "doctor"}
                    />
                    <Form.Check
                      type="radio"
                      label="Technician"
                      value="technician"
                      {...register("member_type", {
                        required: "Member type is required",
                      })}
                      onChange={handleMemberTypeChange}
                      checked={selectedMemberType === "technician"}
                    />
                    <Form.Check
                      type="radio"
                      label="Front Office Staff"
                      value="front office staff"
                      {...register("member_type", {
                        required: "Member type is required",
                      })}
                      onChange={handleMemberTypeChange}
                      checked={selectedMemberType === "front office staff"}
                    />
                    <Form.Check
                      type="radio"
                      label="vital"
                      value="vital"
                      {...register("member_type", {
                        required: "Member type is required",
                      })}
                      onChange={handleMemberTypeChange}
                      checked={selectedMemberType === "vital"}
                    />
                    {errors.member_type && (
                      <Form.Text className="text-danger">
                        {errors.member_type.message}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>

                {/* Conditionally render the specialty field if member type is Doctor */}
                {selectedMemberType === "doctor" && (
                  <>
                    {/* License Number Field */}
                    <Form.Group as={Row} controlId="formLicenseNo">
                      <Form.Label column sm={4}>
                        License Number <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Col className="mb-3" sm={8}>
                        <Form.Control
                          type="text"
                          placeholder="Enter license number"
                          {...register("license_no", {
                            required: "License number is required",
                          })}
                        />
                        {errors.license_no && (
                          <Form.Text className="text-danger">
                            {errors.license_no.message}
                          </Form.Text>
                        )}
                      </Col>
                    </Form.Group>

                    {/* Specialty Field */}
                    <Form.Group as={Row} controlId="formSpeciality">
                      <Form.Label column sm={4}>
                        Specialty <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Col className="mb-3" sm={8}>
                        <Form.Select
                          {...register("specialty_id", {
                            required: "Specialty is required",
                          })}
                          onChange={handleSpecialityChange}
                        >
                          <option value="">Select Specialty</option>
                          {specialities.map((speciality) => (
                            <option
                              key={speciality.speciality_id}
                              value={speciality.speciality_id}
                            >
                              {speciality.specialty_name}
                            </option>
                          ))}
                        </Form.Select>
                        {errors.specialty_id && (
                          <Form.Text className="text-danger">
                            {errors.specialty_id.message}
                          </Form.Text>
                        )}
                      </Col>
                    </Form.Group>
                  </>
                )}

                {/* Additional form fields */}
                {[
                  { label: "First Name", name: "first_name", required: true },
                  { label: "Last Name", name: "last_name", required: true },
                  { label: "Phone Number", name: "phone_number" },
                  {
                    label: "Email",
                    name: "email",
                    required: true,
                    pattern: {
                      value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                      message: "Email is not valid",
                    },
                  },
                  { label: "Password", name: "password", required: true },
                ].map(({ label, name, required, pattern }) => (
                  <Form.Group as={Row} controlId={`form${name}`} key={name}>
                    <Form.Label column sm={4}>
                      {label}{" "}
                      {required && <span style={{ color: "red" }}>*</span>}
                    </Form.Label>
                    <Col sm={8} className="mb-3">
                      <div className="sh-pass">
                        <Form.Control
                          type={
                            name === "email"
                              ? "email"
                              : name === "password"
                              ? showPassword
                                ? "text"
                                : "password"
                              : "text"
                          }
                          placeholder={`Enter ${label.toLowerCase()}`}
                          {...register(name, {
                            required: required && `${label} is required`,
                            pattern: pattern,
                          })}
                        />
                        {name === "password" && (
                          <span className="show-pass">
                            {showPassword ? (
                              <img
                                onClick={togglePasswordVisibility}
                                src={eyeClose}
                                title="Hide password"
                                alt="Hide password"
                                className="sign-input-image-close"
                              />
                            ) : (
                              <img
                                onClick={togglePasswordVisibility}
                                src={eyeicon}
                                title="Show password"
                                alt="Show password"
                                className="sign-input-image"
                              />
                            )}
                          </span>
                        )}
                      </div>
                      {errors[name] && (
                        <Form.Text className="text-danger">
                          {errors[name].message}
                        </Form.Text>
                      )}
                    </Col>
                  </Form.Group>
                ))}

                {/* Submit Button with Loading State */}
                <Button
                  className="submitmem"
                  variant="primary"
                  type="submit"
                  style={{ marginTop: "10px" }}
                  disabled={loading} // Disable button when loading
                >
                  {loading ? "Submitting..." : "Submit"}
                </Button>
              </Form>
            </AdddoctorForm>
          </Modal.Body>
        </div>
      </AddNewDoctor>
    </Modal>
  );
};

export default AddDoctorModal;
