import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    Button,
    Modal,
    Form,
    Table,
} from "react-bootstrap";
import { configJson } from '../../Constent/config';

const KollaConnect = () => {
  const [consumerToken, setConsumerToken] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch the consumer token from your backend
  const fetchConsumerToken = async () => {
    try {
      const response = await fetch(`${configJson.backend_URL}/kolla/get-consumer-token`); // Call your backend
      const data = await response.json();
      if (data.consumer_token) {
        setConsumerToken(data.consumer_token); // Set the token in state
        setLoading(false); // Stop loading after receiving token
      } else {
        throw new Error('No consumer token returned');
      }
    } catch (error) {
      setError('Error fetching consumer token: ' + error.message); // Set error message
      setLoading(false); // Stop loading if error occurs
      console.error('Error fetching consumer token:', error);
    }
  };

  useEffect(() => {
    fetchConsumerToken(); // Fetch the token when the component mounts
  }, []);

  // Function to open Kolla marketplace
  const openMarketplace = () => {
    if (consumerToken) {
      if (window.kolla) {
        window.kolla.authenticate(consumerToken);  // Authenticate with the token
        window.kolla.openMarketplace();  // Open the marketplace
      } else {
        console.error('Kolla SDK is not available');
        setError('Kolla SDK is not available');
      }
    } else {
      console.error('No consumer token available');
      setError('No consumer token available');
    }
  };

  return (
    <Container fluid className="mt-4">
      {/* Conditionally render the content based on loading or errors */}
      {loading ? (
        <p>Loading consumer token...</p> // Show loading state
      ) : error ? (
        <div style={{ color: 'red' }}>{error}</div> // Show error if any
      ) : (
        <Button className="addTestclass mt-3" onClick={openMarketplace}>Open Marketplace</Button> // Render button when token is available
      )}
    </Container>
  );
};

export default KollaConnect;
