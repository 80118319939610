import React, { useEffect, useState, useRef } from "react";
import { Container, Button, Modal, Spinner } from "react-bootstrap"; // Import Spinner component
import SignatureCanvas from "react-signature-canvas";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import {
  ClearSavebtn,
  FormButton,
  PatientContainer,
} from "./patientconsentformStyle";
import { convertToKolkataTime, convertToUTC } from "../../../helpers";
import { configJson } from "../../../Constent/config";
import { toast } from "react-toastify";

function App() {
  const [showAgreeButton, setShowAgreeButton] = useState(false);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [signaturePad, setSignaturePad] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // State for disabling button
  const location = useLocation();
  const [token, setToken] = useState("");
  const [decrypt, setDecrypt] = useState();
  // const termsBoxRef = useRef(null);
  const [date, setDate] = useState(null);
  const sigCanvas = useRef(null);
  const [signature, setSignature] = useState(null);
  const [showbtn, setShowbtn] = useState(false);
  const navigate = useNavigate();
  console.log(signature);

  //grt token
  useEffect(() => {
    const parseQueryString = async () => {
      const searchParams = new URLSearchParams(location.search);
      console.log(searchParams, "searchParams");
      const headers = {};
      for (const [key, value] of searchParams.entries()) {
        headers[key] = value;
      }

      if (headers.token) {
        console.log("headers.token", headers.token);
        setToken(headers.token);
        try {
          let details = jwtDecode(headers.token);
          console.log(details);
          setDecrypt(details);
        } catch (error) {
          console.log(error);
          alert(
            "Token is not valid. Please go to your email and click the link again."
          );
          window.location.href = "https://www.google.com/";
        }
      } else {
        alert(
          "Token is empty. Please go to your email and click the link again."
        );
        window.location.href = "https://www.google.com/";
      }
    };
    parseQueryString();
  }, [location.search]);

  useEffect(() => {
    // console.log(token);
    // console.log(decrypt);
  }, [token, decrypt]);

  const handleSubmit = async () => {
    setIsLoading(true);
    setIsButtonDisabled(true);
    try {
      const request = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          patient_id: decrypt.patient_id,
          signature: signature,
          doctorName:
            decrypt.doctor_first_name + " " + decrypt.doctor_last_name,
          doctor_email: decrypt.doctor_email,
          date: date,
        }),
      };
      const response = await fetch(
        `${configJson.backend_URL}/patient/consentStatusupdate`,
        request
      );

      if (response.ok) {
        const data = await response.json();
        // console.log(data)
        if (data?.patient === true) {
          toast.success("Thank you for submitting!");
          window.close();
          setSignaturePad(false);
          window.location.href = "https://www.google.com/";
        }
      } else {
        const data = response.json();
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error);
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsButtonDisabled(false);
    }

    // const requestOptions = {
    //   method: "PUT",
    //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
    // };

    // const response = await fetch(
    //   `${configJson.backend_URL}/approval/${decrypt.id}`,
    //   requestOptions
    // );
    // const data = await response.json();

    // setIsLoading(false);
    // window.alert("Thank you for submitting!");
    // window.close();
    // setSignaturePad(false);
    // window.location.href = "https://www.google.com/";
  };

  const handleOpenSignatureModal = () => {
    setShowSignatureModal(true);
  };

  const handleCloseSignatureModal = () => {
    setShowSignatureModal(false);
  };

  //close operation
  const handleCloseform = () => {
    window.close();
    window.location.href = "https://www.google.com/";
  };

  //Clear
  const clear = () => {
    sigCanvas.current.clear();
  };
  //set image url
  const saveimage = () => {
    if (sigCanvas.current.isEmpty()) {
      setSignaturePad(false);
      alert("Please provide a signature first.");
      return;
    }
    const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    setSignature(dataURL); // Store the signature in the state
    setShowSignatureModal(false);
    setSignaturePad(true);
    setShowbtn(true);
  };

  //get date
  useEffect(() => {
    const formattedDate = convertToUTC();
    const d1 = convertToKolkataTime(formattedDate);
    // const formattedDate = now.toLocaleString("en-US", {
    //   month: "short",
    //   day: "2-digit",
    //   year: "2-digit",
    //   hour: "2-digit",
    //   minute: "2-digit",
    //   hour12: true,
    // });
    // console.log(formattedDate.split(",").join("/"));
    setDate(d1);
  }, []);

  return (
    <Container className="d-flex align-items-center justify-content-center min-vh-100">
      <PatientContainer>
        <h1 className="text-center mb-4 heading-text">
          Patient Informed Consent
        </h1>

        <div className="terms-box text-part-container">
          {decrypt && decrypt.Patient_first_name ? (
            <ul style={{ listStyleType: "none", paddingLeft: "20px" }}>
              <li>
                <p>
                  <strong>Notice:</strong>
                  <span className="m-2">
                    Before we proceed with your appointment, I want to inform
                    you about an important aspect of how we document our
                    consultations. We utilize a note taking tool called
                    medscribe.app to accurately and efficiently capture the
                    details of our discussions and the outcomes of our
                    appointments. medscribe ensures that we can focus more on
                    our conversation and less on manual note taking, enhancing
                    the quality of care you receive. Your consent is crucial for
                    us to use this technology. Please understand that your
                    information will be handled with the utmost care, and
                    medscribe use is aimed solely at improving your healthcare
                    experience.
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <strong>Name:</strong>
                  <span className="m-2">
                    {decrypt.Patient_first_name}
                    {decrypt.Patient_last_name}
                  </span>
                  {/* <span>decrypt.fname</span> */}
                </p>
                <p>
                  <strong>Date:</strong>
                  <span className="m-2">{date}</span>
                </p>
                <p>
                  <strong> Signature:</strong>
                  {signature ? (
                    <img
                      className="sign-img"
                      src={signature}
                      alt="sign"
                      title="sign"
                    />
                  ) : (
                    ""
                  )}
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    By signing this consent form, you are agreeing to allow your
                    clinician to use medscribe during your consultation.
                  </strong>
                </p>
              </li>
              <li>
                {!showbtn ? (
                  <p className="agree-btn">
                    <Button
                      variant="primary"
                      onClick={handleOpenSignatureModal}
                      className="agree-button"
                    >
                      I Agree
                    </Button>
                  </p>
                ) : (
                  ""
                )}
              </li>
            </ul>
          ) : null}
        </div>
        {signature ? (
          <FormButton>
            <Button className="close-button" onClick={handleCloseform}>
              Close
            </Button>
            <Button
              className="submit-btn"
              onClick={handleSubmit}
              disabled={!signaturePad}
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Submit"
              )}
            </Button>
          </FormButton>
        ) : (
          ""
        )}
      </PatientContainer>

      {/* Signature Modal */}
      <Modal
        show={showSignatureModal}
        onHide={handleCloseSignatureModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Provide Your Signature</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Signature Pad */}
          <SignatureCanvas
            ref={sigCanvas}
            canvasProps={{
              width: 400,
              height: 200,
              className: "signature-canvas",
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <ClearSavebtn>
            <Button
              className="clear-btn"
              onClick={clear}
              disabled={isButtonDisabled}
            >
              clear
            </Button>
            <Button
              className="save-btn"
              onClick={saveimage}
              disabled={isButtonDisabled}
            >
              save
            </Button>
          </ClearSavebtn>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default App;
