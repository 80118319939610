import React from "react";
import { Drawer } from "antd";

const PatientSummary = ({ summaryDrawer, setSummaryDrawer }) => {
  return (
    <Drawer
      placement="right"
      size="large"
      open={summaryDrawer}
      onClose={() => setSummaryDrawer(false)}
      extra={<div className="text-xl font-semibold">Summary report of ---</div>}
    >
      <div className="flex flex-col gap-3">
        <div>
          <div className="text-lg font-semibold">Summary:</div>
          <div>
            The patient reported experiencing pain on both sides of their lower
            abdomen, characterizing it as a full, painful sensation. They denied
            consuming anything unusual. Their typical morning meal consisted of
            idli. The patient admitted to engaging in physical activity,
            specifically jumping, as part of a school event.
          </div>
        </div>
        <div>
          <div className="text-lg font-semibold">Vitals report:</div>
          <div>
            The patient reported experiencing pain on both sides of their lower
            abdomen, characterizing it as a full, painful sensation. They denied
            consuming anything unusual. Their typical morning meal consisted of
            idli. The patient admitted to engaging in physical activity,
            specifically jumping, as part of a school event.
          </div>
        </div>
        <div>
          <div className="text-lg font-semibold">Medication:</div>
          <div>
            The patient reported experiencing pain on both sides of their lower
            abdomen, characterizing it as a full, painful sensation. They denied
            consuming anything unusual. Their typical morning meal consisted of
            idli. The patient admitted to engaging in physical activity,
            specifically jumping, as part of a school event.
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default PatientSummary;
