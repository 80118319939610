// import React, { useEffect, useState } from "react";
// import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
// import { useForm } from "react-hook-form";
// import { configJson } from "../../../Constent/config";
// import { jwtDecode } from "jwt-decode";

// const ProfileUpdate = () => {
//   const {
//     register,
//     handleSubmit,
//     setValue,
//     getValues, // Added this to destructure getValues
//     formState: { errors },
//   } = useForm();
//   const [userData, setUserData] = useState(null);
//   const [error, setError] = useState("");
//   // Handle Profile Image Change
//   const handleImageChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       console.log("Selected file:", file.name);
//       setValue("profileImage", file); // Set the file in the form state
//     }
//   };

//   // Profile Update Submit Handler
//   const onSubmitProfile = (data) => {
//     console.log("Profile Data Submitted:", data);
//   };

//   // Password Update Submit Handler
//   const onSubmitPassword = (data) => {
//     console.log("Password Data Submitted:", data);
//   };
//   const handleFetchUser = async (organizationId, contactNumber, email) => {
//     try {
//       // Validate input before making the API call
//       if (!organizationId || !contactNumber || !email) {
//         setError("All fields are required");
//         setUserData(null);
//         return;
//       }

//       // Reset error state
//       setError("");

//       // API endpoint
//       const apiUrl = `${configJson.backend_URL}/user/admin/${organizationId}/${contactNumber}/${email}`;

//       // Fetch data from the backend
//       const response = await fetch(apiUrl);

//       if (!response.ok) {
//         const errorMessage = await response.json();
//         throw new Error(errorMessage.message || "Failed to fetch user");
//       }

//       // Parse response data
//       const data = await response.json();
//       setUserData(data);
//     } catch (err) {
//       setError(err.message);
//       setUserData(null);
//     }
//   };
//   console.log(userData);
//   useEffect(() => {
//     const token = sessionStorage.getItem("orgtoken");
//     if (token) {
//       const decoded = jwtDecode(token);
//       console.log(23, decoded);
//       if (decoded) {
//         handleFetchUser(
//           decoded.organization_id,
//           decoded.contact_number,
//           decoded.email
//         );
//       }
//     }
//   }, []);
//   return (
//     <Container>
//       <Row className="mt-5">
//         {/* Personal Data Section */}
//         <Col md={6}>
//           <Card className="p-4">
//             <h5>Personal Data</h5>
//             <div className="d-flex align-items-center mb-4">
//               <div
//                 style={{
//                   width: "100px",
//                   height: "100px",
//                   borderRadius: "50%",
//                   border: "2px solid #ccc",
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "center",
//                   position: "relative",
//                   overflow: "hidden",
//                 }}
//               >
//                 <img
//                   src="https://via.placeholder.com/100"
//                   alt="Profile"
//                   style={{ width: "100%", height: "100%", objectFit: "cover" }}
//                 />
//                 <label
//                   htmlFor="profileImage"
//                   style={{
//                     position: "absolute",
//                     bottom: "10px",
//                     right: "10px",
//                     backgroundColor: "#fff",
//                     borderRadius: "50%",
//                     padding: "5px",
//                     cursor: "pointer",
//                     border: "1px solid #ccc",
//                   }}
//                 >
//                   <i className="bi bi-camera"></i>
//                 </label>
//                 <input
//                   type="file"
//                   id="profileImage"
//                   style={{ display: "none" }}
//                   onChange={handleImageChange}
//                 />
//               </div>
//               <p className="ms-3">
//                 <strong>Profile Image</strong>
//                 <br />
//                 The proposed size is 512x512 px, no bigger than 2.5 MB
//               </p>
//             </div>

//             <Form onSubmit={handleSubmit(onSubmitProfile)}>
//               <Row>
//                 <Col md={6}>
//                   <Form.Group className="mb-3">
//                     <Form.Label>First Name</Form.Label>
//                     <Form.Control
//                       type="text"
//                       placeholder="Enter first name"
//                       {...register("first_name", {
//                         required: "First Name is required",
//                       })}
//                     />
//                     {errors.firstName && (
//                       <small className="text-danger">
//                         {errors.first_name.message}
//                       </small>
//                     )}
//                   </Form.Group>
//                 </Col>
//                 <Col md={6}>
//                   <Form.Group className="mb-3">
//                     <Form.Label>Last Name</Form.Label>
//                     <Form.Control
//                       type="text"
//                       placeholder="Enter last name"
//                       {...register("last_name", {
//                         required: "Last Name is required",
//                       })}
//                     />
//                     {errors.lastName && (
//                       <small className="text-danger">
//                         {errors.last_name.message}
//                       </small>
//                     )}
//                   </Form.Group>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col md={6}>
//                   <Form.Group className="mb-3">
//                     <Form.Label>Email</Form.Label>
//                     <Form.Control
//                       type="email"
//                       placeholder="Enter email"
//                       {...register("email", {
//                         required: "Email is required",
//                         pattern: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
//                       })}
//                     />
//                     {errors.email && (
//                       <small className="text-danger">
//                         {errors.email.message}
//                       </small>
//                     )}
//                   </Form.Group>
//                 </Col>
//                 <Col md={6}>
//                   <Form.Group className="mb-3">
//                     <Form.Label>Phone</Form.Label>
//                     <Form.Control
//                       type="text"
//                       placeholder="Phone"
//                       {...register("contact_number", {
//                         pattern: {
//                           value: /^[0-9\s-]+$/,
//                           message: "Only numbers, spaces, and dashes allowed.",
//                         },
//                       })}
//                     />
//                      {errors.lastName && (
//                       <small className="text-danger">
//                         {errors.contact_number.message}
//                       </small>
//                     )}
//                   </Form.Group>
//                 </Col>
//                 <Col md={6}>
//                   <Form.Group className="mb-3">
//                     <Form.Label>Last Name</Form.Label>
//                     <Form.Control
//                       type="text"
//                       placeholder="Enter last name"
//                       {...register("username", {
//                         required: "Last Name is required",
//                       })}
//                     />
//                     {errors.lastName && (
//                       <small className="text-danger">
//                         {errors.username.message}
//                       </small>
//                     )}
//                   </Form.Group>
//                 </Col>
//               </Row>
//               <Button variant="primary" type="submit">
//                 Update Profile
//               </Button>
//             </Form>
//           </Card>
//         </Col>

//         {/* Password Change Section */}
//         <Col md={6}>
//           <Card className="p-4">
//             <h5>Change Password</h5>
//             <Form onSubmit={handleSubmit(onSubmitPassword)}>
//               <Form.Group className="mb-3">
//                 <Form.Label>Current Password</Form.Label>
//                 <Form.Control
//                   type="password"
//                   placeholder="Current Password"
//                   {...register("currentPassword", {
//                     required: "Current Password is required",
//                   })}
//                 />
//                 {errors.currentPassword && (
//                   <small className="text-danger">
//                     {errors.currentPassword.message}
//                   </small>
//                 )}
//               </Form.Group>
//               <Form.Group className="mb-3">
//                 <Form.Label>New Password</Form.Label>
//                 <Form.Control
//                   type="password"
//                   placeholder="Password"
//                   {...register("newPassword", {
//                     required: "New Password is required",
//                   })}
//                 />
//                 {errors.newPassword && (
//                   <small className="text-danger">
//                     {errors.newPassword.message}
//                   </small>
//                 )}
//               </Form.Group>
//               <Form.Group className="mb-3">
//                 <Form.Label>Confirm Password</Form.Label>
//                 <Form.Control
//                   type="password"
//                   placeholder="Confirm Password"
//                   {...register("confirmPassword", {
//                     required: "Confirm Password is required",
//                     validate: (value) =>
//                       value === getValues("newPassword") ||
//                       "Passwords do not match",
//                   })}
//                 />
//                 {errors.confirmPassword && (
//                   <small className="text-danger">
//                     {errors.confirmPassword.message}
//                   </small>
//                 )}
//               </Form.Group>
//               <Button variant="primary" type="submit">
//                 Update Password
//               </Button>
//             </Form>
//           </Card>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default ProfileUpdate;

import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { configJson } from "../../../Constent/config";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { CiCamera } from "react-icons/ci";
import { convertToKolkataTime, convertToUTC } from "../../../helpers";
import axios from "axios";
import Sidebar from "../../Home/Components/Sidebar/Sidebar";

const ProfileUpdate = () => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState("");
  const [imgurl, setImgurl] = useState(null);
  const [logo, setLogo] = useState("");
  const [date, setDate] = useState(null);
  // Profile Form Hook
  const {
    register: profileRegister,
    handleSubmit: handleProfileSubmit,
    setValue: setProfileValue,
    formState: { errors: profileErrors },
  } = useForm();

  // Password Form Hook
  const {
    register: passwordRegister,
    handleSubmit: handlePasswordSubmit,
    setValue: setPasswordRegistervalue,
    formState: { errors: passwordErrors },
    getValues,
  } = useForm();

  // Handle Profile Image Change
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileExtension = file.name.split(".").pop();
      if (fileExtension === "png" || fileExtension === "jpg") {
        setFile(file);
        console.log(fileExtension);
        setLogo(URL.createObjectURL(file)); // Create a preview URL for the uploaded file
      } else {
        toast.error("select only PNG or JPG ");
      }
    }
  };

  // Fetch User Data
  const handleFetchUser = async (organizationId, contactNumber, email) => {
    try {
      // Validate input before making the API call
      if (!organizationId || !contactNumber || !email) {
        setError("All fields are required");
        setUserData(null);
        return;
      }

      // Reset error state
      setError("");

      // API endpoint
      const apiUrl = `${configJson.backend_URL}/user/admin/${organizationId}/${contactNumber}/${email}`;

      // Fetch data from the backend
      const response = await fetch(apiUrl);

      if (!response.ok) {
        const errorMessage = await response.json();
        throw new Error(errorMessage.message || "Failed to fetch user");
      }

      // Parse response data
      const data = await response.json();
      setUserData(data);

      // Set fetched data to profile form fields
      setProfileValue("first_name", data.first_name);
      setProfileValue("last_name", data.last_name);
      setProfileValue("email", data.email);
      setProfileValue("contact_number", data.contact_number);
      setProfileValue("username", data.username);
      setProfileValue("user_id", data.user_id);
      setPasswordRegistervalue("user_id", data.user_id);
      if (data) {
        setImgurl(data.logo_url);
        setLogo(data.url);
      }
    } catch (err) {
      setError(err.message);
      setUserData(null);
    }
  };

  // UseEffect to fetch data on component mount
  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      console.log(23, decoded);
      if (decoded) {
        handleFetchUser(
          decoded.organization_id,
          decoded.contact_number,
          decoded.email
        );
        const formattedDate = convertToUTC();
        const d = convertToKolkataTime(formattedDate);
        console.log(d);
        setDate(d);
      }
    }
  }, []);

  // Handle Profile Form Submit
  const onSubmitProfile = async (data) => {
    console.log("Profile Data Submitted:", data);
    if (!file) {
      UpdateProfile(data, imgurl);
    } else {
      let result = date.replace(/[-:]/g, "_").replace(", ", "_");
      const formData = new FormData();
      formData.append("file", file);
      formData.append("file_name", `user/${data.user_id}_${result}.png`);
      try {
        // Make the request to the server
        const response = await axios.post(
          `${configJson.backend_URL}/superadmin/logo/img`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response);
        if (response.status === 200) {
          console.log(response);
          const url = response.data.imgUrl;
          UpdateProfile(data, url);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error uploading interaction:", error);
      }
    }
    // // Add API call logic here
    // try {
    //   const response = await fetch(
    //     `${configJson.backend_URL}/user/userupdate`,
    //     {
    //       method: "PUT",
    //       headers: {
    //         "Content-Type": "application/json",
    //         // Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Include token if required
    //       },
    //       body: JSON.stringify(data),
    //     }
    //   );

    //   if (!response.ok) {
    //     const errorData = await response.json();
    //     toast.error("Error updating user details:", errorData.message);
    //     return;
    //   }

    //   const result = await response.json();
    //   console.log("User details updated successfully:", result.message);
    // } catch (error) {
    //   toast.error("Error making the API call:", error.message);
    // }
  };
  const UpdateProfile = async (data, imgurl) => {
    const dataform = {
      ...data,
      logo_url: imgurl,
    };
    try {
      const response = await fetch(
        `${configJson.backend_URL}/user/userupdate`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Include token if required
          },
          body: JSON.stringify(dataform),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        toast.error("Error updating user details:", errorData.message);
        return;
      }

      const result = await response.json();
      console.log("User details updated successfully:", result.message);
    } catch (error) {
      toast.error("Error making the API call:", error.message);
    }
  };
  // Handle Password Form Submit
  const onSubmitPassword = async (data) => {
    console.log("Password Data Submitted:", data);
    // Validate the form fields before making the API call
    if (!data.currentPassword || !data.newPassword || !data.confirmPassword) {
      setMessage("All fields are required");
      return;
    }

    if (data.newPassword !== data.confirmPassword) {
      setMessage("Passwords do not match");
      return;
    }

    try {
      const response = await fetch(
        `${configJson.backend_URL}/user/update/password`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Use session token if needed
          },
          body: JSON.stringify(data),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log(result);
        setMessage(result.message);
      } else {
        setMessage(result.message || "Failed to update password");
      }
    } catch (error) {
      console.error("Error updating password:", error.message);
      setMessage("Internal server error");
    }
  };

  return (
    // <Sidebar setuser={true}>
      <Container>
        <Row className="mt-5">
          {/* Personal Data Section */}
          <Col md={6}>
            <Card className="p-4">
              <h5>Personal Data</h5>
              <div className="d-flex align-items-center mb-4">
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    border: "2px solid #ccc",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={logo}
                    alt="Profile"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <label
                    htmlFor="profileImage"
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      right: "10px",
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                      padding: "5px",
                      cursor: "pointer",
                      border: "1px solid #ccc",
                    }}
                  >
                    <CiCamera />
                  </label>
                  <input
                    type="file"
                    id="profileImage"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                </div>
                <p className="ms-3">
                  <strong>Profile Image</strong>
                  <br />
                  The proposed size is 512x512 px, no bigger than 2.5 MB
                </p>
              </div>

              <Form onSubmit={handleProfileSubmit(onSubmitProfile)}>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter first name"
                        {...profileRegister("first_name", {
                          required: "First Name is required",
                        })}
                      />
                      {profileErrors.first_name && (
                        <small className="text-danger">
                          {profileErrors.first_name.message}
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter last name"
                        {...profileRegister("last_name", {
                          required: "Last Name is required",
                        })}
                      />
                      {profileErrors.last_name && (
                        <small className="text-danger">
                          {profileErrors.last_name.message}
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        {...profileRegister("email", {
                          required: "Email is required",
                          pattern: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
                        })}
                      />
                      {profileErrors.email && (
                        <small className="text-danger">
                          {profileErrors.email.message}
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Phone"
                        {...profileRegister("contact_number", {
                          required: "Phone is required",
                          pattern: {
                            value: /^[0-9\s-]+$/,
                            message:
                              "Only numbers, spaces, and dashes allowed.",
                          },
                        })}
                      />
                      {profileErrors.contact_number && (
                        <small className="text-danger">
                          {profileErrors.contact_number.message}
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>User Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter username"
                        {...profileRegister("last_name", {
                          required: "username is required",
                        })}
                      />
                      {profileErrors.last_name && (
                        <small className="text-danger">
                          {profileErrors.username.message}
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Button variant="primary" type="submit">
                  Update Profile
                </Button>
              </Form>
            </Card>
          </Col>

          {/* Password Change Section */}
          <Col md={6}>
            <Card className="p-4">
              <h5>Change Password</h5>
              <Form onSubmit={handlePasswordSubmit(onSubmitPassword)}>
                <Form.Group className="mb-3">
                  <Form.Label>Current Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Current Password"
                    {...passwordRegister("currentPassword", {
                      required: "Current Password is required",
                    })}
                  />
                  {passwordErrors.currentPassword && (
                    <small className="text-danger">
                      {passwordErrors.currentPassword.message}
                    </small>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="New Password"
                    {...passwordRegister("newPassword", {
                      required: "New Password is required",
                    })}
                  />
                  {passwordErrors.newPassword && (
                    <small className="text-danger">
                      {passwordErrors.newPassword.message}
                    </small>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    {...passwordRegister("confirmPassword", {
                      required: "Confirm Password is required",
                      validate: (value) =>
                        value === getValues("newPassword") ||
                        "Passwords do not match",
                    })}
                  />
                  {passwordErrors.confirmPassword && (
                    <small className="text-danger">
                      {passwordErrors.confirmPassword.message}
                    </small>
                  )}
                </Form.Group>
                <Button variant="primary" type="submit">
                  Change Password
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    // </Sidebar>
  );
};

export default ProfileUpdate;
