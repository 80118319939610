import React from "react";
import { Row, Col } from "react-bootstrap";

const BillFooter = ({ total, payment, remarks }) => {
  return (
    <div className="border-top pt-3">
      <Row>
        <Col md={6}>
          <p>
            <strong>Bill Amount:</strong> {total.billAmount.toFixed(2)}
          </p>
          <p>
            <strong>Paid Amount:</strong> {total.paidAmount.toFixed(2)}
          </p>
        </Col>
        <Col md={6}>
          <p>
            <strong>PayMode:</strong> {payment.mode} / No: {payment.referenceNo}
          </p>
          <p>
            <strong>Remarks:</strong> {remarks}
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default BillFooter;
