import React, { useEffect, useState, useRef } from "react";
import { configJson } from "../../../../Constent/config";
import { Button, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { message } from "antd";
import Webcam from "react-webcam";
import axios from "axios";
import { convertToKolkataTime, convertToUTC } from "../../../../helpers";

const UploadFiles = ({
  show,
  onClose,
  patientId,
  doctorId,
  setUploadModal,
  fetchInteractions,
}) => {
  const [interactionTypes, setInteractionTypes] = useState([]);
  const [doctorUploadNotes, setDoctorUploadNotes] = useState("");
  const [interactionDetailType, setInteractionDetailType] = useState("mri");
  const [uploadStatusMessage, setUploadStatusMessage] = useState("");
  const [showUploadStatusPopup, setShowUploadStatusPopup] = useState(false);
  const [file, setFile] = useState([]);
  const [selectedInteractionTypeId, setSelectedInteractionTypeId] =
    useState(null);
  const [loading, setLoading] = useState(false);
  const [openCam, setOpenCam] = useState(false);
  const [isFrontCamera, setIsFrontCamera] = useState(true);
  const [medicines, setMedicines] = useState([]);
  const [otherMedicines, setOtherMedicines] = useState([]);
  // const [formData, setFormData] = useState({
  //   patient_id: userDetails?.patientId,
  //   doctor_id: parseFloat(userDetails?.doctorId),
  //   interaction_date:  new Date().toISOString().slice(0, 19).replace('T', ' '),
  //   interaction_type: "Upload",
  //   interaction_status: '',
  //   interaction_notes: "",
  //   interaction_detail_type: "",
  // });

  //  console.log(userDetails, 'kkkkk')
  const cameraRef = useRef(null);

  const handleCapture = () => {
    if (file !== null) {
      setFile(null);
    }

    const screenshot = cameraRef?.current?.getScreenshot();

    if (screenshot) {
      // Convert base64 data URL to a Blob
      const base64ToBlob = (base64, contentType = "", sliceSize = 512) => {
        const byteCharacters = atob(base64.split(",")[1]);
        const byteArrays = [];

        for (
          let offset = 0;
          offset < byteCharacters.length;
          offset += sliceSize
        ) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
      };

      // Convert base64 to Blob
      const blob = base64ToBlob(screenshot, "image/jpeg");

      // Create a File object with the new name
      const fileName = `${new Date().toISOString()}.jpg`;
      const renamedFile = new File([blob], fileName, { type: "image/jpeg" });

      // Set the renamed file
      setFile(renamedFile);
    }
  };

  // console.log('FILE', file)

  const handleCancel = () => {
    onClose();
    setInteractionDetailType("");
    setFile(null);
    setOpenCam(false);
    setDoctorUploadNotes("");
  };
  // const handleOptionSelect = (typeName, typeId) => {
  //   setInteractionDetailType(typeName);
  //   setSelectedInteractionTypeId(typeId);
  // };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length > 0) {
      setFile(selectedFiles);
      console.log(
        `Files selected: ${selectedFiles.map((file) => file.name).join(", ")}`
      );
    }
  };

  // useEffect(() => {
  //   fetchInteractionTypes();
  // }, []);

  // const fetchInteractionTypes = async () => {
  //   setLoading(true)
  //   try {
  //     const response = await fetch(
  //       `${configJson.backend_URL}/interactiontypes`
  //     );
  //     const result = await response.json();
  //     if (response.ok) {
  //       setInteractionTypes(result);
  //     } else {
  //       message.error("Failed to fetch InteractionTypes.");
  //     }
  //   } catch (error) {
  //     message.error("An error occurred while fetching InteractionTypes.");
  //   } finally {
  //     setLoading(false)
  //   }
  // };
  // const getFormattedDateTime = () => {
  //   const now = new Date();
  //   const formattedDate = now.toLocaleString("en-US", {
  //     month: "2-digit",
  //     day: "2-digit",
  //     year: "2-digit",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     second: "2-digit",
  //     hour12: false,
  //   });
  //   return formattedDate;
  // };

  function getFormattedDateTimeUTC() {
    const dateTime = new Date();
    const formattedDate = convertToUTC();
    const d = convertToKolkataTime(formattedDate);
    const timee = d.replace(/[-:]/g, "_").replace(", ", "_");

    const formattedDateTime = [
      String(dateTime.getUTCMonth() + 1).padStart(2, "0"), // Month
      String(dateTime.getUTCDate()).padStart(2, "0"), // Day
      String(dateTime.getUTCFullYear()).slice(-2), // Year
      String(dateTime.getUTCHours()).padStart(2, "0"), // Hours
      String(dateTime.getUTCMinutes()).padStart(2, "0"), // Minutes
      String(dateTime.getUTCSeconds()).padStart(2, "0"), // Seconds
    ].join("_");

    return timee;
  }

  // const handleSubmitDocs = async (interactionId) => {
  //   if (!interactionDetailType) {
  //     message.error("Please Select Document Type");
  //     return;
  //   }
  //   if (!file) {
  //     message.error(`Please upload an file for Interaction ID: ${interactionId}`);
  //     return;
  //   }
  //   const formData = new FormData();
  //   formData.append("audio_file", file);
  //   const fileName = file.name;
  //   const dateTime = getFormattedDateTime();
  //   const fileExtension = fileName.split(".").pop();
  //   formData.append(
  //     "file_name",
  //     `files/${interactionId}_${patientId}_${dateTime}.${fileExtension}`
  //   );
  //   setLoading(true)

  //   try {
  //     //  const response = await fetch("https://back.denotes.app/upload_audio", {
  //     const response = await fetch(
  //       `${configJson.backend_URL}/soapPrompt/upload`,
  //       {
  //         method: "POST",
  //         body: formData,
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error(`Error: ${response.statusText}`);
  //     }

  //     const responseData = await response.json();
  //     console.log(responseData);
  //     await UploadImageUrl(
  //       interactionId,
  //       selectedInteractionTypeId,
  //       responseData.gcs_uri
  //     );
  //   } catch (error) {
  //     alert(error);
  //     console.error("Error uploading image:", error);
  //     message.error("Error uploading file");
  //   } finally {
  //     onClose()
  //     setLoading(false)
  //   }
  // };
  // const UploadImageUrl = async (interactionId, type, attachmentUrl) => {
  //   try {
  //     const response = await fetch(
  //       `${configJson.backend_URL}/interactionDetail/uploadimage`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           interaction_id: interactionId,
  //           interaction_detail_type: type,
  //           attachment_url: attachmentUrl,
  //           detail_notes: doctorUploadNotes,
  //         }),
  //       }
  //     );

  //     const data = await response.json();
  //     if (!response.ok) {
  //       setShowUploadStatusPopup(true);
  //       setUploadStatusMessage(
  //         "An error occurred while uploading the document."
  //       );
  //     } else {
  //       // setShowUploadStatusPopup(true);
  //       // setUploadStatusMessage("Document uploaded successfully!");
  //       message.success('Document uploaded successfully!')
  //     }
  //   } catch (err) {
  //     // setShowUploadStatusPopup(true);
  //     setUploadStatusMessage("An error occurred while uploading the document.");
  //   }
  // };
  const handleUploadStatusPopup = () => {
    setShowUploadStatusPopup(false);
  };

  const handleSwitchCamera = () => {
    setIsFrontCamera((prev) => !prev);
  };

  // const handleSubmitDocs = async () => {
  //   setLoading(true)
  //   if (!file) {
  //     message.error('Please upload an file');
  //     return;
  //   }
  //   const fileName = file?.name;
  //   const fileExtension = fileName.split(".").pop();
  //   const formData = new FormData();
  //   const dateTime = getFormattedDateTimeUTC()
  //   formData.append('patient_id', patientId)
  //   // formData.append('doctor_id', '')
  //   formData.append('interaction_date', new Date().toISOString().slice(0, 19).replace('T', ' '))
  //   formData.append('interaction_type', '')
  //   formData.append('interaction_status', '')
  //   if (file) {
  //     formData.append("file", file);
  //     formData.append(
  //           "file_name",
  //           `files/${patientId}_${dateTime}.${fileExtension}`
  //         );
  //   }
  //   try {
  //     const result = await axios.post(
  //       `${configJson.backend_URL}/interaction/createImg`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     console.log(result.data);
  //   } catch (error) {
  //     console.error("Error uploading interaction:", error);
  //   } finally {
  //     setLoading(false)
  //     setUploadModal(false)
  //     if (fetchInteractions) {fetchInteractions(patientId)}
  //   }
  // }

  const handleSubmitDocs = async () => {
    setLoading(true);
    if (file.length === 0) {
      message.error("Please upload at least one file");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    const dateTime = getFormattedDateTimeUTC();

    formData.append("patient_id", patientId);
    formData.append(
      "interaction_date",
      new Date().toISOString().slice(0, 19).replace("T", " ")
    );
    formData.append("interaction_type", "docs");
    formData.append("interaction_status", "");

    file.forEach((file, index) => {
      const fileExtension = file.name.split(".").pop();
      const newFileName = `files/${patientId}_${dateTime}_${index}.${fileExtension}`;

      // Create a new file object with the updated name
      const newFile = new File([file], newFileName, { type: file.type });

      // Append the new file to FormData
      formData.append("file", newFile);
    });

    try {
      const res = await axios.post(
        `${configJson.backend_URL}/interaction/createImg`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(result.data);
      setMedicines(
        res.data.answer[0][Object.keys(res.data.answer[0])[0]].medicines
      );
      setOtherMedicines(
        res.data.answer[0][Object.keys(res.data.answer[0])[0]]?.other_medicines
      );
    } catch (error) {
      console.error("Error uploading interaction:", error);
    } finally {
      setLoading(false);
      setUploadModal(false);
      if (fetchInteractions) {
        fetchInteractions(patientId);
      }
    }
  };

  // const displayImage = () => {
  //   if (!file) return null; // Handle case where no image is selected

  //   // Handle different image loading scenarios:
  //   if (file instanceof File) {
  //     // Assuming you're using FileReader:
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       setFile(e.target.result); // Update state with image data URL
  //     };
  //     reader.readAsDataURL(file);
  //   } else if (typeof file === 'string') {
  //     // If file is a URL:
  //     return <img src={file} alt="" width={200} className="m-auto" />;
  //   } else {
  //     console.error('Unexpected file format');
  //     return null; // Handle unexpected format
  //   }
  // }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        {medicines.length === 0 && <Modal.Title>Upload Document</Modal.Title>}
      </Modal.Header>
      <Modal.Body>
        {/* <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" 
          style={{ background: 'linear-gradient(to right, #1C1B1B, #5B5B5B)', border: 'none' }}>
            {interactionDetailType !== ""
              ? interactionDetailType
              : "Select Document type"}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {interactionTypes?.map((data) => {
              return (
                <Dropdown.Item
                  onClick={() =>
                    handleOptionSelect(
                      data.interaction_type_name,
                      data.interaction_type_id
                    )
                  }
                >
                  {data.interaction_type_name}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown> */}
        {medicines?.length === 0 ? (
          <div className="mt-4">
            {openCam ? (
              <div>
                {file == null && (
                  <>
                    <Webcam
                      audio={false}
                      ref={cameraRef}
                      screenshotFormat="image/jpeg"
                      videoConstraints={{
                        facingMode: isFrontCamera
                          ? "user"
                          : { exact: "environment" },
                      }}
                    />
                    <button onClick={handleSwitchCamera}>Switch Camera</button>
                  </>
                )}
                {file && <img src={file.name} alt="Captured Image" />}
                <div
                  className="text-center m-auto py-1 px-2 rounded-xl bg-gray-400 w-fit mt-2"
                  onClick={handleCapture}
                >
                  {file == null ? "capture" : "Retake"}
                </div>
              </div>
            ) : (
              <>
                <label className="block mt-4">
                  <span className="text-gray-700">Upload File:</span>
                  <input
                    type="file"
                    className="mt-1 block w-full"
                    onChange={(e) => setFile(Array.from(e.target.files))}
                    multiple={true}
                  />
                </label>
                {/* {file && displayImage()} */}
              </>
            )}
            <button
              className="text-blue-500"
              onClick={() => {
                setOpenCam(!openCam);
                setFile(null);
              }}
            >
              {openCam ? "Or Upload File" : "Or Take Photo"}
            </button>
            {/* <div className="mt-4">
            <label className="block mt-4">
              <span className="text-gray-700">Notes/Prescription:</span>
              <textarea
                style={{
                  fontFamily: " monospace",
                  border: "1px solid gray",
                  borderRadius: "4px",
                }}
                // name="doctorNotes"
                type="textarea"
                className="mt-1 block w-full"
                // id="Notes-text"
                value={doctorUploadNotes}
                onChange={(e) => setDoctorUploadNotes(e.target.value)}
              />
            </label>
          </div> */}
          </div>
        ) : (
          <>
            <div>
              <table border="1">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Dosage</th>
                    <th>Frequency</th>
                    <th>Duration</th>
                    <th>Intake Instructions</th>
                  </tr>
                </thead>
                <tbody>
                  {medicines.map((medicine, index) => (
                    <tr key={index}>
                      <td>{medicine.name}</td>
                      <td>{medicine.dosage || "N/A"}</td>
                      <td>{medicine.frequency}</td>
                      <td>{medicine.duration}</td>
                      <td>{medicine.intake_instructions || "N/A"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {otherMedicines?.length > 0 && (
                <>
                  <h2>Other Medicines</h2>
                  <table border="1">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Dosage</th>
                        <th>Frequency</th>
                        <th>Duration</th>
                        <th>Intake Instructions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {otherMedicines?.map((medicine, index) => (
                        <tr key={index}>
                          <td>{medicine.name}</td>
                          <td>{medicine.dosage || "N/A"}</td>
                          <td>{medicine.frequency}</td>
                          <td>{medicine.duration}</td>
                          <td>{medicine.intake_instructions || "N/A"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="text-white px-4 py-2 rounded"
          style={{ background: "linear-gradient(to right, #1C1B1B, #5B5B5B)" }}
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          className="text-white px-4 py-2 rounded"
          style={{ background: "linear-gradient(to right, #1C1B1B, #5B5B5B)" }}
          onClick={handleSubmitDocs}
        >
          {loading ? <Spinner animation="border" size="sm" /> : "Upload"}
        </button>
      </Modal.Footer>
      {/* {showUploadStatusPopup && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
          <div
            className="p-6 rounded shadow-md border-1"
            style={{
              backgroundColor: "#fff",
              borderColor: "#5b5b5b",
            }}
          >
            <p>{uploadStatusMessage}</p>
            <div className="flex justify-center">
              <button
                className="text-white px-4 py-2 center"
                style={{
                  backgroundColor: "#1c1b1b",
                }}
                onClick={handleUploadStatusPopup}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )} */}
    </Modal>
  );
};
export default UploadFiles;
