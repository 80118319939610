import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { configJson } from "../../Constent/config";
import { AddNewDoctor } from "./Doctorstyle";
import { useForm } from "react-hook-form";
import { Form, Button, Alert, Row, Col } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { AdddoctorForm } from "../Login-page/LoginStyle";

const AddSpecModal = ({ show, handleClose, handleFetch, token }) => {
  const [decode, setDecode] = useState({});
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedSpec, setSelectedSpec] = useState("");
  const [organization_id, setOrg] = useState();
  const [specialities, setSpecialities] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
      const token = sessionStorage.getItem("orgtoken");
      if (token) {
        try {
          const decoded = jwtDecode(token);
          const organizationId = decoded.organization_id;
          setOrg(organizationId)
          console.log(0,organizationId)
        } catch (error) {
          setErrorMessage("Invalid token. Please login again.");
        }
      } else {
        setErrorMessage("No token found. Please login again.");
      }
  }, []);
  const fetchSpecialities = async () => {
    try {
      const response = await fetch(`${configJson.backend_URL}/specialties/`);
      const data = await response.json();
      setSpecialities(data);
    } catch (error) {
      console.error("Error fetching specialities:", error);
    }
  };

  const handleSpecialityChange = (e) => {
    const selected = e.target.value;
    const filteredData = specialities.filter(item => item.speciality_id == selected);
    setSelectedSpec(filteredData[0]?.specialty_name || ""); // Ensure we set the name correctly
    setValue("specialty_id", selected);
  };

  const handleNameChange = (e) => {
    setSelectedSpec(e.target.value); // Allow user to edit the specialty name
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      // Combine the specialty_id and the modified specialty_name
      const formData = {
        organization_id: organization_id,
        speciality_id: data.specialty_id,
        speciality_name: selectedSpec, // Add edited specialty name
        is_active: 1
      };

      console.log("Form Data to Submit:", formData);
      
      // Make your API request here with formData, for example:
      const response = await fetch(`${configJson.backend_URL}/specialtysetup/create`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      const newUser = await response.json();
      
      if (response.ok) {
        reset();
        handleFetch(newUser); // Send new user data to UserList
        handleClose(); // Close the modal
      } else {
        setErrorMessage(newUser.message || "An unexpected error occurred.");
      }
    } catch (error) {
      setErrorMessage("An unexpected error occurred: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      setDecode(decoded);
      fetchSpecialities();
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <Modal
      className="Memberaddition"
      show={show}
      onHide={handleClose}
      style={{ position: "absolute", left: "0%", top: "17%" }}
      backdrop="static"
    >
      <AddNewDoctor>
        <div>
          <Modal.Header closeButton style={{ alignItems: "center" }}>
            <div className="head-text-container">
              <h2 className="head-text">Add Specialty</h2>
            </div>
          </Modal.Header>
          <Modal.Body className="Modalmember">
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <AdddoctorForm>
              <Form onSubmit={handleSubmit(onSubmit)}>
                {/* Specialty Dropdown */}
                <Form.Group as={Row} controlId="formSpeciality">
                  <Form.Label column sm={4}>
                    Specialty <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Col className="mb-3" sm={8}>
                    <Form.Select
                      {...register("specialty_id", {
                        required: "Specialty is required",
                      })}
                      onChange={handleSpecialityChange}
                    >
                      <option value="">Select Specialty</option>
                      {specialities.map((speciality) => (
                        <option
                          key={speciality.speciality_id}
                          value={speciality.speciality_id}
                        >
                          {speciality.specialty_name}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.specialty_id && (
                      <Form.Text className="text-danger">
                        {errors.specialty_id.message}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>

                {/* Editable Speciality Name Field */}
                <Form.Group as={Row} controlId="formSpecId">
                  <Form.Label column sm={4}>
                    Speciality Name <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Col className="mb-3" sm={8}>
                    <Form.Control
                      type="text"
                      value={selectedSpec || ""}
                      onChange={handleNameChange} // Handle user input change
                      placeholder="Enter Specialty Name"
                    />
                  </Col>
                </Form.Group>

                {/* Submit Button with Loading State */}
                <Button
                  className="submitmem"
                  variant="primary"
                  type="submit"
                  style={{ marginTop: "10px" }}
                  disabled={loading} // Disable button when loading
                >
                  {loading ? "Submitting..." : "Submit"}
                </Button>
              </Form>
            </AdddoctorForm>
          </Modal.Body>
        </div>
      </AddNewDoctor>
    </Modal>
  );
};

export default AddSpecModal;