import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import settingsReducer from './slices/settingsSlice';
import patientsReducer from './slices/patientsSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        settings: settingsReducer,
        patients: patientsReducer,
    },
});

export default store;