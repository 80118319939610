import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { DateTime } from "luxon";

const imgRef = React.createRef();

export const formatDate = (dateString) => {
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }; // Format: 24 Apr, 2023
  return new Date(dateString).toLocaleDateString("en-US", options); // Use 'en-GB' for this format
};

export const renderMedia = (url, openFullScreen) => {
  if (!url) {
    return;
  }

  // console.log('urlll', url)
  const extension = url.split(".").pop().toLowerCase();

  if (extension === "mp3" || extension === "wav") {
    return (
      <audio controls className="w-full !relative !z-[1]">
        <source src={url} type={`audio/${extension}`} />
        Your browser does not support the audio element.
      </audio>
    );
  } else if (["jpg", "jpeg", "png", "gif", "avif"].includes(extension)) {
    return (
      <img
        src={url}
        alt=""
        width={250}
        className="m-auto"
        onClick={openFullScreen}
      />
    );
  } else if (
    extension === "mp4" ||
    extension === "webm" ||
    extension === "ogg"
  ) {
    return (
      <video controls className="w-full !relative !z-[1]">
        <source src={url} type={`video/${extension}`} />
        Your browser does not support the video element.
      </video>
    );
  } else if (extension === "pdf") {
    return <PdfViewer url={url} />;
  } else if (extension === "txt") {
    return (
      <p>
        <ReactMarkdown>{url}</ReactMarkdown>
      </p>
    );
  } else {
    return (
      <p>
        <ReactMarkdown>{url}</ReactMarkdown>
      </p>
    );
  }
};

const timeZone = sessionStorage.getItem("timeZone");
// console.log(timeZone)

export function convertToKolkataTime(utcDateTime) {
  return DateTime.fromISO(utcDateTime, { zone: "utc" })
    .setZone(timeZone)
    .toFormat("yyyy-MM-dd, HH:mm:ss");
}
const PdfViewer = ({ url }) => {
  const [showIframe, setShowIframe] = useState(false);
  const getFileName = (url) => {
    return url.split("/").pop(); // Gets the last segment after the last '/'
  };

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      {/* Display the URL */}
      <p
        style={{
          marginBottom: "10px",
          color: "black",
          wordBreak: "break-word",
        }}
      >
        <strong>PDF URL:</strong>
        <a
          style={{ color: "black" }}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {getFileName(url)}
        </a>
      </p>

      {/* Always display the PDF */}
      <iframe
        src={`${url}#toolbar=0&navpanes=0&scrollbar=0`}
        // width="100%"
        // height="1000px"
        title="PDF Viewer"
        style={{
          maxWidth: "794px", // Restrict to A4 width
          width: "100%", // Responsive
          height: '300px',
          border: "none", // Remove border
          display: "block",
          margin: "0 auto", // Center horizontally
          backgroundColor: "white", // Set iframe background
        }}
      ></iframe>
    </div>
  );
};

export function convertToUTC() {
  // Get the current date and time in Asia/Kolkata
  const kolkataDateTime = DateTime.now().setZone(timeZone);

  // Convert to UTC and return in ISO format
  return kolkataDateTime.setZone("utc").toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
}

export function convertToKolkataTimeFormat(utcDateTime) {
  return DateTime.fromISO(utcDateTime, { zone: "utc" })
    .setZone(timeZone)
    .toFormat("MMM dd, yyyy, h:mm a");
}
export function convertToKolkataTimeDOB(utcDateTime) {
  return DateTime.fromISO(utcDateTime, { zone: "utc" })
    .setZone(timeZone)
    .toFormat("dd-MM-yyyy");
}
export function searchDateField(utcDateTime) {
  return DateTime.fromISO(utcDateTime, { zone: "utc" })
    .setZone(timeZone)
    .toFormat("dd-MM-yyyy");
}
