import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { FaMicrophone } from "react-icons/fa6";
import MicRecorder from "mic-recorder-to-mp3";
import { configJson } from "../../../Constent/config";
import { FaStop, FaPause, FaPlay } from "react-icons/fa";
import { useStopwatch } from "react-timer-hook";
import { TimeCounterContainer } from "../../ThemeStyle/RegisterStyle";
import { EngineContainer } from "./MainEngineStyle";
import CustomAudioPlayer from "./Components/CustomAudioPlayer";
import Sidebar from "../../Home/Components/Sidebar/Sidebar";
import MessageModel from "./Components/MessageModel";
import axios from "axios";
// import AudioAnalyser from "react-audio-analyser";
// import FollowUpModel from "./Components/FollowUpModel";
import { convertToKolkataTime, convertToUTC } from "../../../helpers";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import {
  addPatientToTop,
  updatePatient,
} from "../../../redux/slices/patientsSlice";
import { toast } from "react-toastify";

const Mp3Recorder = new MicRecorder({ bitRate: 129 });

const ConsentRequestForm = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    patientFirstName: state?.patientFirstName || "",
    patientLastName: state?.patientLastName || "",
    doctorFirstName: state?.doctorFirstName || "",
    doctorLastName: state?.doctorLastName || "",
    patient_id: state?.patient_id || "",
    doctor_id: state?.doctor_id || "",
    timestamp: state?.timestamp || "",
    timestamputc: state?.timestamp || "",
    organization_id: state?.organization_id || "",
    doctorlastName: state?.doctorlastName || "",
    // language: state?.language || "",
  });

  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileupload, setFileupload] = useState(false);
  const [audioDuration, setAudioDuration] = useState(null);
  const [recordtime, setRecordtime] = useState(0);
  const [recordtimeminutes, setRecordtimeminutes] = useState(0);
  const [stepNumber, setStepNumber] = useState(1);
  const [blobUrl, setBlobUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const [decode, setDecode] = useState({});
  // const [wait, setWait] = useState(0);
  // const [showModalFollowup, setsHowModalFollowup] = useState(false);

  const { seconds, minutes, hours, start, pause, reset } = useStopwatch({
    autoStart: false,
  });

  // //wav record
  // const [status, setStatus] = useState("");
  // const [audioSrc, setAudioSrc] = useState(null);
  // const [audioType, setAudioType] = useState("audio/mp3");

  // const controlAudio = (status) => {
  //   setStatus(status);
  // };

  // // const changeScheme = (e) => {
  // //   setAudioType(e.target.value);
  // // };

  // useEffect(() => {
  //   setAudioType("audio/mp3");
  // }, []);

  // const audioProps = {
  //   audioType,
  //   status,
  //   audioSrc,
  //   timeslice: 1000, // timeslice（https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/start#Parameters）
  //   backgroundColor: "rgb(105, 117, 101)",
  //   strokeColor: "#ECDFCC",
  //   width: "300",
  //   // startCallback: (e) => {
  //   //   // console.log("succ start", e);
  //   // },
  //   // pauseCallback: (e) => {
  //   //   // console.log("succ pause", e);
  //   // },
  //   stopCallback: (e) => {
  //     const blob = window.URL.createObjectURL(e);
  //     setAudioSrc(blob);
  //     // console.log("succ stop", e);
  //     setBlobUrl(blob);
  //     setIsRecording(false);
  //     setRecordtime(seconds);
  //     setRecordtimeminutes(minutes);
  //     reset();
  //     pause();
  //     setStepNumber(3);
  //     setsHowModalFollowup(true);
  //   },
  //   // onRecordCallback: (e) => {
  //   //   // console.log("recording", e);
  //   // },
  //   errorCallback: (err) => {
  //     console.log("error", err);
  //   },
  // };

  //set Today Date
  useEffect(() => {
    // const timestamp = Date.now();
    // const now = new Date(timestamp);
    // const formattedDate = now.toLocaleString("en-US", {
    //   month: "2-digit",
    //   day: "2-digit",
    //   year: "2-digit",
    //   hour: "2-digit",
    //   minute: "2-digit",
    //   hour12: false,
    // });

    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   timestamp: formattedDate,
    // }));
    const formattedDate = convertToUTC();
    const d = convertToKolkataTime(formattedDate);
    console.log(d);
    setFormData((prevFormData) => ({
      ...prevFormData,
      timestamp: d,
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      timestamputc: formattedDate,
    }));
    const token = sessionStorage.getItem("orgtoken");

    if (!token) {
      navigate("/login");
    } else {
      const decoded = jwtDecode(token);
      setDecode(decoded);
    }
  }, [state.patient_id, setFormData]);

  //upload audio
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith("audio/")) {
        alert("Please upload a valid audio file.");
        return;
      }
      const audio = new Audio();
      const validExtensions = ["audio/mpeg", "audio/wav", "audio/mp3"];
      if (!validExtensions.includes(file.type)) {
        alert("Please upload a valid audio file (MP3, WAV).");
        return;
      }

      const bloburl = URL.createObjectURL(file);
      setBlobUrl(bloburl);
      setUploadedFile(file);
      setFileupload(true);
      setStepNumber(3);
      audio.src = bloburl;
      audio.addEventListener("loadedmetadata", () => {
        let d = Math.floor(audio.duration);
        setAudioDuration(d);
        URL.revokeObjectURL(bloburl); // Free up memory
      });
    }
  };
  // console.log(state.interaction_id);
  // console.log(24545,state.doctor_id);
  async function updatedata(url) {
    const mydata = {
      interaction_id: state.interaction_id,
      doctor_id: state.doctor_id,
      interaction_status: 0,
      interaction_notes: "soap",
      interaction_date: formData.timestamputc,
      interaction_detail_type: "Audio url",
      audioUrl: url,
      interaction_type: "false",
      audioDuration: audioDuration,
    };
    try {
      const response = await axios.put(
        `${configJson.backend_URL}/interaction/updatedata`,
        mydata,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (response.data.status === true) {
        console.log("Audio upload success full");
        dispatch(addPatientToTop(response?.data?.result));
        checkInteractionStatus(response?.data?.result?.interaction_id);
       
        if (decode.organization_id === 10) {
          navigate("/doctor_consent");
        } else {
          console.log(response);
          navigate("/token");
        }
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  }

  //Check Status
  const checkInteractionStatus = async (interaction_id) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      };
      const response = await fetch(
        `${configJson.backend_URL}/interaction/interactions/status/${interaction_id}`,
        requestOptions
      );
      if (!response.ok) {
        toast.error("refersh the page");
      }
      const data = await response.json();
      if (data.status_ok === true) {
        console.log("Data object:", data);
        const changeStatus = {
          interaction_id: data?.interaction?.interaction_id,
          interaction_status: data?.interaction?.interaction_status,
        };
        console.log(changeStatus)
        dispatch(updatePatient(changeStatus));
        navigate(`/activity?id=${data?.interaction?.patient_id}`);
      } else if (data.status_ok === false) {
        setTimeout(() => startPolling({ interaction_id }), 10000);
      }
    } catch (error) {
      console.error("Error checking button status:", error);
    }
  };
  //check 10s ones
  function startPolling({ interaction_id }) {
    checkInteractionStatus(interaction_id);
  }

  //End session call
  const handleSubmitAudio = async (blobUrl, recordtime, recordtimeminutes) => {
    setIsLoading(true);

    if (!blobUrl) {
      setMessage("Please record audio or upload a file.");
      setShowModal(true);
      setIsLoading(false);
      return;
    }
    if (!fileupload) {
      if (recordtimeminutes <= 0 && recordtime <= 25) {
        setMessage("Minimum recording is 25 seconds");
        setShowModal(true);
        setIsLoading(false);
        setStepNumber(1);
        reset();
        pause();
        // setWait(0);
        return;
      }
    } else {
      if (audioDuration <= 25) {
        setMessage("Minimum upload audio is 25 seconds");
        setShowModal(true);
        setIsLoading(false);
        setStepNumber(1);
        // setWait(0);
        return;
      }
    }

    const audioBlob = uploadedFile
      ? uploadedFile
      : await fetch(blobUrl).then((res) => res.blob());

    let result = formData.timestamp.replace(/[-:]/g, "_").replace(", ", "_");
    // console.log(result);
    const formData2 = new FormData();
    formData2.append(
      "file_name",
      `audio_files/${formData.patient_id}_${result}.mp3`
    );
    formData2.append("audio_file", audioBlob, `${formData.patient_id}.mp3`);

    try {
      // const response = await fetch("https://back.denotes.app/upload_audio", {
      //check connectivity
      const response = await fetch(
        `${configJson.backend_URL}/soapPrompt/upload`,
        {
          method: "POST",
          body: formData2,
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Audio stored in bucket");
        const url = result.gcs_uri;
        await updatedata(url);
      } else {
        let errorMessage = "Failed to submit form data.";
        if (response.status === 400) {
          errorMessage = "Bad request. Please check your inputs.";
        } else if (response.status === 404) {
          errorMessage = "API endpoint not found.";
        }
        alert(errorMessage);
      }
    } catch (error) {
      console.error("Error:", error);
      alert(`An error occurred while submitting the form.${error}`);
    } finally {
      setIsLoading(true);
    }
  };
  //Api Call for Audio upload backend
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!blobUrl) {
      setMessage("Please record audio or upload a file.");
      setShowModal(true);
      setIsLoading(false);
      return;
    }
    if (!fileupload) {
      if (recordtimeminutes <= 0 && recordtime <= 25) {
        setMessage("Minimum recording is 25 seconds");
        setShowModal(true);
        setIsLoading(false);
        setStepNumber(1);
        reset();
        pause();
        // setWait(0);
        return;
      }
    } else {
      if (audioDuration <= 25) {
        setMessage("Minimum upload audio is 25 seconds");
        setShowModal(true);
        setIsLoading(false);
        setStepNumber(1);
        // setWait(0);
        return;
      }
    }

    const audioBlob = uploadedFile
      ? uploadedFile
      : await fetch(blobUrl).then((res) => res.blob());

    let result = formData.timestamp.replace(/[-:]/g, "_").replace(", ", "_");
    // console.log(result);
    const formData2 = new FormData();
    formData2.append(
      "file_name",
      `audio_files/${formData.patient_id}_${result}.mp3`
    );
    formData2.append("audio_file", audioBlob, `${formData.patient_id}.mp3`);

    try {
      // const response = await fetch("https://back.denotes.app/upload_audio", {
      //check connectivity
      const response = await fetch(
        `${configJson.backend_URL}/soapPrompt/upload`,
        {
          method: "POST",
          body: formData2,
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Audio stored in bucket");
        const url = result.gcs_uri;
        await updatedata(url);
      } else {
        let errorMessage = "Failed to submit form data.";
        if (response.status === 400) {
          errorMessage = "Bad request. Please check your inputs.";
        } else if (response.status === 404) {
          errorMessage = "API endpoint not found.";
        }
        alert(errorMessage);
      }
    } catch (error) {
      console.error("Error:", error);
      alert(`An error occurred while submitting the form.${error}`);
    } finally {
      setIsLoading(true);
    }
  };
  //audio-analyser
  // //start Audio recording
  // const startRecording = () => {
  //   controlAudio("recording");
  //   setIsRecording(true);
  //   setFileupload(false);
  //   setRecordtime(0);
  //   setRecordtimeminutes(0);
  //   start();
  //   setStepNumber(2);
  // };
  // //stop Audio recording
  // const stopRecording = () => {
  //   controlAudio("inactive");
  // };
  // //pause Audio recording
  // const pauseRecording = () => {
  //   controlAudio("paused");
  //   pause();
  // };
  // //resume Audio recording
  // const resumeRecording = () => {
  //   controlAudio("recording");
  //   start();
  // };

  //close Follow Model
  // const handleCloseFollowup = () => {
  //   setsHowModalFollowup(false);
  // };
  //handle follow status
  // const handleStatus = (value) => {
  //   setWait(value);
  // };
  //mic-recorder-to-mp3
  //start Audio recording
  const startRecording = () => {
    Mp3Recorder.start()
      .then(() => {
        setIsRecording(true);
        setFileupload(false);
        setRecordtime(0);
        setRecordtimeminutes(0);
        start();
        setStepNumber(2);
      })
      .catch((e) => console.error(e));
  };

  //stop Audio recording
  const stopRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        setBlobUrl(blobURL);
        setIsRecording(false);
        setRecordtime(seconds);
        setRecordtimeminutes(minutes);
        setStepNumber(3);
        handleSubmitAudio(blobURL, seconds, minutes);
        reset();
        pause();

        // setsHowModalFollowup(true);
      })
      .catch((e) => console.log(e));
  };

  //show or close model
  const setShowModals = (data) => {
    setShowModal(false);
  };

  //close Model 2s
  useEffect(() => {
    if (showModal === true) {
      setTimeout(() => {
        setShowModal(false);
      }, 2000);
    }
  }, [showModal]);

  return (
    // <Sidebar setuser={true}>
    <EngineContainer>
      <div className="header-name-container">
        <p className="name-tag">
          <span className="name-text">Patient Name :</span>
          <span className="name-name">
            {" "}
            {formData.patientFirstName.charAt(0).toUpperCase() +
              formData.patientFirstName.slice(1).toLowerCase() +
              " " +
              formData.patientLastName.charAt(0).toUpperCase() +
              formData.patientLastName.slice(1).toLowerCase()}
          </span>
        </p>
        {formData.doctorFirstName === "" ? (
          ""
        ) : (
          <p className="name-tag">
            <span className="name-text">Doctor Name :</span>
            <span className="name-name">
              {formData.doctorFirstName.charAt(0).toUpperCase() +
                formData.doctorFirstName.slice(1).toLowerCase() +
                " " +
                formData.doctorLastName.charAt(0).toUpperCase() +
                formData.doctorLastName.slice(1).toLowerCase()}
            </span>
          </p>
        )}
        <p className="name-tag">
          <span className="name-text">Date & Time :</span>
          <span className="name-name">{formData.timestamp}</span>
        </p>
      </div>
      <div className="Line-header">
        <hr className="gradient-line" />
        <hr className="black-line" />
      </div>
      {stepNumber === 3 ? (
        <div className="empty"></div>
      ) : (
        ""
        //<div className="recorder-wav-container">  </div>
        // <AudioAnalyser {...audioProps}>
        //   {/* <div className="btn-box">
        //   <button
        //     className="btn"
        //     style={{ backgroundColor: "green", color: "white" }}
        //     onClick={() => controlAudio("recording")}
        //   >
        //     Start
        //   </button>
        //   <button className="btn" onClick={() => controlAudio("paused")}>
        //     Pause
        //   </button>
        //   <button className="btn" onClick={() => controlAudio("inactive")}>
        //     Stop
        //   </button>
        //   <button
        //     className="btn"
        //     onClick={() => console.log(AudioAnalyser)}
        //   >
        //     Log
        //   </button>
        // </div> */}
        // </AudioAnalyser>
        //   <div className="select-audio-type">
        //   <p>Choose output type</p>
        //   <select onChange={changeScheme} value={audioType}>
        //     <option value="audio/webm">audio/webm (default)</option>
        //     <option value="audio/wav">audio/wav</option>
        //     <option value="audio/mp3">audio/mp3</option>
        //   </select>
        // </div>
      )}
      <div className="top-record">
        <div className="recording-container">
          {stepNumber === 3 ? (
            ""
          ) : (
            <TimeCounterContainer>
              <div className="time-cards">
                <span className="time-font">
                  {hours > 9 ? "" : <span>0</span>}
                  {hours}:
                </span>
                <span className="time-font">
                  {minutes > 9 ? "" : <span>0</span>}
                  {minutes}:
                </span>
                <span className="time-font">
                  {seconds > 9 ? "" : <span>0</span>}
                  {seconds}
                </span>
              </div>
            </TimeCounterContainer>
          )}
          {stepNumber === 3 ? (
            <>
              {blobUrl && (
                <div className="mb-1 audio-wave-out">
                  <audio src={blobUrl} controls className="w-100" />
                  <CustomAudioPlayer audioSrc={blobUrl} />
                </div>
              )}
            </>
          ) : (
            <div className="record-group-button">
              <Button
                type="button"
                className="start-stop-btn"
                onClick={isRecording ? stopRecording : startRecording}
              >
                <div className="button-content">
                  <span className="rec-btn-icon">
                    {isRecording ? <FaStop /> : <FaMicrophone />}
                  </span>
                  <span className="rec-btn-text">
                    {isRecording ? "End session" : "Record session"}
                  </span>
                </div>
              </Button>
              {/* <Button
                  type="button"
                  className="start-stop-btn"
                  disabled={status === "" || status === "inactive"}
                  onClick={
                    status === "recording" ? pauseRecording : resumeRecording
                  }
                >
                  <div className="button-content">
                    <span className="rec-btn-icon">
                      {status === "recording" ? <FaPause /> : <FaPlay />}
                    </span>
                    <span className="rec-btn-text">
                      {status === "recording"
                        ? "Pause session"
                        : "resume session"}
                    </span>
                  </div>
                </Button> */}
            </div>
          )}
        </div>
        {stepNumber === 3 ? (
          ""
        ) : (
          <div className="or-divider">
            <div className="divide-line">
              <hr></hr>
            </div>
            <p>or</p>
            <div className="divide-line">
              <hr></hr>
            </div>
          </div>
        )}
        <Form onSubmit={handleSubmit} className="my-from">
          {stepNumber === 3 ? (
            ""
          ) : (
            <Form.Group className="mb-3 d-flex">
              <Form.Label htmlFor="audioUpload" className="text-primary mr-1">
                Upload
              </Form.Label>
              <p
                className="mb-2"
                style={{
                  fontFamily: "Arial, sans-serif",
                  fontSize: "16px",
                  marginLeft: "10px",
                }}
              >
                Audio File if Already Recorded.
              </p>
              <Form.Control
                id="audioUpload"
                type="file"
                accept="audio/*"
                onChange={handleFileInputChange}
                style={{ display: "none" }}
              />
            </Form.Group>
          )}
          {stepNumber === 3 ? (
            <div className="text-center">
              {isLoading ? (
                <Button className="eng-sending" disabled>
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Processing...
                </Button>
              ) : (
                <div>
                  <Button type="submit" className="eng-sub">
                    Submit
                  </Button>
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </Form>
        {/* {showModalFollowup ? (
            <FollowUpModel
              show={showModalFollowup}
              handleClose={handleCloseFollowup}
              setStatusdData={handleStatus}
            />
          ) : (
            ""
          )} */}
      </div>
      <MessageModel
        showModal={showModal}
        messageData={message}
        setShowModal={setShowModals}
      />
    </EngineContainer>
    // </Sidebar>
  );
};

export default ConsentRequestForm;
