import React from "react";
import { Table, Button } from "react-bootstrap";

const TableTemplateSelection = ({
  prescriptionDataTemplate,
  handleTemplateUpdateRow ,
  handleTemplateDeleteRow ,
  handleAddRowTemplate,
}) => {
  return (
    <Table bordered responsive className="medicinetable">
      <thead>
        <tr>
          <th
            style={{
              textAlign: "center",
              fontSize: "16px",
              fontFamily: "Calibri",
              padding: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <span style={{ width: "100%", textAlign: "center" }}>
                Medicine Name
              </span>
            </div>
          </th>
          <th
            style={{
              width: "10%",
              textAlign: "center",
              fontSize: "16px",
              fontFamily: "Calibri",
              padding: "5px",
            }}
          >
            FN
          </th>
          <th
            style={{
              width: "10%",
              textAlign: "center",
              fontSize: "16px",
              fontFamily: "Calibri",
              padding: "5px",
            }}
          >
            AN
          </th>
          <th
            style={{
              width: "10%",
              textAlign: "center",
              fontSize: "16px",
              fontFamily: "Calibri",
              padding: "5px",
            }}
          >
            N
          </th>
          <th
            style={{
              width: "10%",
              textAlign: "center",
              fontSize: "16px",
              fontFamily: "Calibri",
              padding: "5px",
            }}
          >
            Days
          </th>
          <th
            className="printlastnonecol"
            style={{
              width: "20%",
              textAlign: "center",
              fontSize: "16px",
              fontFamily: "Calibri",
              padding: "5px",
            }}
          >
            Instructions
          </th>
          <th
            className="printDisable"
            style={{
              width: "10%",
              textAlign: "center",
              fontSize: "16px",
              fontFamily: "Calibri",
              padding: "5px",
            }}
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {prescriptionDataTemplate.map((row) => (
          <tr key={row.id}>
            <td style={{ width: "30%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <input
                  type="text"
                  placeholder="Medication Name"
                  value={row.medication_name}
                  onChange={(e) =>
                    handleTemplateUpdateRow (row.id, "medication_name", e.target.value)
                  }
                  style={{
                    width: "65%",
                    textAlign: "center",
                    fontSize: "16px",
                    fontFamily: "Calibri",
                    padding: "5px",
                  }}
                />
                <input
                  type="text"
                  placeholder="Dosage"
                  value={row.dosage}
                  onChange={(e) =>
                    handleTemplateUpdateRow (row.id, "dosage", e.target.value)
                  }
                  style={{
                    width: "21%",
                    textAlign: "center",
                    fontSize: "16px",
                    fontFamily: "Calibri",
                    padding: "5px",
                  }}
                />
              </div>
            </td>
            <td style={{ width: "10%" }}>
              <input
                type="number"
                value={row.frequency.morning}
                onChange={(e) =>
                  handleTemplateUpdateRow (row.id, "frequency", {
                    ...row.frequency,
                    morning: parseFloat(e.target.value) || 0,
                  })
                }
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "16px",
                  fontFamily: "Calibri",
                  padding: "5px",
                }}
                min="0"
              />
            </td>
            <td style={{ width: "10%" }}>
              <input
                type="number"
                value={row.frequency.afternoon}
                onChange={(e) =>
                  handleTemplateUpdateRow (row.id, "frequency", {
                    ...row.frequency,
                    afternoon: parseFloat(e.target.value) || 0,
                  })
                }
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "16px",
                  fontFamily: "Calibri",
                  padding: "5px",
                }}
                min="0"
              />
            </td>
            <td style={{ width: "10%" }}>
              <input
                type="number"
                value={row.frequency.night}
                onChange={(e) =>
                  handleTemplateUpdateRow (row.id, "frequency", {
                    ...row.frequency,
                    night: parseFloat(e.target.value) || 0,
                  })
                }
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "16px",
                  fontFamily: "Calibri",
                  padding: "5px",
                }}
                min="0"
              />
            </td>
            <td style={{ width: "10%" }}>
              <input
                type="text"
                value={row.duration}
                onChange={(e) => handleTemplateUpdateRow (row.id, "duration", e.target.value)}
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "16px",
                  fontFamily: "Calibri",
                  padding: "5px",
                }}
              />
            </td>
            <td className="printlastnonecol" style={{ width: "20%" }}>
              <input
                type="text"
                value={row.instructions}
                onChange={(e) =>
                  handleTemplateUpdateRow (row.id, "instructions", e.target.value)
                }
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "16px",
                  fontFamily: "Calibri",
                  padding: "5px",
                }}
              />
            </td>
            <td style={{ width: "10%" }}>
              <Button
                className="printDisable"
                variant="danger"
                onClick={() => handleTemplateDeleteRow (row.id)}
                style={{
                  fontSize: "16px",
                  fontFamily: "Calibri",
                  padding: "5px",
                }}
              >
                Delete
              </Button>
            </td>
          </tr>
        ))}
        <tr>
          <td colSpan="6" style={{ textAlign: "center" }}>
            <Button
              className="printDisable"
              variant="success"
              onClick={handleAddRowTemplate}
              style={{
                fontSize: "16px",
                fontFamily: "Calibri",
                padding: "5px 20px",
              }}
            >
              Add Row
            </Button>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default TableTemplateSelection;
