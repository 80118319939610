import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { configJson } from "../../../Constent/config";
import { Mybtn } from "../../Engine-Page/mainEngine/MainEngineStyle";

const SpecialityDropdownForm = ({
  specialities,
  selectedspcialite,
  id,
  users,
  setUsers,
  handleSpecialityClose,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading,setIsLoading]=useState(false)
  console.log(specialities);
  const onSubmit = async (data) => {
    console.log("Selected Speciality Data:", data);

    if (!data.speciality_id || !id) {
      toast("Please provide both Interaction ID and Speciality ID.");
      return;
    }
    if(parseInt(data.speciality_id)===selectedspcialite){
      handleSpecialityClose(false);
      return;
    }
    setIsLoading(true)
    try {
      const response = await fetch(
        `${configJson.backend_URL}/interaction/Speciality/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ speciality_id: data.speciality_id }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);
        toast.success(responseData.message);
        const ids = responseData.interaction.speciality_id;
        console.log("ids", ids);
        // Find the speciality data by speciality_id
        const update = specialities.filter(
          (value) => value.speciality_id === parseInt(ids)
        );
        console.log("update", update);
        // Find the interaction by interaction_id
        const interactionToUpdate = users.find(
          (interaction) => interaction.interaction_id === id
        );

        if (interactionToUpdate && update) {
          // Update the speciality fields if the interaction is found
          interactionToUpdate.speciality.speciality_name =
            update[0].speciality_name;
          interactionToUpdate.speciality.speciality_id = data.speciality_id;

          // Update the users state with the modified interaction
          const updatedUsers = users.map((interaction) =>
            interaction.interaction_id === id
              ? interactionToUpdate
              : interaction
          );
          console.log("updatedUsers", updatedUsers);
          setUsers(updatedUsers); // Update the users state with the updated interaction
          handleSpecialityClose(false);
        }
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || "Failed to update speciality.");
      }
    } catch (error) {
      console.error("Error updating speciality:", error);
      toast.error("Error updating speciality.");
    }finally{
      setIsLoading(false)
    }
  };

  console.log(id);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="specialitySelect">
        <Form.Label>Select Speciality</Form.Label>
        <Form.Control
          as="select"
          {...register("speciality_id", { required: "Speciality is required" })}
          defaultValue={selectedspcialite || ""}
        >
          <option value="">Select an option</option>
          {specialities.map((speciality) => (
            <option
              key={speciality.speciality_id}
              value={speciality.speciality_id}
            >
              {speciality.speciality_name}
            </option>
          ))}
        </Form.Control>
        {errors.speciality_id && (
          <Form.Text className="text-danger">
            {errors.speciality_id.message}
          </Form.Text>
        )}
      </Form.Group>
      <Mybtn>
        {/* {!isLoading} */}
        <Button type="submit" className="new-style-btn">
          Submit
        </Button>
      </Mybtn>
    </Form>
  );
};

export default SpecialityDropdownForm;
