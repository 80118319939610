import styled from "styled-components";

export const ConsentContainer = styled.div`
  .header-text-part {
    text-align: center;
    .header-text {
      background-image: linear-gradient(to right, #c81d77, #6710c2);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-size: 30px;
    }
  }
  .qr-image-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 38px;
  }

  .btn-log {
    text-align: center;
    .reg-sub-btn {
      color: #fff;
      background-image: linear-gradient(to right, #ba1a81, #7412b7);
      padding: 5px 20px 5px 20px;
    }
  }
`;
