import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { DateTime } from "luxon";
import {
  InteractionPatientContainer,
  SideInteractionPatientContainer,
  TokenNumberContainer,
} from "./SidebarStyle";
import {
  addPatientToList,
  clearSearchResults,
  fetchPatientsByDate,
  searchPatients,
} from "../../../../redux/slices/patientsSlice";

function InteractionPatientList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const decode = useSelector((state) => state.auth.decodedToken);
  const { patientList, searchResults, loading } = useSelector(
    (state) => state.patients
  );

  const [query, setQuery] = useState("");
  const [currentDate, setCurrentDate] = useState(DateTime.now());

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setCurrentDate(DateTime.fromISO(selectedDate));
    dispatch(
      fetchPatientsByDate({ doctorId: decode.doctor_id, date: selectedDate })
    );
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setQuery(value);
    if (value) {
      dispatch(
        searchPatients({ organizationId: decode.organization_id, query: value })
      );
    }
  };

  const handlePatientClick = (patient) => {
    dispatch(addPatientToList(patient));
    navigate(`/activity?id=${patient.Patient.patient_id}`);
    dispatch(clearSearchResults());
  };

  const loadPreviousDayData = () => {
    const previousDate = currentDate.minus({ days: 1 });
    setCurrentDate(previousDate);
    dispatch(
      fetchPatientsByDate({
        doctorId: decode?.doctor_id,
        date: previousDate.toISODate(),
      })
    );
  };

  useEffect(() => {
    dispatch(
      fetchPatientsByDate({
        doctorId: decode?.doctor_id,
        date: currentDate.toISODate(),
      })
    );
  }, [dispatch, decode?.doctor_id, currentDate]);

  return (
    <>
      <input
        type="text"
        placeholder="Enter patient name"
        value={query}
        onChange={handleSearch}
        style={{
          width: "100%",
          padding: "10px",
          marginBottom: "10px",
          backgroundColor: "rgb(187 187 187 / 30%)",
        }}
      />
      <input
        type="date"
        value={currentDate.toISODate()}
        onChange={handleDateChange}
        className="form-control mb-3"
      />
      {loading && <p>Loading...</p>}
      <ul style={{ position: "absolute", background: "white", width: "100%" }}>
        {searchResults.map((patient) => (
          <li
            key={patient.Patient.patient_id}
            type="button"
            onClick={() => handlePatientClick(patient)}
          >
            {patient.Patient.first_name} {patient.Patient.last_name}
          </li>
        ))}
      </ul>
      {patientList.length > 0 ? (
        <SideInteractionPatientContainer>
          {patientList.map((patient) => (
            <div
              key={patient.Patient.patient_id}
              className={`flex items-center justify-between p-2 cursor-pointer shadow-md text-sm ${
                location.pathname ===
                `activity?id=${patient.Patient.patient_id}`
                  ? "!bg-[#D9D9D9]"
                  : ""
              } ${
                patient?.interaction_status === "1"
                  ? "bg-green-500"
                  : "bg-red-600"
              }`}
              onClick={() =>
                navigate(`/activity?id=${patient.Patient.patient_id}`)
              }
            >
              <div className="flex items-center gap-1">
                <div className="rounded-full bg-[#b3b0b0] w-fit p-1 size-[23px]">
                  <img src={`/user.png`} width={14} alt="Patient avatar" />
                </div>
                <div className="gap-1">
                  {patient?.Patient?.first_name} {patient?.Patient?.last_name}
                </div>
              </div>
              <TokenNumberContainer>
                <span>{patient?.token_number}</span>
              </TokenNumberContainer>
            </div>
          ))}
          <button onClick={loadPreviousDayData} disabled={loading}>
            Post Visit
          </button>
        </SideInteractionPatientContainer>
      ) : (
        <div>
          <p>Today No data</p>
          <button onClick={loadPreviousDayData} disabled={loading}>
            Post Visit
          </button>
        </div>
      )}
    </>
  );
}

export default InteractionPatientList;
