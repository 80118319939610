import styled from "styled-components";

export const TabStyleComp = styled.div`
  .tabsection.nav-tabs .nav-link.active {
    background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    color: #fff !important;
  }
    .searchbaradd{
    gap:20px;
    }
    .cstmtab {
    gap: 20px;
    max-width: 600px;
    margin-right: 0px;
    margin-left: auto;
    }
  .tabsection.nav-tabs .nav-link.active span.d-flex.align-items-center {
    color: #fff !important;
  }
  .tabsection span.d-flex.align-items-center {
    color: #595959;
    font-weight: 500;
  }
    .tabup .addmemberbtn{
color: #fff;
    background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    padding: 5px 20px 5px 20px;
        width: 200px;
    border:none;
    }
    li.page-item.active span.page-link{
        background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    color: #fff !important;
    border:none;
    }
     li.page-item a.page-link{
       color: #595959;
    font-weight: 500;
     }
     @media (max-width: 767px) {
   .d-flex.justify-content-between.align-items-center.my-4.tabup {
    flex-direction: column;
    gap: 10px;
}
    button.editbtnres.btn.btn-primary {
    width: 130px;
    justify-content: center;
    align-items: center;
    display: flex;
}

`;

export const TeamLineStyle=styled.div`
  .heading-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    .gradient-text {
      position: relative;
      background-image: linear-gradient(to right, rgb(89 89 89), rgb(38 37 37));
      -webkit-background-clip: text;
      color: transparent;
    }

    .gradient-text::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px;
      height: 2px;
      width: 100%;
      background-image: linear-gradient(to right, rgb(89 89 89), rgb(38 37 37));
    }
    .heading-text-line {
      flex-grow: 1;
      height: 2px;
      background-color: rgba(186, 186, 186, 1);
      margin-top: 24px;
    }
  }
`