import React, { useState, useRef, useEffect } from "react";
import { TokenPageContainer } from "../Doctor-Page/Doctorstyle";
import Sidebar from "../Home/Components/Sidebar/Sidebar";
import { formatDate } from "../../helpers";
import { Radio, Checkbox, Modal } from "antd";
import { FaCamera, FaUpload } from "react-icons/fa6";
import Webcam from "react-webcam";

const Registration = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    age: { years: "", months: "", days: "" },
    isEstimated: false,
    berthTime: "",
    dob: "",
    address: {
      houseNumber: "",
      city: "",
      pincode: "",
      district: "",
      state: "",
    },
    contact: {
      phone: "",
      AltPhone: "",
      email: "",
    },
  });
  const [file, setFile] = useState(null);
  const [openCam, setOpenCam] = useState(false);
  const [isFrontCamera, setIsFrontCamera] = useState(true);
  const cameraRef = useRef(null);
  const fileInputRef = useRef(null);
  const contentRef = useRef();

  const handleCapture = () => {
    if (file !== null) {
      setFile(null);
    }
    const handleIconClick = () => {
      // Trigger the file input click
      fileInputRef.current.click();
    };

    const screenshot = cameraRef?.current?.getScreenshot();

    if (screenshot) {
      // Convert base64 data URL to a Blob
      const base64ToBlob = (base64, contentType = "", sliceSize = 512) => {
        const byteCharacters = atob(base64.split(",")[1]);
        const byteArrays = [];

        for (
          let offset = 0;
          offset < byteCharacters.length;
          offset += sliceSize
        ) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
      };

      // Convert base64 to Blob
      const blob = base64ToBlob(screenshot, "image/jpeg");

      // Create a File object with the new name
      const fileName = `${new Date().toISOString()}.jpg`;
      const renamedFile = new File([blob], fileName, { type: "image/jpeg" });

      // Set the renamed file
      setFile(renamedFile);
    }
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      console.log(`File selected: ${selectedFile.name}`);
    }
  };

  const handleSwitchCamera = () => {
    setIsFrontCamera((prev) => !prev);
  };

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleNestedInputChange = (field, subField, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        [subField]: value,
      },
    }));
  };
  const triggerFileInput = () => {
    document.getElementById("file-input").click();
  };

  const displayImage = () => {
    if (!file) return null; // Handle case where no image is selected

    // Handle different image loading scenarios:
    if (file instanceof File) {
      // Assuming you're using FileReader:
      const reader = new FileReader();
      reader.onload = (e) => {
        setFile(e.target.result); // Update state with image data URL
      };
      reader.readAsDataURL(file);
    } else if (typeof file === "string") {
      // If file is a URL:
      return <img src={file} alt="" width={144} className="m-auto" />;
    } else {
      console.error("Unexpected file format");
      return null; // Handle unexpected format
    }
  };

  useEffect(() => {
    if (!openCam && cameraRef.current) {
      const stream = cameraRef.current.stream;
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    }
  }, [openCam]);

  const handlePrint = () => {
    const printFrame = document.createElement("iframe");
    printFrame.style.position = "absolute";
    printFrame.style.width = "0";
    printFrame.style.height = "0";
    printFrame.style.border = "none";
    document.body.appendChild(printFrame);

    const content = contentRef.current.innerHTML;
    const printDocument = printFrame.contentWindow.document;

    printDocument.open();
    printDocument.write(`
      <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
          <style>
              body { font-family: Arial, sans-serif; }
          </style>
        </head>
        <body onload="window.print(); window.close();">
          ${content}
        </body>
      </html>
    `);
    printDocument.close();
  };

  return (
    <Sidebar setuser={true}>
      <TokenPageContainer className="px-4">
        <div className="flex items-center justify-end">
          <button
            className="rounded-3xl text-white py-2 px-3 w-32"
            style={{
              background: "linear-gradient(to right, #1C1B1B, #5B5B5B)",
            }}
            onClick={handlePrint}
          >
            Print Card
          </button>
        </div>
        <div className="border h-1 my-4 border-black"></div>
        <div className="flex flex-col lg:flex-row items-center gap-6">
          <div className="lg:w-3/4 flex flex-col gap-4 !order-2 lg:!order-1">
            <div className="flex items-center gap-2">
              <div>Registration Date:</div>
              <div>{formatDate(new Date())}</div>
            </div>
            <div className="flex items-center gap-2">
              <div>Patient name:</div>
              <input
                type="text"
                placeholder="First name"
                className="border outline-none p-1"
                value={formData.firstName}
                onChange={(e) => handleInputChange("firstName", e.target.value)}
              />
              <input
                type="text"
                placeholder="Last name"
                className="border outline-none p-1"
                value={formData.lastName}
                onChange={(e) => handleInputChange("lastName", e.target.value)}
              />
            </div>
            <div className="flex items-center gap-2">
              <div>Gender:</div>
              <Radio.Group
                onChange={(e) => handleInputChange("gender", e.target.value)}
                value={formData.gender}
              >
                <Radio value="male">Male</Radio>
                <Radio value="female">Female</Radio>
              </Radio.Group>
            </div>
            <div className="flex items-center gap-2">
              <div>Age:</div>
              {["years", "months", "days"].map((unit) => (
                <div key={unit} className="flex items-center gap-2 border px-2">
                  <input
                    type="number"
                    className="border-none outline-none p-1 w-full"
                    value={formData.age[unit]}
                    onChange={(e) =>
                      handleNestedInputChange("age", unit, e.target.value)
                    }
                  />
                  <div>{unit[0].toUpperCase() + unit.slice(1)}</div>
                </div>
              ))}
            </div>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <div>DOB:</div>
                <input
                  type="date"
                  className="border outline-none p-1 w-full"
                  value={formData.dob}
                  onChange={(e) => handleInputChange("dob", e.target.value)}
                />
              </div>
              <div className="flex items-center">
                <Checkbox
                  onChange={(e) =>
                    handleInputChange("isEstimated", e.target.checked)
                  }
                  checked={formData.isEstimated}
                >
                  Estimated
                </Checkbox>
              </div>
              <div className="flex items-center gap-2">
                <div>Berth Time:</div>
                <input
                  type="time"
                  value={formData.berthTime}
                  onChange={(e) =>
                    handleInputChange("berthTime", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="lg:w-1/4 flex flex-col gap-2 !order-1 lg:!order-2">
            <div className="size-36 border-3 overflow-hidden">
              {file !== null ? (
                displayImage()
              ) : (
                <img src={`/patientImage.png`} width={144} />
              )}
            </div>
            <div className="flex items-center justify-center gap-4 text-2xl w-32">
              <FaCamera
                className="cursor-pointer"
                onClick={() => setOpenCam(true)}
              />
              <div>
                <FaUpload
                  className="cursor-pointer"
                  onClick={triggerFileInput}
                />
                <input
                  type="file"
                  id="file-input"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="border h-1 mt-8 border-black"></div>
        <div className="p-2 py-1 rounded border w-fit -mt-5 ml-6 bg-white border-black">
          Address information
        </div>
        <div className="grid grid-cols-2 gap-8 mt-2">
          {["houseNumber", "city", "pincode", "district", "state"].map(
            (field) => (
              <div key={field} className="flex items-center gap-2">
                <div className="w-1/2">{field.replace(/([A-Z])/g, " $1")}:</div>
                <input
                  type="text"
                  className="border outline-none w-full px-2"
                  value={formData.address[field]}
                  onChange={(e) =>
                    handleNestedInputChange("address", field, e.target.value)
                  }
                />
              </div>
            )
          )}
        </div>
        <div className="border h-1 mt-8 border-black"></div>
        <div className="p-2 py-1 rounded border w-fit -mt-5 ml-6 bg-white border-black">
          Other information
        </div>

        <div className="grid grid-cols-2 gap-8 mt-2">
          {["phone", "AltPhone", "email"].map((field) => (
            <div key={field} className="flex items-center gap-2">
              <div className="w-1/2">{field.replace(/([A-Z])/g, " $1")}:</div>
              <input
                type="text"
                className="border outline-none w-full px-2"
                value={formData.contact[field]}
                onChange={(e) =>
                  handleNestedInputChange("contact", field, e.target.value)
                }
              />
            </div>
          ))}
        </div>
        <div className="flex items-center justify-end gap-2">
          <button
            className="rounded-3xl text-white py-2 px-3 w-32"
            style={{
              background: "linear-gradient(to right, #1C1B1B, #5B5B5B)",
            }}
          >
            Save
          </button>
        </div>
        <Modal
          open={openCam}
          onCancel={() => setOpenCam(false)}
          onClose={() => setOpenCam(false)}
          onOk={() => setOpenCam(false)}
          title={" "}
        >
          <Webcam
            audio={false}
            ref={cameraRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              facingMode: isFrontCamera ? "user" : { exact: "environment" },
            }}
          />
          <button className="cursor-pointer" onClick={handleSwitchCamera}>
            Switch Camera
          </button>
          <div
            className="text-center m-auto py-1 px-2 rounded-xl bg-gray-400 w-fit my-2 cursor-pointer"
            onClick={handleCapture}
          >
            capture
          </div>
          {file && displayImage()}
        </Modal>
        <div className="hidden" ref={contentRef}>
          <PatientCard formData={formData} file={file} setFile={setFile} />
        </div>
      </TokenPageContainer>
    </Sidebar>
  );
};

export default Registration;

const PatientCard = ({ formData, file, setFile }) => {
  const displayImage = () => {
    if (!file) return null; // Handle case where no image is selected

    // Handle different image loading scenarios:
    if (file instanceof File) {
      // Assuming you're using FileReader:
      const reader = new FileReader();
      reader.onload = (e) => {
        setFile(e.target.result); // Update state with image data URL
      };
      reader.readAsDataURL(file);
    } else if (typeof file === "string") {
      // If file is a URL:
      return <img src={file} alt="" width={144} className="m-auto" />;
    } else {
      console.error("Unexpected file format");
      return null; // Handle unexpected format
    }
  };

  return (
    <div className="bg-[#FAFAFA] p-2 rounded w-full">
      <div className="flex items-center justify-between">
        <div>
          <img src={`/gunam.png`} alt="Gunam" width={200} />
        </div>
        <div className="text-right">
          <div className="font-bold">Gunam Super Speciality Hospital</div>
          <div className="text-sm">Health Care You Deserve </div>
        </div>
      </div>
      <div className="border-b border-[#696969] my-4"></div>
      <div className="flex items-center justify-between px-6">
        <div className="flex flex-col gap-2 w-2/3">
          <div className="flex items-center gap-2">
            <div>Patient name:</div>
            <div>
              {formData?.firstName} {formData?.lastName}
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div>Gender:</div>
            <div>{formData?.gender}</div>
          </div>
          <div className="flex items-center gap-2">
            <div>DOB:</div>
            <div>{formData?.dob}</div>
          </div>
          <div className="flex items-center gap-2">
            <div>Age:</div>
            <div>{formData?.age?.years}</div>
          </div>
          <div className="flex items-center gap-2">
            <div>Phone No.:</div>
            <div>{formData?.contact?.phone}</div>
          </div>
        </div>
        <div className="size-36 border">
          {file !== null ? (
            displayImage()
          ) : (
            <img src={`/patientImage.png`} width={144} />
          )}
        </div>
        <div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
