import React from "react";
import { TokenPageContainer } from "../Doctor-Page/Doctorstyle";
import Sidebar from "../Home/Components/Sidebar/Sidebar";

const Analytics = () => {
  const grafanaUrl =
    "https://reports.agileads.us/d/be4f57oil5gjkt/medscribe-dev-logs?from=now-1h&to=now&timezone=browser&kiosk"; // Replace with your Grafana dashboard URL

  return (
    <Sidebar setuser={true}>
      <TokenPageContainer style={{ padding: 0, margin: 0, width: "100%", height: "100vh" }}>
        <div style={{ height: "100%", width: "100%", overflow: "hidden" }}>
          <iframe
            src={grafanaUrl}
            title="Grafana Dashboard"
            style={{
              display: "block", // Prevents iframe default inline spacing
              width: "100%",
              height: "100%",
              border: "none",
            }}
            allowFullScreen
          />
        </div>
      </TokenPageContainer>
    </Sidebar>
  );
};

export default Analytics;
